import { ControlValueAccessor, FormControl } from "@angular/forms";

export class BehaviourAdapter implements ControlValueAccessor {

  public adapter;

  subscribe(): void {
    this.form.valueChanges.subscribe((value) => {
      this.value = {...this.form.value};
    })
  }

  public form = new FormControl();

  onChange: any = (value) => {};
  onTouched: any = () => {};

  private _value: any;

  public get value(): any {
    return this._value;
  }
  public set value(value: any) {
    this._value = value;
    this.onChange(value);
  }

  writeValue(value) {
    this.form.setValue(!value?.selected ? { typed: "", selected: {}}: value);
    this.value = value;
    // this.onChange(value)
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    //
  }
};
