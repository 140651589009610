import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { Location, CommonModule } from '@angular/common';
import constants from '@ildes/config/constants';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/list-luminaries/list-luminaries.actions';
import { ToastService } from '@ildes/services/toast.service';

@Component({
  selector: 'app-edit-luminary-identification-data',
  templateUrl: './edit-luminary-identification-data.component.html',
  styleUrls: ['./edit-luminary-identification-data.component.css']
})
export class EditLuminaryIdentificationDataComponent implements OnInit {

  luminary;
  luminaryMaterials;
  loadingMaterials;
  retilap = constants.RETILAP;
  loading;
  searchLuminaryTypesAdapter;
  searchMakerTypesAdapter;
  searchModelAdapter;
  searchNeighborhoodAdapter;
  searchProjectAdapter;
  selectedRetilap = null;
  luminaryFormGroup = new UntypedFormGroup({
    selectedProjectForm: new UntypedFormControl(),
    technologyForm: new UntypedFormControl(),
    number: new UntypedFormControl(''),
    power: new UntypedFormControl('', [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
    location: new UntypedFormControl(''),
    neighborhoodForm: new UntypedFormControl(),
    modelForm: new UntypedFormControl(),
    makerForm: new UntypedFormControl(),
    pathObservations: new UntypedFormControl(),
  });

  constructor(
    private store: Store<AppState>,
    public apiManager: ApiManagerService,
    private location: Location,
    private toast: ToastService,
  ) { }

  get selectedProject() {
    return this.luminaryFormGroup.get('selectedProjectForm')?.value?.selected;
  }

  get technology() {
    return this.luminaryFormGroup.get('technologyForm')?.value?.typed;
  }

  get neighborhood() {
    return this.luminaryFormGroup.get('neighborhoodForm')?.value?.typed;
  }

  get maker() {
    return this.luminaryFormGroup.get('makerForm')?.value?.typed;
  }

  get model() {
    return this.luminaryFormGroup.get('modelForm')?.value?.typed;
  }

  get number() {
    return this.luminaryFormGroup.get('number')?.value;
  }

  get power() {
    return this.luminaryFormGroup.get('power')?.value;
  }

  get ubication() {
    return this.luminaryFormGroup.get('location')?.value
  }

  get address() {
    return this.ubication?.typed;
  }

  get latitude() {
    return parseFloat(this.ubication?.lat);
  }

  get longitude() {
    return parseFloat(this.ubication?.lng);
  }

  get pathObservations() {
    return this.luminaryFormGroup.get('pathObservations')?.value;
  }

  fetchAsocMaterials() {
    this.loadingMaterials = true;
    return this.apiManager.getAsocMaterial({ technology: this.technology, power: this.power }).subscribe((asocMaterials: any) => {
      this.loadingMaterials = false;
      this.luminaryMaterials = asocMaterials?.materials;
    }, () => {
      this.loadingMaterials = false;
      this.luminaryMaterials = null;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(
      actions.initState()
    );
    const state:any = this.location.getState();

    this.luminary = state.luminary;
    if (!this.luminary) {
      this.location.back();

      return;
    }
    const { controls } = this.luminaryFormGroup;

    controls.number.setValue(this.luminary.number);
    controls.pathObservations.setValue(this.luminary.pathObservations);
    controls.technologyForm.setValue({
      typed: this.luminary.technology,
      selected: this.luminary.technology
    });
    controls.selectedProjectForm.setValue({
      typed: this.luminary.projectName || this.luminary.project?.name,
      selected: this.luminary.project?.id ? this.luminary.project : { id: this.luminary.project }
    });
    controls.makerForm.setValue({
      typed: this.luminary.maker || '',
      selected: this.luminary.maker || null
    });
    controls.modelForm.setValue({
      typed: this.luminary.model || '',
      selected: this.luminary.model || null
    });
    const [lng, lat] = this.luminary.geolocation?.coordinates || ['',''];

    controls.location.setValue({
      place: { lat, lng },
      typed: this.luminary.address || '',
      lat,
      lng
    })

    controls.neighborhoodForm.setValue({
      typed: this.luminary.neighborhood || '',
      selected: this.luminary.neighborhood || null
    });

    controls.power.setValue(this.luminary.power);

    this.selectedRetilap = this.retilap.find((retilap) => retilap.id === this.luminary.retilap?.id);


    this.searchLuminaryTypesAdapter = (data) => {
      return this.apiManager.getFromCatalog('technologies', data)
    };
    this.searchMakerTypesAdapter = (data) => {
      return this.apiManager.getFromCatalog('makers', data)
    };
    this.searchModelAdapter = (data) => {
      return this.apiManager.getFromCatalog('models', data)
    };
    this.searchNeighborhoodAdapter = (data) => {
      return this.apiManager.getFromCatalog('neighborhood', data)
    };
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };
  }

  get projectTyped() {
    return this.luminaryFormGroup.get('selectedProjectForm')?.value?.typed;
  }

  save() {
    this.loading = true;

    const luminary = {
      number: this.number,
      ...this.selectedProject && {project: this.selectedProject.id},
      projectName: this.projectTyped,
      address: this.address,
      geolocation: {
        type: 'Point',
        coordinates: [this.longitude, this.latitude]
      },
      retilap: this.selectedRetilap,
      neighborhood: this.neighborhood,
      technology: this.technology,
      power: this.power,
      maker: this.maker,
      model: this.model,
      pathObservations: this.pathObservations,
    };

    this.apiManager.updateLuminary(this.luminary.id, {...this.luminary, ...luminary}).subscribe(() => {
      this.loading = false;

      this.store.dispatch(
        actions.remove({ _id: this.luminary._id})
      )
      this.location.back();
    }, (error) => {
      if (!error.code || error.code > 0) {
        return;
      }

      const luminaryMerged = {...this.luminary, ...luminary};

      if (this.luminary._id) {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.update({ id: this.luminary._id, changes: luminaryMerged })
        )
      } else {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.create({ luminary: luminaryMerged })
        )
      }
      this.location.back();

      this.loading = false;
    })
  }
}
