import { CreateMaintenanceComponent } from '@ildes/views/create-maintenance/create-maintenance.component';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import constants from '@ildes/config/constants';

@Component({
    selector: 'create-maintenance-dialog',
    standalone: true,
    imports: [
        CreateMaintenanceComponent,
        CommonModule,
    ],
    template: `
    <app-create-maintenance
      [painDescription]="painDescription"
      [embedMode]="true"
      [types]="types"
      [ignoreLuminaryStep]="true"
      [ignoreLocationStep]="true"
      (onClose)="closeDialog()"
      (filledMainenanData)="createMaintenance($event)">
    </app-create-maintenance>`,
    styleUrls: ['./create-maintenance-dialog.component.css']
})
export class CreateMaintenanceDialogComponent {
  painDescription;
  types=constants.MAINTENANCE_TYPES;
  constructor(
    public dialogRef: MatDialogRef<CreateMaintenanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.painDescription = data.painDescription;
  }

  createMaintenance(event) {
    this.dialogRef.close({maintenance: event});
  }

  closeDialog() {
    this.dialogRef.close({close: true});
  }
}
