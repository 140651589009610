import { SelectStoreWithStockDialogComponent } from './../../select-store-with-stock-dialog/select-store-with-stock-dialog.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiManagerService } from '../../../services/api-manager.service';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { SessionStore } from '@ildes/stores/session.store';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceListDialogComponent } from '@ildes/views/maintenance-list-dialog/maintenance-list-dialog.component';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class TechniciansDetailComponent implements OnInit {
  user;
  requestedStores = {}
  store;
  selectedTechnician;
  selectedTechnicianId;
  loadingMaintenances;
  loadingTechnicianDetail;
  loadingStores;
  loadingStoreDetail;
  pagination;
  currentPage;
  maintenances;
  stores = [];
  storeDetail = {};
  selectedStore = {};
  preassignedMaterials = new Map();
  materialSeacherForm = new UntypedFormGroup({
    materialName: new UntypedFormControl(),
  });
  searchMaterialAdapter;
  loadingAssignMaterials;
  constructor(
    private route: ActivatedRoute,
    private apiManager: ApiManagerService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadingStores = true;

    SessionStore.getInstance().get$().subscribe((data) => {
      if (!data?.user) {
        this.router.navigate(['signin']);

        return;
      }
      this.user = data.user;
    });
    this.apiManager.listStores().subscribe((data: any) => {
        this.stores = data.data;
        this.loadingStores = false;
    }, () => {
        this.loadingStores = false;
    })

    this.route.queryParams.subscribe(params => {
      this.selectedTechnicianId = params.id;
      this.fetchDataTechnician();
      this.list();
    });

    this.searchMaterialAdapter = () => {
      return this.apiManager.listMaterials();
    };

  }

  fetchDataTechnician() {
    this.loadingTechnicianDetail = true;
    this.apiManager.getUserDetail(this.selectedTechnicianId).subscribe((user: any) => {
      this.selectedTechnician = user;
      this.preassignedMaterials = new Map();
      this.loadingTechnicianDetail = false;
      (user.preassignedMaterials || []).forEach((material) => {
        this.preassignedMaterials.set(`${material.reference.id},${material.store?.id || ''}`, material);
        if (material.store?.id) {
          this.fetchStoreDetail(material.store.id);
        }
      });
    }, () => {
      this.loadingTechnicianDetail = false;
    });
  }

  list(pageIndex = 0) {
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const filter: any = this.getFilter();
    this.loadingMaintenances = true;
    this.apiManager.listMaintenances({...page, ...filter, state: ['NEW', 'IN_PROGRESS']}).subscribe((maintenances: any) => {
      this.loadingMaintenances = false;
      this.maintenances = maintenances.data;
      this.pagination = maintenances.pagination;
    }, (data) => {
      this.loadingMaintenances = false;
    });
  }

  getFilter() {
    const filter: any = {
      assigned: this.selectedTechnicianId
    };

    return filter;
  }

  onSelectedValue(value) {
    const materialSelected = this.materialSeacherForm.controls.materialName.value;
    const key = `${materialSelected.selected.id},${this.store.id}`;
    this.updateDictionary(key, this.store, materialSelected.selected, 1);

  }

  updateDictionary(key, store, reference, amount) {
    const materialAssigned = this.preassignedMaterials.get(key);

    if (materialAssigned) {
      materialAssigned.amount += amount;
    } else {
      this.preassignedMaterials.set(key, {
        store: { name: store.name, id: store.id },
        reference,
        amount
      });
    }
    this.materialSeacherForm.reset();
  }

  get normalizedMaterialPreassigned() {
    return Array.from(this.preassignedMaterials, ([name, value]) => {
      if (!value.store) {
        return value;
      }
      value.max = this.storeDetail[value.store.id]?.find(({ material }) => material === value.reference.id)?.amount || 0;

      return value;
    });
  }

  get hasMaterialOutOfStock() {
    return this.normalizedMaterialPreassigned.find((material) => material.amount > material.max)
  }

  assignMaterial() {
    this.loadingAssignMaterials = true;
    this.apiManager.assignMaterials(this.selectedTechnicianId, { assignedMaterials: this.normalizedMaterialPreassigned.map((item) => {
      const newItem = {...item};

      delete newItem.max;
      return newItem;
    }) }).subscribe(() => {
      this.loadingAssignMaterials = false;
      this.materialSeacherForm.reset();
      this.selectedStore = {};
      this.requestedStores = {};
      this.storeDetail = {};
      this.removePreassignedMaterial();
    });
  }

  removePreassignedMaterial() {
    this.apiManager.removePreassignedMaterials(this.selectedTechnicianId).subscribe(() => {
      this.fetchDataTechnician();
    });
  }

  savePreassignedMaterial() {
    this.apiManager.savePreassignedMaterials(this.selectedTechnicianId, { preassignedMaterials: this.normalizedMaterialPreassigned.map((item) => {
      const newItem = {...item};

      delete newItem.max;
      return newItem;
    }) }).subscribe(() => {
      this.fetchDataTechnician();
    });
  }

  remove(item) {
    const key = `${item.reference.id},${item.store?.id || ''}`;

    this.preassignedMaterials.delete(key);
  }

  returnToInventory(store) {
    this.apiManager.returnToInventory(this.selectedTechnicianId, { materials: store.items }).subscribe(() => {
      this.fetchDataTechnician();
    });
  }

  fetchStoreDetail(storeId) {
    if (this.requestedStores[storeId]) {
      return;
    }
    this.requestedStores[storeId] = true;
    this.loadingStoreDetail = true;
    this.apiManager.getStoreDetail(storeId).subscribe(({data}: any) => {
      this.storeDetail[storeId] = data;
      this.loadingStoreDetail = false;
    }, () => {
      this.loadingStoreDetail = false;
      this.requestedStores[storeId] = false;
    });
  }

  onStoreChange($event) {
    this.store = $event.value;

    this.fetchStoreDetail(this.store.id);
  }

  gotoCreateStores() {
    this.router.navigate(['/app/list-stores/create']);
  }

  assignMaintenances() {
    const dialog = this.dialog.open(MaintenanceListDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        technician: this.selectedTechnicianId
      }
    });
    dialog.afterClosed().subscribe( (data) => {

      if (data.close) {
        return;
      }

      this.loadingMaintenances = true;
      const maintenance = {...data.selectedMaintenance, technician: this.selectedTechnicianId };
      this.apiManager.assignMaintenancesToTechnical([maintenance]).subscribe(() => {
        this.list();
      }, () => {
        this.loadingMaintenances = false;
      });
    });
  }

  get materialsFromStore() {
    const stores = {};

    this.selectedTechnician?.returnedMaterials.forEach(( material) =>  {
      stores[material.storeId] = stores[material.storeId] || { name: material.store, items: []};

      stores[material.storeId].items.push(material);
    });

    return Object.values(stores);

  }

  selectStore(item) {
    const dialog = this.dialog.open(SelectStoreWithStockDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        item
      }
    });
    dialog.afterClosed().subscribe( (data) => {
      if (data.closed) {
        return;
      }

      const materials = this.preassignedMaterials.get(`${item.reference.id},${item.store?.id || ''}`);

      this.remove(item);
      this.preassignedMaterials.delete(`${item.reference.id},${item.store?.id || ''}`)
      console.log(materials)


      this.fetchStoreDetail(data.selected.id);
      item.store = data.selected;
      this.updateDictionary(`${item.reference.id},${item.store?.id || ''}`, data.selected, item.reference, item.amount)


    })
  }
}
