import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ViewChild } from '@angular/core';
import { CreateLuminaryDialogComponent } from '../create-luminary-dialog/create-luminary-dialog.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ADD_MATERIAL_ACTIVITY } from '@ildes/config/constants';

@Component({
    selector: 'app-expansion-data-dialog',
    template: `
    <simple-header title="Datos de la expansión" (action)="close()" icon="close"></simple-header>
    <mat-vertical-stepper [linear]="true" #stepper>
      <mat-step [completed]="false">
        <ng-template matStepLabel>Material usado en la expansión</ng-template>
        <div *ngFor="let material of usedMaterials">
          <div class="material padding-top-8 padding-bottom-8">
            <div class="fill center">
              <div>
              {{material.reference.name}}
              </div>
              <div class="description padding-top-8">
                Máximo {{material.max}}
              </div>
            </div>
            <input-material-value
              [min]="0"
              [max]="material.max"
              class="vertical-center"
              [material]="material"
              [(value)]="material.amount"></input-material-value>
          </div>
          <mat-divider></mat-divider>
        </div>
        <button (click)="continue()" mat-button color="primary" class="margin-top-16">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Datos de luminaria </ng-template>
        <list-luminary-item
            *ngIf="luminary"
            (click)="openCreateLuminaryDialog()"
            [number]="luminary.number"
            [project]="project?.name || 'Sin proyecto'"
            [address]="luminary.address"
            [ubication]="luminary.geolocation"
            [neighborhood]="luminary.neighborhood"
            [technology]="luminary.technology"
            [power]="luminary.power"
        ></list-luminary-item>
        <div class="flex-row" *ngIf="!luminary">
            <button class="right" (click)="openCreateLuminaryDialog()"  mat-button>Crear luminaria</button>
        </div>
        <button [disabled]="!luminary" (click)="continue()" mat-button color="primary" class="margin-top-16">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Actividades realizadas </ng-template>
        <app-list-dynamic-activities (onChangeActivities)="updateActivities($event)" [selectedActivities]="this.initActivities" [readOnlyList]="defaultActivityList"  ></app-list-dynamic-activities>
        <button (click)="continue()" mat-button color="primary" class="margin-top-16">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Observaciones </ng-template>
        <form [formGroup]="formDescription" autocomplete="off" class="padding-top-8">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Observaciones de la expansión</mat-label>
            <textarea rows="2" matInput formControlName="description"></textarea>
          </mat-form-field>
          <button [disabled]="formDescription.invalid" (click)="continue()" mat-button matStepperNext color="primary">Siguiente</button>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel> Confirmación </ng-template>
        <h3>Material Usado</h3>
        <div *ngIf="!usedMaterials.length" class="align-center">
          No se ha usado material para la expansión
        </div>
        <div class="padding-left-8 padding-bottom-16">
          <div *ngFor="let material of cleanListUsedMaterial" class="item">
            <list-material-item
              [name]="material.reference.name"
              [unit]="material.reference.unit"
              [amount]="material.amount"
              class="padding-top-8">
            </list-material-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <h3>Datos de luminaria</h3>
        <div class="padding-left-8 padding-bottom-16">
        <list-luminary-item
            *ngIf="luminary"
            [number]="luminary.number"
            [project]="luminary.project?.name || 'Sin proyecto'"
            [address]="luminary.address"
            [ubication]="luminary.geolocation"
            [neighborhood]="luminary.neighborhood"
            [technology]="luminary.technology"
            [power]="luminary.power"
        ></list-luminary-item>
        </div>
        <h3>Observaciones</h3>
        <div class="description padding-left-8 padding-bottom-16">
          {{formDescription.controls?.description?.value}}
        </div>
        <button mat-button (click)="confirm()" color="primary">Guardar</button>
      </mat-step>
    </mat-vertical-stepper>
    `,
    styleUrls: ['./expansion-data-dialog.component.css']
})
export class ExpansionDataDialogComponent {
    availableMaterials;
    usedMaterials = [];
    luminary;
    project;
    activities;
    initActivities;
    @ViewChild('stepper') stepper: any;

    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ExpansionDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {

        this.project = data.project;
        this.luminary = data.luminary;
        this.availableMaterials = (data.availableMaterials || []).map((material) => (
            {...material}
        ));
        const currentUsedMaterials = data.usedMaterials;
        this.usedMaterials = this.availableMaterials.map((availableMaterial) => {
            const currentUsedMaterial = (currentUsedMaterials || [])
                .find((usedMaterial) => usedMaterial.reference?.id === availableMaterial.reference.id);

            return {
                amount: currentUsedMaterial?.amount || 0,
                reference: availableMaterial.reference,
                max: availableMaterial.amount + (currentUsedMaterial?.amount || 0)
            };
        });
        (currentUsedMaterials || []).forEach((usedMaterial) => {
            const usedInAvailableMaterials = this.usedMaterials
              .find((currentUsedMaterial) => currentUsedMaterial.reference?.id === usedMaterial.reference.id);

            if (!usedInAvailableMaterials) {
              this.usedMaterials.push({
                amount: usedMaterial.amount,
                reference: usedMaterial.reference,
                max: usedMaterial.amount
              });
            }
        });
        if (data.activities) {
          let tempDefaultActivityList = [...this.defaultActivityList];

          this.initActivities = data.activities.filter(( data ) => {
            const index = tempDefaultActivityList.findIndex(({id}) => id === data.id);

            if (index >= 0) {
              tempDefaultActivityList.splice(index, 1);

              return false;
            }

            return true;
          });
        }

    }

    formDescription = new UntypedFormGroup({
        description: new UntypedFormControl('')
    });

    get cleanListUsedMaterial() {
        return this.usedMaterials.filter(({ amount }) => amount > 0);
    }

    get defaultActivityList() {
      return this.usedMaterials.map((data) => {
        return Array.from(Array(data.amount)).map(() => ( {...ADD_MATERIAL_ACTIVITY, extraData: data.reference.name }));
      }).flat();
    }

    updateActivities(activities) {
      this.activities = activities;
    }

    continue() {
        this.stepper.selected.completed = true;
        this.stepper.next();
     }

    openCreateLuminaryDialog() {
        const dialog = this.dialog.open(CreateLuminaryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                luminary: this.luminary,
                project: this.project,
            }
        });

        dialog.afterClosed().subscribe(async (data) => {
            if (data.closed) {
                return;
            }
            this.luminary = data.luminary;
        });
    }

    confirm() {
        this.dialogRef.close({
          luminary: this.luminary,
          usedMaterials: this.cleanListUsedMaterial,
          activities: this.activities,
          description: this.formDescription.controls?.description?.value,
        });
    }

    public close() {
        this.dialogRef.close({ closed: true });
    }
}
