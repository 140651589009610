import {  NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit, forwardRef } from '@angular/core';
import { PredictiveSearcherComponent } from '../predictive-searcher/predictive-searcher.component';
import { BehaviourAdapter } from '../predictive-searcher/behaviour-adapter';

@Component({
    selector: 'app-neighborhood-selector',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PredictiveSearcherComponent
    ],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => NeighborhoodSelectorComponent),
      },
    ],
    template: `
      <predictive-searcher
        label="Barrio"
        [formControl]="form"
        [service]="adapter">
      </predictive-searcher>`,
    styleUrls: ['./neighborhood-selector.component.css']
})
export class NeighborhoodSelectorComponent extends BehaviourAdapter implements OnInit {

  constructor(private apiManager: ApiManagerService) {
    super();
    this.adapter = (neighborhood) => {
      return this.apiManager.getFromCatalog('neighborhood', neighborhood)
    };
  }
  ngOnInit(): void {
    this.subscribe();
  }

}
