import { FileHelperService } from './../../services/file-helper.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@ildes/app.reducer';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UnsyncModernizationsService } from '@ildes/services/unsync-modernizations.service';
import { Store } from '@ngrx/store';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';
import { Observable, concat, defer, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import projectDailyQuestionary from '../repair-maintenance/questions-high-permission';
import { SessionStore } from '@ildes/stores/session.store';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { MatDialog } from '@angular/material/dialog';

const DISCARD_CHANGES = {
    id: 'discard',
    description: 'Descartar',
    icon: 'trash'
};
const DOWNLOAD_DOCUMENTATION = {
  id: 'download',
  description: 'Descargar permiso de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};

const DOWNLOAD_ORDER_TASK = {
  id: 'download_order',
  description: 'Descargar orden de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};

function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) =>  Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
        return defer(() => {
            onSubscribe();
            return source;
        });
    };
}
@Component({
    selector: 'app-technician-dashboard-modernizations',
    template: `
      <pull-to-refresh [scrollableContent]="scrolledContent" [target]="container" (refresh)="refresh()" ></pull-to-refresh>
      <div class="container" #container>
        <div class="container">
          <div class="filter-container">
            <mat-button-toggle-group (change)="setMaintenanceFilter($event)" >
              <mat-button-toggle [checked]="true" value="NEW">Pendientes</mat-button-toggle>
              <mat-button-toggle value="IN_PROGRESS">En curso</mat-button-toggle>
              <mat-button-toggle value="CLOSED">Cerrados</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <section class="container">
            <mat-progress-bar mode="indeterminate" *ngIf="loadingFetch"></mat-progress-bar>
            <div class="scrollable-content" #scrolledContent>
              <section *ngIf="state !== 'IN_PROGRESS' && !loadingFetch">
                <div *ngIf="!loadingFetch" class="scrollable-content" #scrolledContent>
                  <div *ngFor="let order of modernizations" class="item">
                    <app-list-order-item
                      [actions]="state === 'CLOSED' && !order.dirty ? actionsModernizationsClosed: []"
                      (onClickAction)="doAction(order, $event)"
                      (onLinkClick)="!pendingRequest && openModernization(order)"
                      [state]="order.state"
                      [orderno]="order.orderno"
                      [estimatedResolutionDate]="order.estimatedResolutionDate"
                      [resolutionDate]="order.resolutionDate"
                      [technician]="order.technician"
                      [project]="order.project?.name || 'Sin proyecto'"
                      [address]="order.address|| order.luminary?.address"
                      [ubication]="order.geolocation || order.luminary?.geolocation"
                      [neighborhood]="order.neighborhood || order.luminary?.neighborhood"
                      [orderDescription]="order.orderDescription"
                      [technology]="order.newTechnology"
                      [power]="order.newPower"
                      [oldPower]="order.oldPower"
                      [oldTechnology]="order.oldTechnology"
                      [oldLuminaryNumber]="order.oldLuminaryNumber"
                      [newLuminaryNumber]="order.newLuminaryNumber"
                      [luminary]="order.luminary"
                    ></app-list-order-item>
                    <div class="padding-bottom-8">
                        <i>
                            <span *ngIf="order.dirty" class="ok"> Refresque para ver la información actualizada </span>
                        </i>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div *ngIf="!modernizations?.length">
                      <app-image-panel text="No existen expansiones" class="margin-top-16" ></app-image-panel>
                  </div>
                </div>
              </section>
              <section *ngIf="state === 'IN_PROGRESS'">
                <section *ngIf="pendingSyncDailyQuestionaries?.length && unsyncModernizationsList?.length">
                  <h3>Existen cuestionarios diarios sin guardar</h3>
                  <mat-progress-bar mode="indeterminate" *ngIf="pendingQuestionaryRequest"></mat-progress-bar>
                  <app-alert-dialog
                      *ngIf="!pendingQuestionaryRequest"
                      text="Al guardar todo también se guardarán los cuestionarios diarios."
                      status="advise"></app-alert-dialog>
                </section>
                <section *ngIf="unsyncModernizationsList?.length">
                  <h3>Modernizaciones pendientes de guardar</h3>
                  <div *ngFor="let unsyncModernization of unsyncModernizationsList" class="item">
                    <app-list-order-item
                      type="Modernización"
                      [pendingSync]="true"
                      [actions]="(actionsModernizations | filterActionsMaintenance:order)"
                      (onClickAction)="!pendingRequest && onActionClick(unsyncModernization, $event)"
                      (onLinkClick)="!pendingRequest && select(unsyncModernization)"
                      state="IN_PROGRESS"
                      [orderno]="unsyncModernization.orderno"
                      [newLuminaryNumber]="unsyncModernization.newLuminaryNumber"
                      [technician]="this.user"
                      [project]="unsyncModernization.selectedProject?.name || unsyncModernization.luminary?.projectName || 'Sin proyecto'"
                      [address]="unsyncModernization.luminary?.address"
                      [ubication]="unsyncModernization.luminary?.geolocation"
                      [neighborhood]="unsyncModernization.luminary?.neighborhood"
                      [luminary]="unsyncModernization.luminary"
                      [technology]="unsyncModernization.technology"
                      [power]="unsyncModernization.power"
                    ></app-list-order-item>
                    <!-- <list-luminary-item
                        (onActionClick)="!pendingRequest && onActionClick(unsyncModernization, $event)"
                        (onLinkClick)="!pendingRequest && select(unsyncModernization)"
                        [actions]="actionsModernizations"
                        [number]="unsyncModernization.luminary ? unsyncModernization.luminary.number: 'Pendiente crear luminaria'"
                        [project]="unsyncModernization.selectedProject?.name || unsyncModernization.luminary.projectName || 'Sin proyecto'"
                        [address]="unsyncModernization.luminary?.address"
                        [ubication]="unsyncModernization.luminary?.geolocation"
                        [neighborhood]="unsyncModernization.luminary?.neighborhood"
                        [technology]="unsyncModernization.luminary?.technology"
                        [power]="unsyncModernization.luminary?.power"
                        [newTechnology]="unsyncModernization.technology"
                        [newPower]="unsyncModernization.power"
                    ></list-luminary-item> -->
                    <div class="padding-bottom-8">
                        <i>
                            <span *ngIf="unsyncModernization.closeDate" class="ok"> Pendiente de sincronizar </span>
                            <span *ngIf="!unsyncModernization.closeDate"  class="advise"> Pendiente de completar el proceso </span>
                        </i>
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="loading[unsyncModernization.id]"></mat-progress-bar>
                    <mat-divider></mat-divider>
                  </div>
                  <div class="align-center padding-top-8" *ngIf="hasPendingSync">
                      <button [disabled]="pendingRequest" mat-button (click)="sync()" class="button-size-200">
                          <mat-icon class="icon-button">cloud_upload</mat-icon>Guardar todo
                      </button>
                  </div>
                </section>
                <div *ngIf="!unsyncModernizationsList?.length">
                    <app-image-panel text="No hay modernizaciones pendientes de sincronizar" class="margin-top-16" ></app-image-panel>
                </div>
              </section>
            </div>
            <mat-paginator
                *ngIf="!loadingFetch && pagination && modernizations.length  && state !== 'IN_PROGRESS'"
                [pageIndex]="currentPage"
                [length]="pagination?.totalDocs"
                pageSize="20"
                (page)="paginate($event)">
            </mat-paginator>
          </section>
        </div>
      </div>
    `,
    styleUrls: ['./technician-dashboard-modernizations.component.css'],
})
export class TechnicianDashboardModernizationsComponent implements OnInit {
    unsyncModernizationsList;
    loading = {};
    loadingQuestionary = {};
    actionsModernizations = [
      DISCARD_CHANGES
    ];
    actionsModernizationsClosed = [
      DOWNLOAD_DOCUMENTATION,
      DOWNLOAD_ORDER_TASK
    ];
    error = false;
    dailyQuestionaries;
    loadingFetch;
    state = 'NEW';
    pagination;
    modernizations;
    currentPage = 0;
    downloading = false;
    user;
    constructor(
        private dialog: MatDialog,
        private store: Store<AppState>,
        private apiManager: ApiManagerService,
        private router: Router,
        private unsyncModernizations: UnsyncModernizationsService,
        private fileHelper: FileHelperService
    ) {
        this.user = SessionStore.getInstance().get().user;
        this.unsyncModernizationsList = this.unsyncModernizations.getItems()?.filter(({state, unsync}) => unsync || state !== 'CLOSED' );

        this.store.dispatch(
            actionsQuestionaries.loadDailyQuestionaries()
        );

        this.store.select('dailyQuestionaries').subscribe((data) => {
            this.dailyQuestionaries = data.filter(({ pendingSync }) => pendingSync);
        });
    }

    ngOnInit(): void {
      this.fetchModernizations();
    }

    get pendingQuestionaryRequest() {
        return Object.values(this.loadingQuestionary).some((value) => value );
    }
    get pendingRequest() {
        return Object.values(this.loading).some((value) => value );
    }

    onActionClick(modernization, event) {
        switch(event.id) {
          case DISCARD_CHANGES.id:
            this.unsyncModernizationsList = this.unsyncModernizations.clearItem(modernization);

            break;
          default:
            break;
        }
      }

    select(modernization) {
      //this.router.navigate(['/app/create-modernization'], { queryParams: { id: modernization.id} });
      this.router.navigate(['/app/create-modernization'], { state: { modernization} })
    }

    sync() {
        this.error = false;
        const requestsLuminaries = this.unsyncModernizationsList.filter(( {closeDate } ) => closeDate).map((expansion) => {
            return this.unsyncModernizations.sync(expansion).pipe(
                doOnSubscribe(() => {
                    this.loading[expansion.id] = true;
                }),
                tap(() => ({
                    next: this.loading[expansion.id] = false
                })),
                map(() => {
                    return expansion;
                })
            )
        });

        const requestQuestionaries = [...this.pendingSyncDailyQuestionaries].map((questionary) => {
            const key = `${questionary.projectId}_${questionary.date}`;

            return this.syncDailyQuestionary(questionary).pipe(
                doOnSubscribe(() => {
                    this.loadingQuestionary[key] = true;
                }),
                tap(() => ({
                    next: this.loadingQuestionary[key] = false
                })),
                map(() => {
                    return questionary;
                })
            )
        });
        const userId = this.user.id;

        concat(...[...requestQuestionaries, ...requestsLuminaries]).subscribe(() => {
            this.unsyncModernizationsList = this.unsyncModernizations.getItems()?.filter(({unsync, state}) => unsync || state !== 'CLOSED');
            if (this.unsyncModernizationsList?.length) {
                return;
            }
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
          }, (error) => {
            this.loading = {};
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
            if (error && error.code <= 0) {
              return;
            }
            this.error = true;
          });

    }

    get pendingSyncDailyQuestionaries() {
        return this.dailyQuestionaries?.filter(({ pendingSync })=>pendingSync);
    }

    get hasPendingSync() {
        return !!this.unsyncModernizationsList?.filter(( {closeDate } ) => closeDate).length;
    }
    private syncDailyQuestionary(dailyQuestionary) {
        const projectId = dailyQuestionary.projectId;
        const questionaryDate = dailyQuestionary.date;

        return this.apiManager.updateDailyQuestionary(projectId, {
            questions: projectDailyQuestionary,
            answers: dailyQuestionary.answers,
            date: questionaryDate,
        }).pipe(
            tap(() => {
                this.store.dispatch(
                    actionsQuestionaries.update({
                        changes: { pendingSync: false },
                        date: new Date(questionaryDate),
                        projectId: projectId,
                    })
                );
            })
        )
    }
    refresh() {
      if (this.state === 'IN_PROGRESS') {
        return;
      }
      this.fetchModernizations(this.pagination?.page - 1, this.pagination?.limit, false);
    }

    setMaintenanceFilter(event) {
      this.state = event.value;
      this.pagination = null;
      if (this.state === 'IN_PROGRESS') {
        return
      }
      if (this.state === 'CLOSED') {
        this.fetchModernizations(0, 20);
      } else {
        this.fetchModernizations();
      }
    }

    fetchModernizations(pageIndex = 1, limit = 0, cache = true) {
      this.currentPage = pageIndex;
      let page;
      this.loadingFetch = true;

      page = limit ? { page: pageIndex + 1, limit } : {};

      this.unsyncModernizations.fetchModernizations( { state: this.state, ...page}, cache).subscribe((data: any) => {
        this.loadingFetch = false;
        this.modernizations = data.data;
        this.pagination = limit && data.pagination;
      }, (err) => {
        this.loadingFetch = false;
      })
    }

    openModernization(modernization) {

      let modernizationData = modernization;
      const userId = SessionStore.getInstance().get().user.id;

      if (modernizationData.technician?.id === userId && modernizationData.state !== 'CLOSED') {
        this.select(modernizationData);
        //this.router.navigate(['/app/create-modernization'], { state: { modernization} })
      } else {
        const request = !modernization.dirty ?
          of(modernization):
          this.apiManager.getModernizationDetail(modernization.id).pipe(map(( data:any ) => data.data));
        request.subscribe((data) => {
          this.dialog.open(OrderDetailComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
              modernization: data
            }
          });
        })
      }
    }

    doAction(order, action) {
      switch (action.id) {
        case DOWNLOAD_DOCUMENTATION.id:
          this.downloadDocumentation(order.id).subscribe((data) => {
            this.open(data);
          });
          break;
        case DOWNLOAD_ORDER_TASK.id:
          this.open(order.orderTaskDocumentPath);
          break;
        default:
          break;
      }
    }

    downloadDocumentation(orderId) {
      return this.apiManager.getTasks({
        type: 'DOCUMENT_SIGN',
        subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
        'metadata.modernization': orderId
      }).pipe(
        mergeMap((taskData: any) => {
          const task = taskData.data[0];
          if (!task.usersWhoPerformTheTask?.length) {
            return [task.metadata.documentName];
          } else {
            return this.apiManager.getEvidence({ taskId: task.id })
              .pipe(
                map(({ data }: any) => data.signedDocumentName)
              )
          }
        })
      );
    }

    open(fileName) {
      this.downloading = true;
      this.fileHelper.downloadPdf(fileName).subscribe(async (data: any) => {
        this.downloading = false;
      }, () => {
        this.downloading = false;
      })
    }
    paginate(event) {
      this.fetchModernizations(event.pageIndex, 20, false);
    }
}
