import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { DateSelectorComponent } from '@ildes/basic/date-selector/date-selector.component';
import { DropdownSelectorComponent } from '@ildes/basic/dropdown-selector/dropdown-selector.component';
import { PredictiveSearcherComponent } from '@ildes/basic/predictive-searcher/predictive-searcher.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { InputPlaceComponent } from '@ildes/basic/input-place/input-place.component';
import { SelectUsersDialogComponent } from '@ildes/basic/select-users-dialog/select-users-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionStore } from '@ildes/stores/session.store';
import { CreateMaintenanceDialogComponent } from '@ildes/views/create-maintenance-dialog/create-maintenance-dialog.component';
import { ListMaintenanceItemComponent } from '@ildes/basic/list-maintenance-item/list-maintenance-item.component';

const OPERATIVE_SECTION = {
    id: 'OPERATIVE',
    description: 'Operativa',
   // timeToResponse: 8
};
const SECTOR_TYPES = [
    {
        id: 'URBAN',
        description: 'Urbano',
    },
    {
        id: 'RURAL',
        description: 'Rural',
    },
];
const RESPONSE_OPERATION_ID = 'OPERATION';
const RESPONSE_OTHER_ID = 'OTHERS';
const RESPONSE_TYPES = [
  {
      id: RESPONSE_OPERATION_ID,
      description: 'Minuta de Operación',
  },
  {
      id: 'EXPANSION',
      description: 'Minuta de Expansión',
  },
  {
    id: 'THIRD',
    description: 'Minuta de Remisión a Terceros',
  },
  {
      id: 'IAP',
      description: 'Minuta de IAP',
  },
  {
      id: RESPONSE_OTHER_ID,
      description: 'Otros',
  }
];

const ORIGIN_PQR_TYPES = [
    {
        id: 'PHONE',
        description: 'Telefónico',
    },
    {
        id: 'FACE_TO_FACE',
        description: 'Verbal',
    },
    {
        id: 'WRITED',
        description: 'Escrito',
    }
];

const REQUEST_MAINTENANCE_ID = 'MAINTENANCE';
const REQUEST_OTHER_ID = 'OTHERS';
const EXPANSION_ID = 'EXPANSION';
const REQUEST_TYPES = [
    {
        id: REQUEST_MAINTENANCE_ID,
        description: 'Mantenimiento',
    },
    {
        id: EXPANSION_ID,
        description: 'Expansión',
    },
    {
        id: 'TAX',
        description: 'Impuesto alumbrado público',
    }
    ,
    {
        id: REQUEST_OTHER_ID,
        description: 'Otros',
    }
];
@Component({
    selector: 'app-create-pqr',
    standalone: true,
    imports: [
        ListMaintenanceItemComponent,
        ReactiveFormsModule,
        CommonModule,
        PredictiveSearcherComponent,
        SimpleHeaderComponent,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        DropdownSelectorComponent,
        MatButtonModule,
        DateSelectorComponent,
        MatCheckboxModule,
        InputPlaceComponent
    ],
    template: `
    <div class="container">
        <div class="scrollable-content">
            <simple-header title="Crear PQR" [back]="true" icon="close"></simple-header>
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step label="Datos de la petición" [completed]="validateStep1">
                    <predictive-searcher
                        [mandatorySelection]="true"
                        [formControl]="selectedProjectForm"
                        label="Proyecto"
                        [service]="searchProjectAdapter"></predictive-searcher>
                    <mat-form-field appearance="outline" class="full-width" (click)="openSelectUserDialog()">
                        <mat-label>Funcionario que recibe la solicitud</mat-label>
                        <input matInput readonly [formControl]="receptor" maxlength="90">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <dropdown-selector (selectedChange)="onRequestTypeChange($event)" class="column"  label="Tipo de solicitud" [types]="requestTypes" [(selected)]="requestType"></dropdown-selector>
                    <!-- <dropdown-selector class="column"  label="Área encargada" [types]="accountableSectionTypes" [(selected)]="accountableSection"></dropdown-selector> -->
                    <div class="section margin-top-8">
                        <h3>Fecha de radicado</h3>
                        <div>
                            <date-selector [date]="registrationDate" minYear=2000 maxYear=2080 (onChangeDate)="setRegistrationDate($event)" ></date-selector>
                        </div>
                    </div>
                    <dropdown-selector [disabled]="isMaintenance" class="column"  label="Tipo de respuesta" [types]="responseTypes" [(selected)]="responseType"></dropdown-selector>
                    <mat-form-field appearance="outline" class="full-width" *ngIf="isOtherResponseType">
                        <mat-label>Descripción del tipo de solicitud</mat-label>
                        <textarea rows="2" matInput [formControl]="responseTypeDescription"></textarea>
                    </mat-form-field>
                    <button [disabled]="!validateStep1" matStepperNext mat-button color="primary">Siguiente</button>
                </mat-step>
                <mat-step label="Datos de PQRS" [completed]="validateStep2">
                    <h3>Datos de peticionario</h3>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Nombre</mat-label>
                        <input matInput [formControl]="petitionerName" maxlength="20">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Cédula</mat-label>
                        <input matInput [formControl]="identification" maxlength="20">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Dirección del peticionario</mat-label>
                        <textarea rows="2" matInput [formControl]="petitionerAddress"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Teléfono fijo</mat-label>
                        <input matInput [formControl]="phone" maxlength="20">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Celular</mat-label>
                        <input matInput [formControl]="mobilePhone" maxlength="20">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [formControl]="email" maxlength="200">
                        <mat-error>
                            Debe ser formato email
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Otro medio de contacto</mat-label>
                        <input matInput [formControl]="otherContact" maxlength="20">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <dropdown-selector class="column"  label="Medio en que presenta la solicitud" [types]="originPqrTypes" [(selected)]="originPqr"></dropdown-selector>
                    <h3>Anexos</h3>
                    <div>
                        <mat-checkbox
                            class="margin-bottom-16"
                            matRipple
                            role="button"
                            [checked]="hasAttachments"
                            (change)="onChange($event)">
                            Presenta anexos
                        </mat-checkbox>
                    </div>
                    <mat-form-field appearance="outline" class="full-width" *ngIf="this.hasAttachments">
                        <mat-label>Tipo de anexo</mat-label>
                        <input  matInput [formControl]="attachmentType" maxlength="20">
                        <mat-error>
                            El nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <button [disabled]="!validateStep2" matStepperNext mat-button color="primary">Siguiente</button>
                </mat-step>
                <mat-step label="Información de la actividad">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Observaciones</mat-label>
                        <textarea rows="2" matInput [formControl]="pqrsDescription"></textarea>
                    </mat-form-field>
                    <section *ngIf="isExpansion || isMaintenance">
                      <h3>Ubicación y punto de referencia</h3>
                      <dropdown-selector class="column"  label="Sector" [types]="sectorTypes" [(selected)]="sector"></dropdown-selector>
                      <input-place
                          [required]="true"
                          class="column"
                          label="Dirección"
                          placeholder="CLL 33 45"
                          dark
                          [formControl]="address"
                          appearance="fill"></input-place>
                      <predictive-searcher
                          label="Barrio"
                          [formControl]="neighborhoodForm"
                          [service]="searchNeighborhoodAdapter"
                          class="column margin-right-8"></predictive-searcher>
                      <mat-form-field appearance="outline" class="full-width">
                          <mat-label>Punto de referencia de ubicación</mat-label>
                          <input matInput maxlength="80" [formControl]="referencePointDescription">
                      </mat-form-field>
                    </section>
                    <button matStepperNext *ngIf="isMaintenance" [disabled]="!validateStep3" mat-button color="primary">Siguiente</button>
                    <div class="align-center padding-top-32" *ngIf="!isMaintenance">
                        <div class="padding-bottom-8">
                            <button  (click)="this.create()" [disabled]="loading || !validateStep3" mat-raised-button color="primary" class="button-size-200">Guardar</button>
                        </div>
                        <div>
                            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>
                </mat-step>
                <mat-step *ngIf="isMaintenance" label="Mantenimiento de la actividad">
                  <p *ngIf="!this.maintenance">
                      Para PQRS asignados al área de operación se necesita crear un mantenimiento.
                  </p>
                  <list-maintenance-item
                    luminaryNumber="Mantenimiento asociado a PQR"
                    *ngIf="maintenance"
                    [project]="selectedProjectForm.value?.typed || 'Sin proyecto'"
                    [state]="maintenance.state"
                    [type]="maintenance.type"
                    [address]="address?.value.typed"
                    [neighborhood]="neighborhoodForm?.value?.typed"
                    [referencePoint]="referencePoint?.value"
                    [painType]="maintenance.painType"
                    [painDescription]="pqrsDescription?.value"
                    [estimatedResolutionDate]="maintenance.estimatedResolutionDate"
                  ></list-maintenance-item>
                  <button (click)="this.createMaintenance()" mat-button>
                    <span *ngIf="this.maintenance">Borrar y crear otro</span>
                    <span *ngIf="!this.maintenance">Crear mantenimiento</span>
                  </button>
                  <div class="align-center padding-top-32" >
                      <div class="padding-bottom-8">
                        <button (click)="this.create()" [disabled]="!maintenance" mat-raised-button color="primary" class="button-size-200">Guardar</button>
                      </div>
                  </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>
    </div>
    `,
    styleUrls: ['./create-pqr.component.css']
})
export class CreatePqrComponent implements OnInit{
    @ViewChild('stepper') virtualStepper: any;
    selectedReceptor;
    pqrsDescription = new FormControl();
    referencePointDescription = new FormControl();
    searchProjectAdapter;
    selectedProjectForm = new FormControl();
    petitionerName = new FormControl();
    petitionerAddress = new FormControl();
    phone = new FormControl();
    mobilePhone = new FormControl();
    email = new FormControl();
    identification = new FormControl();
    otherContact = new FormControl();
    responseTypeDescription = new FormControl();
    originPqrTypes=ORIGIN_PQR_TYPES;
    originPqr;
    requestTypes=REQUEST_TYPES;
    requestType;
    receptor = new FormControl();
    registrationDate = new Date();
    //accountableSection;
    //accountableSectionTypes = ACCOUNTABLE_SECTION_TYPES;
    responseTypes = RESPONSE_TYPES;
    responseType;
    hasAttachments;
    attachmentType = new FormControl();
    sectorTypes = SECTOR_TYPES;
    sector;
    searchNeighborhoodAdapter;
    address = new FormControl();
    neighborhoodForm = new FormControl();
    loading = false;
    maintenance;
    constructor(
        private dialog: MatDialog,
        private apiManager: ApiManagerService,
    ) {
        this.searchProjectAdapter = (data) => {
            return this.apiManager.listProjects(data);
        };

        this.searchNeighborhoodAdapter = (neighborhood) => {
            return this.apiManager.getFromCatalog('neighborhood', neighborhood)
        };
    }
    resetForm() {
      this.selectedReceptor = SessionStore.getInstance().get().user;
      this.receptor = new FormControl(`${this.selectedReceptor.name} ${this.selectedReceptor.surname}`);
      this.pqrsDescription.reset();
      this.referencePointDescription.reset();
      this.selectedProjectForm.reset();
      this.petitionerName.reset();
      this.petitionerAddress.reset();
      this.phone.reset();
      this.mobilePhone.reset();
      this.email.reset();
      this.identification.reset();
      this.otherContact.reset();
      this.responseTypeDescription.reset();
      this.originPqr =undefined;
      this.requestType = undefined;;
      this.receptor.reset();
      this.registrationDate = new Date();
      this.responseType = undefined;;
      this.hasAttachments = undefined;;
      this.attachmentType.reset();
      this.sector = undefined;
      this.address.reset();
      this.neighborhoodForm.reset();
      this.loading = false;
      this.maintenance = undefined;
      this.virtualStepper.reset()
    }
    ngOnInit(): void {
      this.selectedReceptor = SessionStore.getInstance().get().user;
      this.receptor = new FormControl(`${this.selectedReceptor.name} ${this.selectedReceptor.surname}`);
    }

    get validateStep1() {
        //return true;

        return this.selectedProjectForm.value?.typed &&
            this.requestType &&
            this.responseType &&
            this.registrationDate &&
            (this.isOtherResponseType ? !!this.responseTypeDescription?.value?.trim() : true);
    }

    get validateStep2() {
        return this.petitionerName.value &&
            this.identification.value &&
            this.originPqr &&
            (this.phone.value || this.mobilePhone.value || this.otherContact.value) &&
            (this.hasAttachments ? this.attachmentType.value : true);
    }

    get validateStep3() {
      return this.pqrsDescription.value && ((this.isMaintenance || this.isExpansion) ? this.validAddressSection : true);
    }

    get validAddressSection() {
      return ( this.address.value?.typed || (this.address.value?.lat && this.address.value?.lng) || this.neighborhoodForm.value?.typed || this.referencePointDescription.value);
    }

    private performData() {
        const jsonAddress = this.address.value;
        let address = jsonAddress?.typed;
        let latitude = jsonAddress?.place?.lat;
        let longitude = jsonAddress?.place?.lng;
        let maintenance;
        let neighborhood = this.neighborhoodForm.value?.typed;
        let geolocation = {
          type: 'Point',
          coordinates: [longitude, latitude]
        };
        if (this.isMaintenance) {
          maintenance = {
            ...this.maintenance,
            ...{
              project: this.selectedProjectForm.value.selected.id,
              address,
              ...longitude && latitude && { geolocation },
              neighborhood
            }
          }
        }

        return {
            ...this.isMaintenance && { maintenance },
            petitioner: {
                name: this.petitionerName.value,
                phone: this.phone.value,
                email: this.email.value,
                mobilePhone: this.mobilePhone.value,
                identification: this.identification.value,
                otherContact: this.otherContact.value,
                address: this.petitionerAddress.value
            },
            ...this.isOtherResponseType && { responseTypeDescription: this.responseTypeDescription.value },
            project: this.selectedProjectForm.value.selected.id,
            originPqr: this.originPqr?.id,
            requestType: this.requestType?.id,
            receptor: { id: this.selectedReceptor.id },
            pqrsDescription: this.pqrsDescription.value,
            referencePointDescription: this.referencePointDescription.value,
            //responseDays: this.accountableSection?.timeToResponse,
            registrationDate: this.registrationDate.toISOString(),
            //accountableSection: this.accountableSection?.id,
            responseType: this.responseType?.id,
            hasAttachments: this.hasAttachments,
            attachmentType: this.attachmentType.value,
            sector: this.sector?.id,
            address,
            ... longitude && latitude && { geolocation },
            neighborhood
        }
    }

    onRequestTypeChange(data) {
      if (data?.id === REQUEST_MAINTENANCE_ID) {
        this.responseType = this.responseTypes.find(({id}) => id === RESPONSE_OPERATION_ID)
      } else if (this.isMaintenance) { // es de la iteración anterior, reseteamos para no dejar minuta de operación
        this.responseType = null;
      }
    }
    create() {
        const data = this.performData();

        this.loading = true;
        this.apiManager.createPQR(data).subscribe(() => {
          this.resetForm();
          this.loading = false;
        }, () => {
          this.loading = false;
        })
    }

    setRegistrationDate(registrationDate) {
        this.registrationDate = registrationDate?.selectedDate;
    }

    onChange(event) {
        this.hasAttachments = event.checked;
    }

    // get isOperativeSectionAccountable() {
    //     return this.accountableSection === OPERATIVE_SECTION;
    // }

    get isOtherResponseType() {
      return this.responseType?.id === RESPONSE_OTHER_ID;
    }
    get isMaintenance() {
      return this.requestType?.id === REQUEST_MAINTENANCE_ID;
    }

    get isExpansion() {
      return this.requestType?.id === EXPANSION_ID;
    }

    openSelectUserDialog() {
        const dialog = this.dialog.open(SelectUsersDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: { selected: [{...this.selectedReceptor}], selection: SelectUsersDialogComponent.UNIQUE_SELECTION}
          });

        dialog.afterClosed().subscribe((data) => {
            if (data.close) {
                return;
            }
            this.selectedReceptor = data.selectedUser;
            this.receptor = new FormControl(`${this.selectedReceptor.name} ${this.selectedReceptor.surname}`);
        });
    }

    createMaintenance() {
      const dialog = this.dialog.open(CreateMaintenanceDialogComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data: {
          project: this.selectedProjectForm?.value?.selected || { name: this.selectedProjectForm?.value?.typed},
          painDescription: this.pqrsDescription.value
        }
      });

      dialog.afterClosed().subscribe((data) => {
        if (data.close) {
          return;
        }
        this.maintenance = data.maintenance;
      });
    }
}
