<div class="space-width actionable" matRipple role="button" (click)="linkClicked()">
  <div class="header padding-bottom-8">
    <div class="actionable column-full-width">
      <span class="name" *ngIf="number">{{number}}</span>
      <span *ngIf="newLuminaryNumber"> ({{newLuminaryNumber}})</span>
      <i class="name error" *ngIf="!number">Sin número</i>
    </div>
    <div class="margin-right-16">
      <span class="project">{{project}}</span>
    </div>
  </div>
  <div class="container">
    <div class="data">
      <div class="margin-right-16 basic-data">
        <div *ngIf="ubication" class="subtitle ubication actionable" matRipple role="button" aria-label="Abrir en navegador">
          <mat-icon>room</mat-icon> <span class="align-text"> ({{ubication.coordinates[0]}}, {{ubication.coordinates[1]}}) </span>
        </div>
        <div class="subtitle" *ngIf="address">
          <span>{{address}}{{neighborhood ? (" | " + neighborhood): ""}}</span>
        </div>
        <div class="subtitle" *ngIf="pathObservations">
          <span>{{pathObservations}}</span>
        </div>
        <div class="tagged-data">
          <tag-automatic-color icon="memory" [text]="technology" class="actionable" matRipple *ngIf="technology"></tag-automatic-color>
          <tag-automatic-color icon="offline_bolt" [text]="power" class="actionable" matRipple *ngIf="power"></tag-automatic-color>
          <mat-icon *ngIf="newTechnology && newPower">chevron_right</mat-icon>
          <tag-automatic-color icon="memory" [text]="newTechnology" class="actionable" matRipple *ngIf="newTechnology"></tag-automatic-color>
          <tag-automatic-color icon="offline_bolt" [text]="newPower" class="actionable" matRipple *ngIf="newPower"></tag-automatic-color>
        </div>
        <div *ngIf="luminaryOfTemporalUse">
          <mat-icon>timelapse</mat-icon> Luminaria de uso temporal
        </div>
      </div>
    </div>
  </div>
</div>
<div class="actions" *ngIf="actions">
  <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="onClickActionEvent($event)"></contextual-action>
</div>
