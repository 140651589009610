<div class="container">
  <simple-header class="padding-8 background" title="Datos de luminaria" [back]="true" icon="close"></simple-header>
  <app-loading *ngIf="loading"></app-loading>
  <div class="section" *ngIf="this.luminary">
    <div class="action-element-container">
      <h3 class="action-text-title">Datos de identificación de la luminaria</h3>
      <div matRipple role="button" class="actionable action-button" [routerLink]="['/app/luminary/edit-identification-data']" [state]="routeState">Editar</div>
    </div>
    <div class="action-element-container padding-bottom-8">
      <div class="action-text-title"><strong>Fecha de creación: </strong>{{this.luminary.createdAt | formatDate}}</div>
      <div *ngIf="history?.length" matRipple role="button" class="actionable action-button" [routerLink]="['/app/luminary/history']" [queryParams]="{ id: id }">Ver histórico</div>
    </div>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Proyecto: </strong><span [ngClass]="{'error': !this.luminary.project?.name && !this.luminary.projectName}"> {{this.luminary.project?.name || this.luminary.projectName || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Ubicación: </strong><span [ngClass]="{'error': !this.luminary.geolocation}"> ({{this.luminary.geolocation?.coordinates[1]}} , {{this.luminary.geolocation?.coordinates[0]}}) </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Dirección: </strong><span [ngClass]="{'error': !this.luminary.address}"> {{this.luminary.address || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Barrio: </strong><span [ngClass]="{'error': !this.luminary.neighborhood}"> {{this.luminary.neighborhood || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de vía Retilap: </strong><span [ngClass]="{'error': !this.luminary.retilap?.description}"> {{this.luminary.retilap?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Observaciones de ruta: </strong><span [ngClass]="{'error': !this.luminary.pathObservations}"> {{this.luminary.pathObservations || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Número: </strong><span [ngClass]="{'error': !this.luminary.number}"> {{this.luminary.number || 'Sin número'}} </span>
      <span *ngIf="this.luminary.luminaryOfTemporalUse">( Luminaria de uso temporal )</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8 inline-flex">
      <strong>Tecnología: </strong>
      <tag-automatic-color icon="memory" [text]="this.luminary.technology" *ngIf="this.luminary.technology" class="margin-left-8"></tag-automatic-color>
      <span class="error" *ngIf="!this.luminary.technology">&nbsp;No informado</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8 inline-flex">
      <strong>Potencia: </strong>
      <tag-automatic-color icon="memory" [text]="this.luminary.power" *ngIf="this.luminary.power" class="margin-left-8"></tag-automatic-color>
      <span class="error" *ngIf="!this.luminary.power">&nbsp;No informado</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Pérdidas en balasto: </strong><span>{{this.luminary.powerLosses ? this.luminary.powerLosses: 'Sin pérdidas'}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Fabricante: </strong><span [ngClass]="{'error': !this.luminary.maker}">{{this.luminary.maker || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Modelo: </strong><span [ngClass]="{'error': !this.luminary.model}">{{this.luminary.model || 'No informado'}} </span>
    </p>
  </div>
  <div class="section margin-8" *ngIf="this.luminary">
    <div class="action-element-container">
      <h3 class="action-text-title">Características de poste y luminaria</h3>
      <div matRipple role="button" class="actionable action-button" [routerLink]="['/app/luminary/edit-post-data']" [state]="routeState">Editar</div>
    </div>
    <p class="padding-top-8">
      <strong>Número de poste: </strong><span [ngClass]="{'error': !this.luminary.postNumber}"> {{this.luminary.postNumber || 'No informado'}} </span>
    </p>
    <p class="padding-top-8">
      <strong>Tipo: </strong><span [ngClass]="{'error': !this.luminary.luminaryType?.description}"> {{this.luminary.luminaryType?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Especial: </strong><span [ngClass]="{'error': !this.luminary.special?.description}"> {{this.luminary.special?.description || 'Sin número'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Anomalía: </strong><span [ngClass]="{'error': !this.luminary.anomaly?.description}"> {{this.luminary.anomaly?.description || 'Sin número'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de encendido: </strong><span [ngClass]="{'error': !this.luminary.switchedon?.description}"> {{this.luminary.switchedon?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de poste: </strong><span [ngClass]="{'error': !this.luminary.postType?.description}">{{this.luminary.postType?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Clase de poste: </strong><span [ngClass]="{'error': !this.luminary.postClass?.description}">{{this.luminary.postClass?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Altura de poste: </strong><span [ngClass]="{'error': !this.luminary.postHeight}">{{this.luminary.postHeight + ' m.'|| 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Uso particular: </strong><span>{{this.luminary.particularUse ? 'Sí': 'No'}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de zona: </strong><span>{{this.luminary.installationZone?.description}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de espacio iluminado: </strong><span>{{this.luminary.spaceType?.description}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Observaciones: </strong><span [ngClass]="{'error': !this.luminary.observations}">{{this.luminary.observations || 'Sin observaciones'}} </span>
    </p>
    <div class="padding-top-8 inline-flex">
      <strong>Fotos: </strong>
      <span class="error" *ngIf="!this.luminary.photos?.length">&nbsp;Sin fotos</span>
      <div *ngFor="let photo of this.luminary.photos; index as i" class="selected-photo">
        <img [src]="photo | imagePath">
      </div>
    </div>
  </div>
  <div class="section margin-8" *ngIf="this.luminary">
    <div class="action-element-container">
      <h3 class="action-text-title">Características de transformador y red</h3>
      <div matRipple role="button" class="actionable action-button" [routerLink]="['/app/luminary/edit-transformer-data']" [state]="routeState">Editar</div>
    </div>
    <p class="padding-top-8">
      <strong>Número de transformador: </strong><span [ngClass]="{'error': !this.luminary.transformerNumber}"> {{this.luminary.transformerNumber || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Material red eléctrica: </strong><span>{{this.luminary.electricalNetworkMaterialType?.description}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Calibre red eléctrica: </strong><span>{{this.luminary.conductorGauge}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Instalación red eléctrica: </strong><span>{{this.luminary.electricalNetworkInstallationType?.description}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Red eléctrica: </strong><span [ngClass]="{'error': !this.luminary.electricNetwork?.description}">{{this.luminary.electricNetwork?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de transformador: </strong><span>{{this.luminary.useTansformerType?.description}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Instalación transformador: </strong><span>{{this.luminary.transformerInstallationType?.description}}</span>
    </p>
    <mat-divider></mat-divider>
  </div>
  <div class="section margin-8" *ngIf="this.luminary">
    <div class="action-element-container">
      <h3 class="action-text-title">Características de canalización</h3>
      <div *ngIf="isSub" matRipple role="button" class="actionable action-button" [routerLink]="['/app/luminary/edit-pipe-data']" [state]="routeState">Editar</div>
      <div *ngIf="!isSub" class="actionable action-button">No aplica</div>
    </div>
    <p class="padding-top-8">
      <strong>Tipo de canalización: </strong><span> {{this.luminary.useCanalizationType?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Caja de inspección: </strong><span>{{this.luminary.inspectionBox !== undefined ? ( this.luminary.inspectionBox ? 'Sí': 'No') : 'No informado'}}</span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de ductería: </strong><span> {{this.luminary.pipeType?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de zona de ductería: </strong><span> {{this.luminary.pipeZoneType?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
  </div>
</div>
