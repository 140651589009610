import { Component } from '@angular/core';
import { MaintenanceQuestionaryDialogComponent } from '@ildes/basic/maintenance-questionary-dialog/maintenance-questionary-dialog.component';
import taskQuestionary from '../repair-maintenance/questions-maintenance';
import projectDailyQuestionary from '../repair-maintenance/questions-high-permission';
import { MatDialog } from '@angular/material/dialog';
import { GalleryDialogComponent } from '@ildes/basic/gallery-dialog/gallery-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskIdentificationDialogComponent } from '@ildes/views/task-identification-dialog/task-identification-dialog.component';
import { ExpansionDataDialogComponent } from '../expansion-data-dialog/expansion-data-dialog.component';
import getAvailableUsedMaterials from '@ildes/utils/calculate-available-materials';
import { SessionStore } from '@ildes/stores/session.store';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { Location } from '@angular/common';
import { concat, of, throwError, zip } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { UnsyncModernizationsService } from '@ildes/services/unsync-modernizations.service';
@Component({
    selector: 'app-create-expansion',
    template: `
        <connection-alert></connection-alert>
        <mat-progress-bar mode="indeterminate" *ngIf="pendingRequest"></mat-progress-bar>
        <div class="container">
        <div class="scrollable-content">
            <simple-header title="Expansión" [back]="true" icon="close"></simple-header>
            <div class="item">
                <app-list-step-state-item
                    [state]="statusStepSelectProject"
                    [disabled]="false"
                    (click)="openTaskData()"
                    title="Identificación de la labor"
                    [description]="descriptionStepSelectProject">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepQuestionaryDailyPermission"
                    [disabled]="!selectedProject"
                    (click)="openDailyQuestionary()"
                    title="Cuestionario diario para trabajar en alturas"
                    [description]="descriptionStepQuestionaryDailyPermission">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepHighQuestionaryPermission"
                    [disabled]="statusStepQuestionaryDailyPermission!=='COMPLETED'"
                    (click)="openSecurityQuestionary()"
                    title="Confirmación de seguridad"
                    [description]="descriptionStepHighQuestionaryPermission">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepInitialPhotos"
                    [disabled]="statusStepHighQuestionaryPermission!=='COMPLETED'"
                    (click)="openTakePhotos('initial')"
                    title="Tomar fotos del estado inicial"
                    [description]="descriptionStepInitialPhotos">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepExpansionData"
                    [disabled]="statusStepInitialPhotos!=='COMPLETED'"
                    (click)="openDialogExpansion()"
                    title="Datos de la expansión"
                    [description]="descriptionStepExpansionData">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepFinalPhotos"
                    [disabled]="true"
                    title="Fotos del estado final"
                    [description]="descriptionStepFinalPhotos">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
            </div>
            <div class="padding-top-8 padding-bottom-8" *ngIf="invalidSelectedProject">
                <app-alert-dialog
                    text="No se pudo comprobar el proyecto seleccionado. Para finalizar el proceso tienes que seleccionar de nuevo el proyecto"
                    status="advise"></app-alert-dialog>
            </div>
            <div class="align-center padding-top-8" *ngIf="!unsync">
                <button [disabled]="pendingRequest || !completedSteps" mat-raised-button color="primary" (click)="save()" class="button-size-200">Crear expansión</button>
            </div>
            <div *ngIf="!invalidSelectedProject && unsync" class="align-center padding-top-8">
                <button [disabled]="pendingRequest" mat-button (click)="save()" class="button-size-200">
                    <mat-icon class="icon-button">cloud_upload</mat-icon>Sincronizar
                </button>
            </div>
        </div>
        </div>
    `,
    styleUrls: ['./create-expansion.component.css'],
})
export class CreateExpansionComponent {
    public selectedProject;
    public pendingRequest;
    public answersSecurityQuestionary;
    private openDate;
    private luminary;
    private usedMaterials;
    private user;
    private assignedMaintenances;
    private description;
    private ascendMethod;
    private id;
    public unsync;
    private height;
    private dailyQuestionary;
    private resolutionDate;
    private update;
    private orderno;
    public photos = {
        initial: undefined,
        final: undefined
    };
    private activities;
    public signTask() {

    }

    get invalidSelectedProject() {
        return this.selectedProject && !this.selectedProject?.id;
    }

    performExpansionData() {
        const project = {
            name: this.selectedProject.name,
            id: this.selectedProject.id,
        }
        this.luminary.project = project;
        return {
            id: this.id,
            orderno: this.orderno,
            ascendMethod: this.ascendMethod,
            height: this.height,
            selectedProject: project,
            project,
            unsync: this.unsync,
            dailyQuestionary: this.dailyQuestionary,
            answersSecurityQuestionary: this.answersSecurityQuestionary,
            openDate: this.openDate,
            luminary: this.luminary,
            usedMaterials: this.usedMaterials,
            description: this.description,
            photos: this.photos,
            resolutionDate: this.resolutionDate,
            activities: this.activities,
            state: (this.resolutionDate && 'CLOSED')
        };
    }
    public save() {
      let expansionData = this.performExpansionData();

      if (this.invalidSelectedProject) {
        this.unsync = true;
        this.unsyncExpansionsSerive.addItem({...expansionData, unsync: true});

        return;
      }

      this.resolutionDate = this.resolutionDate || new Date().toISOString();
      expansionData = this.performExpansionData();
      this.unsyncExpansionsSerive.addItem(expansionData);

      this.pendingRequest= true;
      const userId = SessionStore.getInstance().get().user.id;
      concat(
          this.syncDailyQuestionary(),
          this.unsyncExpansionsSerive.sync(expansionData, this.update).pipe(tap(() => {
              this.apiManager.getUserDetail(userId).subscribe((user) => {
                  this.pendingRequest = false;
                  SessionStore.updateUserInfo(user, true);
              }, () => {
                  this.pendingRequest = false;
              });
              this.location.back();
          })),
      ).subscribe(() => {
      }, () => {
          this.apiManager.getUserDetail(userId).subscribe((user) => {
              this.pendingRequest = false;
              SessionStore.updateUserInfo(user, true);
          }, () => {
              this.pendingRequest = false;
          });
      });
    }
    private syncDailyQuestionary() {
        if (!this.dailyQuestionary?.pendingSync) {
            return of(null);
        }
        return this.apiManager.updateDailyQuestionary(this.selectedProject.id, {
            questions: projectDailyQuestionary,
            answers: this.dailyQuestionary.answers,
            date: this.dailyQuestionary.date,
        }).pipe(
            tap(() => {
                this.store.dispatch(
                    actionsQuestionaries.update({
                        changes: { pendingSync: false },
                        date: new Date(this.dailyQuestionary.date),
                        projectId: this.selectedProject.id,
                    })
                );
            }),
            catchError((error) => {
                if (error.code === 1) {
                    this.store.dispatch(
                        actionsQuestionaries.update({
                            changes: { answers: this.dailyQuestionary.answers.map(({ id }) => ({ id, value: true })), pendingSync: false },
                            date: new Date(this.dailyQuestionary.date),
                            projectId: this.selectedProject.id,
                        })
                    );
                }
                return throwError(error);
            })
        )
    }
    public get completedSteps() {
        return this.statusStepQuestionaryDailyPermission === 'COMPLETED' && this.statusStepExpansionData === 'COMPLETED';
    }

    public get statusStepSelectProject() {
        if (this.selectedProject?.id && this.height && this.ascendMethod) {
            return 'COMPLETED';
        } else {
            return 'PENDING';
        }
    }


    public get descriptionStepSelectProject() {
        if (this.selectedProject) {
            return `Proyecto seleccionado: ${this.selectedProject.name}.<br>
                Altura: ${this.height || '-'}.<br>
                Método de ascenso: ${this.ascendMethod?.description || '-'}.`;
        } else {
            return 'Debes seleccionar un proyecto para poder comenzar.';
        }
    }

    public get statusStepQuestionaryDailyPermission() {
        if (!this.dailyQuestionary?.answers) {
            return 'PENDING';
        }
        const responsedAnswers = this.dailyQuestionary?.answers?.filter(({ value }) => value)?.length;

        if (responsedAnswers === projectDailyQuestionary?.length) {
            return 'COMPLETED';
        } else {
            return 'IN_PROGRESS';
        }
    }

    public get statusStepHighQuestionaryPermission() {
        if (!this.answersSecurityQuestionary) {
            return 'PENDING';
        }
        const responsedAnswers = this.answersSecurityQuestionary?.filter(({ value }) => value)?.length;

        if (responsedAnswers === taskQuestionary?.length) {
            return 'COMPLETED';
        } else {
            return 'IN_PROGRESS';
        }
    }

    public get descriptionStepQuestionaryDailyPermission() {
        if (this.dailyQuestionary?.answers?.length > 0) {
            const responsedAnswers = this.dailyQuestionary?.answers.filter(({ value }) => value).length;

            return `Contestadas ${responsedAnswers} de ${projectDailyQuestionary.length}`;
        }

        return 'Completa el formulario diario de permiso de trabajo';

    }
    public get descriptionStepHighQuestionaryPermission() {
        if (this.answersSecurityQuestionary?.length > 0) {
            const responsedAnswers = this.answersSecurityQuestionary.filter(({ value }) => value).length;

            return `Contestadas ${responsedAnswers} de ${taskQuestionary.length}`;
        }

        return 'Completa el cuestionario de permiso en alturas.';

    }
    public get statusStepInitialPhotos() {
        return this.photos.initial ? 'COMPLETED' : 'PENDING';
    }
    public get descriptionStepInitialPhotos() {
        if (this.photos.initial) {
            return `${this.photos.initial.length} añadidas.`;
        } else {
            return 'Toma las fotos del estado del terrreno.';
        }
    }
    public get descriptionStepExpansionData() {
        return !this.luminary ?
            'Introduce los datos de la nueva luminaria.' :
            `Material usado: ${this.humaniceMaterials(this.usedMaterials)}.<br>
            Observaciones: ${this.description || '-'}.<br>
            Tecnología: ${this.luminary.technology}.<br>
            Potencia: ${this.luminary.power}.<br>
            Actividades realizadas: ${this.activities?.length || '-'}.`;
    }
    public get statusStepFinalPhotos() {
        return this.photos.final ? 'COMPLETED' : 'PENDING';
    }
    public get descriptionStepFinalPhotos() {
        if (this.photos.final) {
            return `${this.photos.final.length} añadidas.`;
        } else {
            return 'Toma las fotos al censar la luminaria.';
        }
    }
    public get statusStepExpansionData() {
        return this.luminary ? 'COMPLETED' : 'PENDING';
    }
    private humaniceMaterials(materials) {
        if (!materials?.length) {
            return '-'
        }
        return materials.map((material) => `${material.reference.name} x${material.amount}`).join(',');
    }
    constructor(
        private dialog: MatDialog,
        private store: Store<AppState>,
        private unsyncExpansionsSerive: UnsyncExpansionsService,
        private unsyncModernizations: UnsyncModernizationsService,
        private route: ActivatedRoute,
        private location: Location,
        private apiManager: ApiManagerService,
    ) {
        SessionStore.getInstance().get$().subscribe((data) => {
            this.user = data?.user;
            this.store.dispatch(
                actions.loadMaintenances({
                    assigned: this.user.id,
                    state: undefined,
                    options: { cache: true }
                })
            )
        });

        this.store.select('assignedMaintenances').subscribe((data) => {
            this.assignedMaintenances = data;
        });

        const state = history.state.expansion;

        if (state) {
          // this.unsync = state.unsync;
          this.id = state.id;
          this.selectedProject = state.project; //
          //this.usedMaterials = state.usedMaterials;
          //this.answersSecurityQuestionary = state.answersSecurityQuestionary;
          this.openDate = state.openDate;
          this.update = true;
          //this.luminary = state.luminary;
          //this.photos = state.photos;
          //this.description = state.description;
          //this.ascendMethod = state.ascendMethod;
          //this.height = state.height;
          //this.resolutionDate = state.resolutionDate;

          if (this.selectedProject.id) {
              this.findQuestionary();
          }
        }
        this.update = false; // no hay qp, por tanto entramos por el boton de crear
        this.route.queryParams.subscribe(params => {
            this.store.dispatch(
                actionsQuestionaries.loadDailyQuestionaries()
            );
            const value = this.unsyncExpansionsSerive.findItem(params.id);
            this.id = params.id;
            this.orderno = params.orderno;
            if (!value) {
              this.update = true; // no existe el item, por tanto venimos a modificar
              return;
            }
            this.orderno = value.orderno;
            this.unsync = value.unsync;
            this.selectedProject = value.selectedProject;
            this.usedMaterials = value.usedMaterials;
            this.answersSecurityQuestionary = value.answersSecurityQuestionary;
            this.openDate = value.openDate;
            this.luminary = value.luminary;
            this.photos = value.photos;
            this.description = value.description;
            this.ascendMethod = value.ascendMethod;
            this.height = value.height;
            this.resolutionDate = value.resolutionDate;
            this.update = value.update; // el additem del servicio lo habrá puesto bien
            this.activities = value.activities;
            if (this.selectedProject?.id) {
                this.findQuestionary();
            } else {
                this.dailyQuestionary = value.dailyQuestionary
            }
        });
    }

    openTaskData() {
        const dialog = this.dialog.open(TaskIdentificationDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                project: this.selectedProject,
                ascendMethod: this.ascendMethod,
                height: this.height,
                requiredProject: true,
            }
        });

        dialog.afterClosed().subscribe((data) => {
            if (!data.commit) {
                return;
            }
            this.ascendMethod = data.ascendMethod;
            this.height = data.height;
            this.selectedProject = data.project;

            if (this.luminary) {
              this.id = this.unsyncExpansionsSerive.addItem(this.performExpansionData());
            }
            if (this.selectedProject.id) {
                this.findQuestionary();
            }
        });
    }

    private sameDate(day1,day2) {
        return (
          day1.getFullYear() === day2.getFullYear() &&
          day1.getMonth() === day2.getMonth() &&
          day1.getDate() === day2.getDate()
        );
      }

    findQuestionary() {
        this.store.select('dailyQuestionaries').pipe(first()).subscribe((data) => {
            const consignedDate = this.getConsignedDate();

            const tmpDailyQuestionary = data.find(({ projectId, date }) => projectId === this.selectedProject.id && this.sameDate(consignedDate, date));
            if (tmpDailyQuestionary) {
              this.dailyQuestionary = tmpDailyQuestionary;
            } else if (this.dailyQuestionary) {
              this.dailyQuestionary.projectId = this.selectedProject.id;
            }
            else {
                this.fetchDailyQuestionary(this.user.id);
            }
        });

        // return this.store.select('dailyQuestionaries').subscribe((data: any) => {
        //     const consignedDate = this.getConsignedDate();

        //     this.dailyQuestionary = data.find(({ projectId, date }) => projectId === this.selectedProject?.id && this.sameDate(consignedDate, date));
        // })
    }

    getConsignedDate() {
        return this.resolutionDate ? new Date(this.resolutionDate) : new Date();
    }

    fetchDailyQuestionary(technicianId?) {
        const consignedDate = this.getConsignedDate();

        this.apiManager.getDailyQuestionary(this.selectedProject?.id, { date: consignedDate.toISOString(), technicianId } , { error: false }).subscribe((data: any) =>{
          if (!data.data) {
            return;
          }
          this.dailyQuestionary = {
            answers: data.data.questionary,
            date: new Date(data.data.date),
            projectId: this.selectedProject?.id
          };
        });
      }

    openDailyQuestionary() {
        // completar cuestionario
        const dialog = this.dialog.open(MaintenanceQuestionaryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                title: 'Cuestionario diario para trabajar en alturas',
                readOnly: false,
                questions: projectDailyQuestionary,
                answers: this.dailyQuestionary?.answers
            }
        });

        dialog.afterClosed().subscribe((data) => {
            if (!data.commit) {
                return;
            }
            const openDate = new Date().toISOString();
            const updated = !!this.dailyQuestionary;
            this.dailyQuestionary = {
                questions: projectDailyQuestionary,
                answers: data.answers,
            };
            // TODO: ver qué pasa si no tenemos el proyecto (permitir hacerlo por nombre también)
            if (!this.selectedProject.id) {

                return;
            }
            this.apiManager.updateDailyQuestionary(this.selectedProject.id, {
                questions: projectDailyQuestionary,
                answers: data.answers,
                date: openDate,
              }).subscribe(() => {
                this.performSaveDailyPermission({ data, updated }, false);
              }, (error) => {
                // en este caso ya se completó el cuestionario de forma correcta y no se puede modificar
                // restauramos a todo true
                if (error.code === 1) {
                  data.answers.forEach((answer) => {
                    answer.value = true;
                  });

                  this.performSaveDailyPermission({ data, updated }, false);

                  return;
                }
                if (!error.code || error.code > 0) {
                  return;
                }
                this.performSaveDailyPermission({ data, updated }, true);
              });
        });
    }

    public performSaveDailyPermission({ data, updated }, pendingSync = false) {
        if (!updated) {
          this.store.dispatch(
            actionsQuestionaries.newQuestionary({
              answers: data.answers,
              date: new Date(),
              projectId: this.selectedProject.id,
              pendingSync
            })
          );
        } else {
          this.store.dispatch(
            actionsQuestionaries.update({
              changes: { answers: data.answers, pendingSync },
              date: new Date(),
              projectId: this.selectedProject.id,
            })
          );
        }
      }

    openSecurityQuestionary() {
        // completar cuestionario
        const dialog = this.dialog.open(MaintenanceQuestionaryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                readOnly: false,
                questions: taskQuestionary,
                title: 'Cuestionario de seguridad',
                answers: this.answersSecurityQuestionary,
            }
        });

        dialog.afterClosed().subscribe((data) => {
            if (!data.commit) {
                return;
            }
            this.answersSecurityQuestionary = data.answers;
        });
    }

    openTakePhotos(step) {
        // asociamos fotos a estado inicial o estado final
        const dialog = this.dialog.open(GalleryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                readOnly: false,
                photos: this.photos.initial
            }
        });

        dialog.afterClosed().subscribe(async (data) => {
            if (!data?.commit) {
                return;
            }

            this.photos[step] = data.photos;

            if (this.luminary) {
              this.id = this.unsyncExpansionsSerive.addItem(this.performExpansionData());
            }
        })
    }

    openDialogExpansion() {
        const dialog = this.dialog.open(ExpansionDataDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                readOnly: false,
                luminary: this.luminary,
                project: this.selectedProject,
                activities: this.activities,
                availableMaterials: getAvailableUsedMaterials(
                  this.user.confirmedMaterials,
                  this.assignedMaintenances,
                  this.unsyncExpansionsSerive.getItems()?.filter(({state, unsync}) => unsync || state !== 'CLOSED' ),
                  this.unsyncModernizations.getItems()?.filter(({state, unsync}) => unsync || state !== 'CLOSED' )
                ),
                usedMaterials: this.usedMaterials?.map(({ amount, reference }) => ({ amount, reference })),
                descripiton: this.description
            }
        });

        dialog.afterClosed().subscribe(async (data) => {
            if (data.closed) {
                return;
            }
            this.luminary = data.luminary;
            this.description = data.description;
            this.usedMaterials = data.usedMaterials;
            this.activities = data.activities;
            this.photos.final = this.luminary.photos || [];
            this.id = this.unsyncExpansionsSerive.addItem(this.performExpansionData());
        });
    }

}
