import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import constants from '@ildes/config/constants';
import { Location } from '@angular/common';
import { ToastService } from '@ildes/services/toast.service';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/list-luminaries/list-luminaries.actions';

@Component({
  selector: 'app-edit-pipe-data',
  templateUrl: './edit-pipe-data.component.html',
  styleUrls: ['./edit-pipe-data.component.css']
})
export class EditPipeDataComponent implements OnInit {

  loading;
  luminary;

  useCanalizationTypes = constants.USE_CANALIZATION_TYPES;
  selectedUseCanalizationType;
  pipeTypes = constants.PIPE_TYPES;
  selectedPipeType;
  pipeZoneTypes = constants.PIPE_ZONE_TYPES;
  selectedPipeZoneType;
  inspectionBox;

  constructor(
    private store: Store<AppState>,
    private apiManager: ApiManagerService,
    private location: Location,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    const state:any = this.location.getState();

    this.luminary = state.luminary;
    if (!this.luminary) {
      this.location.back();

      return;
    }

    this.selectedUseCanalizationType = this.useCanalizationTypes.find(({id}) => this.luminary.useCanalizationType?.id === id);
    this.selectedPipeType = this.pipeTypes.find(({id}) => this.luminary.pipeType?.id === id);
    this.selectedPipeZoneType = this.pipeZoneTypes.find(({id}) => this.luminary.pipeZoneType?.id === id);
    this.inspectionBox = this.luminary.inspectionBox;
  }


  save() {
    this.loading = true;

    const luminary = {
      project: this.luminary.project?.id,
      useCanalizationType:this.selectedUseCanalizationType,
      inspectionBox: this.inspectionBox,
      pipeType: this.selectedPipeType,
      pipeZoneType: this.selectedPipeZoneType
    };

    this.apiManager.updateLuminary(this.luminary.id, {...this.luminary, ...luminary}).subscribe(() => {
      this.loading = false;

      this.store.dispatch(
        actions.remove({ _id: this.luminary._id})
      )
      this.location.back();
    }, (error) => {
      this.loading = false;
      if (!error.code || error.code > 0) {
        return;
      }

      const luminaryMerged = {
        ...this.luminary,
       ...luminary
      };

      if (this.luminary._id) {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.update({ id: this.luminary._id, changes: luminaryMerged })
        )
      } else {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.create({ luminary: luminaryMerged })
        )
      }
      this.location.back();
    });
  }

}
