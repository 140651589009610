import { Component, OnInit, ContentChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../services/api-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'list-users-composition',
  templateUrl: './list-users-composition.component.html',
  styleUrls: ['./list-users-composition.component.css']
})
export class ListUsersCompositionComponent implements OnInit {

  users = [];
  loading;
  pagination;
  filter;
  @Input() maxSelectedUsers;
  @Input() gotoUserDetail;
  @Input() belongsToMyProjects;
  @Input() project;
  @Input() selectedUsers = [];
  @Input() disabledUsers = [];
  @Input() paddingBottom;
  @Input() selector;
  @Input() scrollMode;
  @Input() actionEvents;
  @Input() roleId;
  @Output() doAction = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() onClickItem = new EventEmitter();

  searchFormGroup = new UntypedFormGroup({
    complexName: new UntypedFormControl(),
  });

  get sortedUsers() {
    const selected = [];
    const any = [];

    return [...this.selectedUsers, ...this.users.filter((user) => !this.selectedUsers.find(({ id }: any) => user.id === id))];
  }
  constructor(
    private apiManager: ApiManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.selectedUsers = this.setSelectedUsers(this.selectedUsers || []);
    this.list();
  }

  gotoUser(user) {
    this.onClickItem.emit({ user });

    if (!this.gotoUserDetail) {
      return;
    }
    this.router.navigate(['/app/user'], { queryParams: { id: user.id } });
  }

  paginate(event) {
    this.list({ page: event.pageIndex + 1, mode: event.mode})
  }

  onDoAction(action, user) {
    this.doAction.emit({action, user});
  }

  onChange(event, user) {
    user.selected = event.checked;
    this.doAction.emit({action: 'change-selection', checked: event.checked, user});
  }

  get maxUserSelectedReached() {
    return this.maxSelectedUsers && this.sortedUsers.filter(( { selected }) => selected).length === this.maxSelectedUsers;
  }

  onScroll(event) {
    const { scrollTop, scrollHeight, offsetHeight } = event.target;

    if (this.scrollMode !== 'infinite' || !this.pagination?.hasNextPage) {
      return;
    }
    if (scrollTop >= scrollHeight - offsetHeight) {
      this.list({ page: this.pagination.nextPage, mode: event.mode});
    }
  }

  private setSelectedUsers(users) {
    return this.selectedUsers ? users.map((user) =>
      Object.assign(user, {
        selected: Boolean(this.selectedUsers.find(({ id }: any) => id === user.id)),
        disabled: Boolean(this.disabledUsers.find(({ id }: any) => id === user.id))
      })): users;
  }

  private list(pagination: any = null) {
    this.loading = true;
    const add = pagination?.mode === 'infinite';
    const paramPagination = pagination && { page: pagination.page };
    const filter = this.composeFilter();

    this.apiManager.listUsers({...paramPagination, ...filter}).subscribe((users: any) => {
      this.loading = false;
      if (!users?.data?.length) {
        this.users = [];
        this.pagination = null;

        return;
      }
      if (add) {
        this.users = [...this.users, ...this.setSelectedUsers(users.data)];
      } else {
        this.users = this.setSelectedUsers(users.data);
      }
      this.pagination = users.pagination;
    }, (data) => {
      this.loading = false;
    });
  }

  composeFilter() {
    return {
      ...(this.filter || {}),
      ...this.roleId && { roleId: this.roleId },
      ...this.project && { projectId: this.project},
      ...this.belongsToMyProjects && { belongsToMyProjects: this.belongsToMyProjects }
    }
  }

  findByName() {
    this.filter = {
      complexName: this.searchFormGroup.get('complexName').value
    };

    this.list();
  }


  removeFilters() {
    this.filter = null;

    this.list();
  }

  public get humanizeFilter() {
    if (!this.filter) {
      return;
    }

    return [{
      key: 'Nombre: ',
      value: this.filter.complexName,
    }]
  }
}
