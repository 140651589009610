<simple-header (action)="headerAction()" [actions]="headerActions" title="Identificación de la labor" icon="close"></simple-header>
<mat-vertical-stepper [linear]="true" #stepper>
  <form [formGroup]="taskData" autocomplete="off">
  <mat-step [completed]="!!selectedProject" *ngIf="!ignoreProject">
    <ng-template matStepLabel>Indique el proyecto</ng-template>
    <div class="inline-flex padding-top-8">
      <predictive-searcher
          formControlName="selectedProject"
          label="Proyecto"
          [service]="searchProjectAdapter"
          class="column margin-right-8"></predictive-searcher>
    </div>
    <button [disabled]="!selectedProject" mat-button matStepperNext color="primary">Siguiente</button>
  </mat-step>
  <mat-step [completed]="!!luminary">
    <ng-template matStepLabel>Indique el número de luminaria</ng-template>
    <div class="inline-flex padding-top-8">
      <predictive-searcher
        class="column"
        formControlName="luminary"
        label="Número de luminaria"
        [service]="searchLuminaryAdapter"></predictive-searcher>
    </div>
    <button [disabled]="!luminary" mat-button matStepperNext color="primary">Siguiente</button>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Indique la altura aproximada</ng-template>
      <mat-form-field appearance="outline" class="full-width padding-top-8">
        <mat-label>Altura de la luminaria</mat-label>
        <input required type="number" matInput formControlName="height">
      </mat-form-field>
      <button [disabled]="height.invalid" matStepperNext mat-button matStepperNext color="primary">Siguiente</button>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Seleccione el sistema de ascenso</ng-template>
    <mat-form-field appearance="outline" class="full-width padding-top-8">
      <mat-label>Sistema de ascenso</mat-label>
      <mat-select required formControlName="ascendMethod">
        <mat-option *ngFor="let option of ascendMethodOptions" [value]="option.id">
          {{option.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button [disabled]="ascendMethod.invalid" matStepperNext mat-button matStepperNext color="primary">Siguiente</button>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirme los datos antes de continuar</ng-template>
    <key-value-item key="Luminaria" [value]="luminary"></key-value-item>
    <key-value-item key="Altura" [value]="height.value"></key-value-item>
    <key-value-item key="Sistema de ascenso" [value]="ascendMethodOption?.description"></key-value-item>
    <div class="align-center padding-top-8">
      <button mat-raised-button color="primary" (click)="close(true)" class="button-size-200">Aceptar</button>
    </div>
  </mat-step>
  </form>

</mat-vertical-stepper>


