import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '../list-luminaries/list-luminaries.actions';
import { first } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-luminary',
  templateUrl: './luminary.component.html',
  styleUrls: ['./luminary.component.css']
})
export class LuminaryComponent implements OnInit, OnDestroy {

  id;
  loading;
  luminary;
  observers = [];
  history;
  constructor(
    private route: ActivatedRoute,
    private apiManager: ApiManagerService,
    private location: Location,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) {
    this.store.dispatch(
      actions.initState()
    );
  }

  ngOnDestroy(): void {
    this.observers.forEach(s => s.unsubscribe());
  }
  ngOnInit(): void {

    const queryParamsObserver = this.route.queryParams.pipe((first())).subscribe(params => {
      this.id = params.id;

      this.apiManager.getHistoryLuminary(this.id).subscribe((data:any) => {
        this.history = data?.data;
      });

      const storeObserver = this.store.select('luminaries').pipe().subscribe((luminaries) => {
        this.luminary = luminaries.find(({id}) => id == this.id);

        if (this.luminary) {
          return;
        }
        this.loading = true;
        this.apiManager.getLuminaryDetail(this.id).subscribe((luminary) => {
          this.luminary = luminary;
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      });

      this.observers.push(storeObserver)
    });
    this.observers.push(queryParamsObserver)
  }

  get isSub() {
    return this.luminary.electricalNetworkInstallationType?.id === 'SUB';
  }
  get routeState() {
    return { luminary: this.luminary };
  }
}
