<div class="container">
  <div class="scrollable-content">
    <simple-header (action)="headerAction()" [actions]="headerActions" title="Material usado" icon="close"></simple-header>
    <div *ngIf="presetMaterials">
      <h3>Datos de la reparación</h3>
      <div *ngIf="presetMaterials && !usedMaterials?.length" class="align-center">
        No se ha usado material para la reparación
      </div>
      <div class="padding-left-8 padding-bottom-16">
        <div *ngFor="let material of cleanListUsedMaterial" class="item">
          <list-material-item
            [name]="material.reference.name"
            [unit]="material.reference.unit"
            [amount]="material.amount"
            class="padding-top-8">
          </list-material-item>
          <mat-divider></mat-divider>
        </div>
      </div>
      <h3>Material Retirado</h3>
      <div *ngIf="presetMaterials && !extractedMaterials.length" class="align-center">
        Ningún material retirado
      </div>
      <div class="padding-left-8 padding-bottom-16">
        <div *ngFor="let material of cleanListRetiredMaterial" class="item">
          <list-material-item
            [amount]="material.amount"
            [name]="material.reference.name"
            [unit]="material.reference.unit"
            class="padding-top-8">
          </list-material-item>
          <mat-divider></mat-divider>
        </div>
      </div>
      <h3>Actividades Realizadas</h3>
      <div class="padding-left-8 padding-bottom-16">
        <ul>
          <li *ngFor="let activity of activities">
            {{activity.description}} <span *ngIf="activity.extraData">({{activity.extraData}})</span>
          </li>
        </ul>
      </div>
      <h3>Datos de nueva luminaria</h3>
      <div class="padding-left-8 padding-bottom-16">
        <ul *ngIf="newLuminaryNumber; else elseTemplate">
          <li>
            Número: {{newLuminaryNumber}}
          </li>
          <li>
            Tecnología: {{technology}}
          </li>
          <li>
            Potencia: {{power}}
          </li>
          <li>
            Uso temporal: {{luminaryOfTemporalUse ? 'Sí': 'No'}}
          </li>
        </ul>
        <ng-template #elseTemplate>
          No hay datos de nueva luminaria
        </ng-template>
      </div>
      <h3>Observaciones</h3>
      <div class="description padding-left-8 padding-bottom-16">
        {{this.description}}
      </div>
      <div class="padding-top-8 padding-bottom-8">
        <app-alert-dialog text="Cancela el material y vuelve a establecerlo si no has podido sincronizar" status="advise"></app-alert-dialog>
      </div>
      <div class="padding-top-8 padding-bottom-8">
        <app-alert-dialog text="Si cancelas el material el mantenimiento quedará sin material asignado y volverás a disponer del material en tu inventario" status="advise"></app-alert-dialog>
      </div>
      <div class="align-center padding-top-8 padding-bottom-8">
        <button [disabled]="loading" mat-raised-button color="primary" (click)="resetMaterial()">Cancelar material</button>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
    <mat-vertical-stepper [linear]="true" #stepper *ngIf="!presetMaterials">
      <mat-step [completed]="false">
        <ng-template matStepLabel>Material usado en la reparación</ng-template>
        <div *ngFor="let material of usedMaterials">
          <div class="material padding-top-8 padding-bottom-8">
            <div class="fill center">
              <div>
              {{material.reference.name}}
              </div>
              <div class="description padding-top-8">
                Máximo {{material.max}}
              </div>
            </div>
            <input-material-value
              [min]="0"
              [max]="material.max"
              class="vertical-center"
              [material]="material"
              [(value)]="material.amount"></input-material-value>
          </div>
        </div>
        <button (click)="setUsedMaterials()" mat-button color="primary">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel>Datos de nueva luminaria</ng-template>
        <div class="margin-top-16">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nuevo número de luminaria</mat-label>
            <input matInput [formControl]="newLuminaryNumberForm">
          </mat-form-field>
        </div>
        <div class="inline-flex">
          <div class="inline-flex column">
            <predictive-searcher
              label="Tecnología"
              [formControl]="technologyForm"
              [service]="searchLuminaryTypesAdapter"
              class="column margin-right-8"></predictive-searcher>
            <mat-form-field appearance="outline" class="column margin-right-column-8">
              <mat-label>Potencia</mat-label>
              <input [formControl]="powerForm" matInput maxlength="20">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="padding-top-8 padding-bottom-8">
          <mat-checkbox [(ngModel)]="luminaryOfTemporalUse" [ngModelOptions]="{standalone: true}">Luminaria de uso temporal</mat-checkbox>
        </div>
        <button mat-button color="primary" (click)="continue()">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Material retirado </ng-template>
        <form [formGroup]="materialSeacherForm" class="padding-top-8">
          <div class="inline-flex">
            <predictive-searcher
              formControlName="materialName"
              label="Añadir material"
              [service]="searchMaterialAdapter"
              (selectedValue)="onSelectedValue($event)"
              class="column margin-right-8"
              ></predictive-searcher>
          </div>
          <div class="flex-row">
            <button class="right" (click)="addUnknownMaterial()" [disabled]="!typedRetiredMaterial" mat-button>Añadir</button>
          </div>
        </form>
        <div *ngFor="let material of extractedMaterials">
          <div class="material padding-top-8 padding-bottom-8">
            <div class="fill center">
              <div>
              {{material.reference.name}}
              </div>
            </div>
            <input-material-value
              [min]="0"
              class="vertical-center"
              [material]="material"
              [(value)]="material.amount"></input-material-value>
          </div>
          <mat-divider></mat-divider>
        </div>
        <button mat-button (click)="continue()" color="primary">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Actividades realizadas </ng-template>
        <app-list-dynamic-activities (onChangeActivities)="updateActivities($event)" [selectedActivities]="this.initActivities" [readOnlyList]="defaultActivityList"  ></app-list-dynamic-activities>
        <button (click)="continue()" mat-button color="primary" class="margin-top-16">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Observaciones </ng-template>
        <form [formGroup]="formDescription" autocomplete="off" class="padding-top-8">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Observaciones de la reparación</mat-label>
            <textarea rows="2" matInput formControlName="description"></textarea>
            <mat-hint *ngIf="causeOfNotextractedMaterials" style="margin-bottom:18px">
              Recuerda indicar por qué no has retirado material.
            </mat-hint>
          </mat-form-field>
          <div aria-hidden *ngIf="causeOfNotextractedMaterials" style="margin-bottom:18px">
          </div>
          <button [disabled]="formDescription.invalid" (click)="continue()" mat-button matStepperNext color="primary">Siguiente</button>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel> Confirmación </ng-template>
        <h3>Material Usado</h3>
        <div *ngIf="!usedMaterials.length" class="align-center">
          No se ha usado material para la reparación
        </div>
        <div class="padding-left-8 padding-bottom-16">
          <div *ngFor="let material of cleanListUsedMaterial" class="item">
            <list-material-item
              [name]="material.reference.name"
              [unit]="material.reference.unit"
              [amount]="material.amount"
              class="padding-top-8">
            </list-material-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <h3>Material Retirado</h3>
        <div *ngIf="!extractedMaterials.length" class="align-center">
          Ningún material retirado
        </div>
        <div class="padding-left-8 padding-bottom-16">
          <div *ngFor="let material of cleanListRetiredMaterial" class="item">
            <list-material-item
              [amount]="material.amount"
              [name]="material.reference.name"
              [unit]="material.reference.unit"
              class="padding-top-8">
            </list-material-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <h3>Actividades Realizadas</h3>
        <div class="padding-left-8 padding-bottom-16">
          <ul>
            <li *ngFor="let activity of activities">
              {{activity.description}} <span *ngIf="activity.extraData">({{activity.extraData}})</span>
            </li>
          </ul>
        </div>
        <h3>Datos de nueva luminaria</h3>
        <div class="padding-left-8 padding-bottom-16">
          <ul *ngIf="newLuminaryNumberForm.value; else elseTemplate">
            <li>
              Número: {{newLuminaryNumberForm.value}}
            </li>
            <li>
              Tecnología: {{technologyForm.value?.typed}}
            </li>
            <li>
              Potencia: {{powerForm.value}}
            </li>
            <li>
              Uso temporal: {{luminaryOfTemporalUse ? 'Sí': 'No'}}
            </li>
          </ul>
          <ng-template #elseTemplate>
            No hay datos de nueva luminaria
          </ng-template>
        </div>
        <h3>Observaciones</h3>
        <div class="description padding-left-8 padding-bottom-16">
          {{formDescription.controls?.description?.value}}
        </div>
        <button mat-button (click)="confirm()" color="primary">Continuar</button>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
