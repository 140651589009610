import { humanizeDate } from "../../utils/formatters";

function getProp( object, keys, defaultVal ){
  keys = Array.isArray( keys )? keys : keys.split('.');
  object = object[keys[0]];
  if( object && keys.length>1 ){
    return getProp( object, keys.slice(1), defaultVal );
  }
  return object === undefined? defaultVal : object;
}

const columns = {
  'orderno': {
    name: 'Número de orden',
    path: 'orderno',
  },
  'pqr': {
    name: 'Número de pqr',
    path: 'pqr.orderno',
  },
  'type': {
    name: 'Tipo',
    path: 'type.description',
  },
  'createdAt': {
    name: 'Fecha de alta',
    parse: (maintenance) => {
      if (!maintenance.createdAt) {
        return '';
      }
      return humanizeDate(maintenance.createdAt, true);
    }
  },
  'repairmentDate': {
    name: 'Fecha de cierre',
    parse: (maintenance) => {
      if (!maintenance.resolutionDate) {
        return '';
      }
      return humanizeDate(maintenance.resolutionDate, true);
    }
  },
  'oldLuminary': {
    name: 'Número de luminaria anterior',
    path: 'oldLuminaryNumber',
  },
  'newLuminaryNumber': {
    name: 'Número de luminaria sustituido',
    path: 'newLuminaryNumber',
  },
  'oldTechnology': {
    name: 'Tecnología anterior',
    path: 'oldTechnology'
  },
  'oldPower': {
    name: 'Potencia anterior',
    path: 'oldPower'
  },
  'luminary': {
    name: 'Número de luminaria',
    path: 'luminary.number'
  },
  'technology': {
    name: 'Tecnología',
    parse: (order) => {
      return order.newTechnology || order.luminary?.technology || '-';
    }
  },
  'power': {
    name: 'Potencia',
    parse: (order) => {
      return order.newPower || order.luminary?.power || '-';
    }
  },
  'latitude': {
    name: 'Latitud',
    parse: ({luminary}) => {
      return luminary?.geolocation?.coordinates && luminary?.geolocation?.coordinates[0];
    }
  },
  'longitude': {
    name: 'Longitud',
    parse: ({luminary}) => {
      return luminary?.geolocation?.coordinates && luminary?.geolocation?.coordinates[1];
    }
  },
  'cedula': {
    name: 'Cédula',
    path: 'pqr.petitioner.identification'
  },
  'name': {
    name: 'Nombre',
    path: 'pqr.petitioner.name'
  },
  'contact': {
    name: 'Contacto',
    parse: (maintenance) => {
      return maintenance.pqr?.petitioner?.phone || maintenance.pqr?.petitioner?.mobilePhone || maintenance.pqr?.petitioner?.otherContact;
    }
  },
  'asignado': {
    name: 'Técnico asignado',
    parse: (maintenance) => {
      if (!maintenance?.technician) {
        return 'Sin asignar';
      }
      return `${maintenance?.technician?.name || ''} ${maintenance?.technician?.surname || ''}`;
    }
  },
  'projectName': {
    name: 'Proyecto',
    path: 'project.name',
  },
  'location': {
    name: 'Ubicación',
    parse: (maintenance) => {
      const address = maintenance.luminary?.address || maintenance.address;
      const locationReferenceDescription = maintenance.luminary?.locationReferenceDescription || maintenance.locationReferenceDescription;
      return `${address || ''} ${locationReferenceDescription || ''}`.trim();
    }
  },
  'neighborhood': {
    name: 'Barrio'
  }
};

export const resumedColumns = {
  ...columns,
  'activities': {
    name: 'Actividades',
    parse: (maintenance) => {
      return maintenance?.activities?.length || 0;
    }
  },
  'coste': {
    name: 'Coste Total',
    path: 'totalCost'
  }
};

export const detailedColumns = {
  ...columns,
  'activities': {
    name: 'Actividad',
    parse: (maintenance) => {
      const activities = maintenance.activities;

      if (!Object.values(activities || {})?.length) {
        return '-';
      }
      return `${activities.description} ${activities.extraData ? `(${activities.extraData})`: ''}`;
    }
  },
}

export const parse = (items, columns) => {
  const columnValues = Object.values(columns);
  const columnNames = Object.keys(columns);



  return items.map((item) => {
    return columnValues.map((columnConfig, index) => {
      let value = '';
      if (columnConfig.parse) {
        value = columnConfig.parse(item);
      }
      else if (columnConfig.path) {
        value = getProp(item, columnConfig.path, '-');
      }
      else if (columnConfig.descriptions) {
        value = columnConfig.descriptions[item[columnNames[index]]];
      } else {
        value = item[columnNames[index]] || '-';
      }

      return {
        value,
        ...columnConfig.config && { config: columnConfig.config }
      }
    })
  })
}


