import { Injectable, Inject } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { StorageService } from '@ildes/services/storage-service.service';
import { ToastService } from '@ildes/services/toast.service';
import { environment } from '../../environments/environment';

const HOST = environment.host;
const PORT = environment.port;

import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private notified = {};
  constructor(private storage: StorageService, private toastService: ToastService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {

    const cache = req.headers.get('cache') === 'true';

    // if (!req.headers.has('cache') || !cache) {
    //   return next.handle(req);
    // }

    const headers = req.headers.delete('cache');
    const [,endpoint] = req.urlWithParams.split(`${HOST}:${PORT}/`);
    const key = btoa(endpoint);
    const data = this.storage.getItem(key, true);

    if (!cache) {
      return next.handle(req).pipe(
        tap((response: any) => {
          if (!(response instanceof HttpResponse)) {
            return;
          }
          if (data && response?.body) {
            this.storage.setItem(key, response.body);
          }
        }),
        map((response: any) => {
          if (!(response instanceof HttpResponse)) {
            return response;
          }
          if (!response?.body) {
            if (!this.notified[key]) {
              this.toastService.show(`Datos actualizados por última vez ${new Date(data.metadata.timestamp).toLocaleString()}`, 'warning');
            }
            return data;
          } else {
            return response;
          }
        }),
        catchError((e) => {
          if (!data) {
            return throwError(e);
          }
          return of(new HttpResponse({
            status: 200,
            body: data.value
          }));
        })
      );
    }

    if (data) {
      if (!this.notified[key]) {
        this.toastService.show(`Datos actualizados por última vez ${new Date(data.metadata.timestamp).toLocaleString()}`, 'warning');
      }
      this.notified[key] = true;
      return of(new HttpResponse({
        status: 200,
        body: data.value
      }));
    }

    return next.handle(req.clone({ headers })).pipe(
      tap((response) => this.storage.setItem(key, response.body))
    );
  }
}
