import { TagAutomaticColorComponent } from './../tag-automatic-color/tag-automatic-color.component';
import { ContextualActionComponent } from './../contextual-action/contextual-action.component';
import { HumanizeStateComponent } from './../humanize-state/humanize-state.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import {  Component, EventEmitter, Input, Output } from '@angular/core';
import { FormatDatePipe } from '@ildes/pipes/format-date.pipe';
import { ListLuminaryItemComponent } from '../list-luminary-item/list-luminary-item.component';

@Component({
    selector: 'app-list-order-item',
    standalone: true,
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        HumanizeStateComponent,
        ContextualActionComponent,
        TagAutomaticColorComponent,
        FormatDatePipe,
        ListLuminaryItemComponent
    ],
    template: `
    <div class="flex-row padding-top-8 padding-bottom-8">
      <mat-checkbox
        *ngIf="selector"
        [disabled]="state!=='NEW'"
        class="margin-8"
        matRipple
        role="button"
        [checked]="selected"
        (change)="onChange($event)">
      </mat-checkbox>
      <div class="space-width actionable {{selector && state !=='NEW' ? 'disabled': ''}}" (click)="linkClicked()">
        <div class="header padding-bottom-8">
          <div class="column-full-width luminary-number" >
            <span class="name">Número de orden: {{orderno}}</span>
          </div>
          <div>
            <span class="project">{{project}}</span>
          </div>
        </div>
        <div class="container">
          <div class="data">
            <div class="margin-right-16 basic-data">
              <div class="padding-bottom-8">
                <humanize-state [state]="state"></humanize-state>
              </div>
              <div class="icon-text no-sync" *ngIf="pendingSync" >
                <mat-icon>cloud_off</mat-icon> <span> Pendiente de sincronizar </span>
              </div>
              <div class="icon-text" *ngIf="type">
                <mat-icon>build</mat-icon> <span> {{type}} </span>
              </div>
            </div>
          </div>
          <div class="container-right">
            <div class="icon-text" *ngIf="technician">
              <mat-icon>account_circle</mat-icon> <span class="capitalize"> {{technician.name}} {{technician.surname}} </span>
            </div>
            <div class="icon-text error" *ngIf="!technician">
              <i>Sin asignar</i>
            </div>
          </div>
        </div>
        <!-- <div class="container" *ngIf="luminary">
          <list-luminary-item
            [number]="luminaryNumber || oldLuminaryNumber || luminary.number"
            [newLuminaryNumber]="newLuminaryNumber"
            [project]="luminary.projectName"
            [address]="luminary?.address"
            [ubication]="luminary?.geolocation"
            [neighborhood]="luminary?.neighborhood"
            [technology]="oldTechnology || luminary.technology"
            [power]="oldPower || luminary.power"
            [newTechnology]="technology"
            [newPower]="power"
            ></list-luminary-item>
        </div> -->
        <div class="container">
          <div class="data">
            <div class="margin-right-16 basic-data">
              <div class="subtitle-description" *ngIf="orderDescription">
                {{orderDescription}}
              </div>
              <!-- <div class="icon-text" *ngIf="estimatedResolutionDate">
                <mat-icon>calendar_today</mat-icon> <span>Fecha estimada: {{estimatedResolutionDate | formatDate}} </span>
              </div> -->
              <div class="icon-text" *ngIf="resolutionDate">
                <mat-icon>calendar_today</mat-icon> <span>Fecha de resolución: {{resolutionDate | formatDate}} </span>
              </div>
              <div class="icon-text" *ngIf="luminaryNumber || oldLuminaryNumber || luminary?.number">
                <mat-icon>wb_incandescent</mat-icon>
                <span class="name" >{{luminaryNumber || oldLuminaryNumber || luminary.number}}</span>
                <span *ngIf="newLuminaryNumber"> ({{newLuminaryNumber}})</span>
              </div>
              <div>
                <div class="icon-text">
                  <mat-icon>room</mat-icon> <span> {{luminary?.address || address || 'Ubicación no informada'}} </span>
                </div>
              </div>
              <div class="subtitle icon-text" *ngIf="luminary?.neighborhood || neighborhood">
                <span>Barrio: {{luminary?.neighborhood || neighborhood}} </span>
              </div>
              <div class="subtitle icon-text" *ngIf="luminary?.referencePoint || referencePoint">
                <span>Punto de referencia: {{luminary?.referencePoint || referencePoint}}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
        <div class="tagged-data" *ngIf="oldTechnology || power || luminary">
          <tag-automatic-color icon="memory" [text]="oldTechnology || luminary?.technology" matRipple *ngIf="oldTechnology || luminary?.technology"></tag-automatic-color>
          <tag-automatic-color icon="offline_bolt" [text]="oldPower || luminary?.power" matRipple *ngIf="oldPower || luminary?.power"></tag-automatic-color>
          <mat-icon *ngIf="(oldTechnology || luminary) && technology">chevron_right</mat-icon>
          <tag-automatic-color icon="memory" [text]="technology" class="actionable" matRipple *ngIf="technology"></tag-automatic-color>
          <tag-automatic-color icon="offline_bolt" [text]="power" class="actionable" matRipple *ngIf="power"></tag-automatic-color>
        </div>
      </div>
      </div>
      <div class="actions {{selector ? 'invisible': ''}}" *ngIf="actions">
        <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="onClickActionEvent($event)"></contextual-action>
      </div>
    </div>

    <!-- <div>
      <div class="tagged-data">
        <tag-automatic-color icon="memory" [text]="technology" matRipple *ngIf="technology"></tag-automatic-color>
        <tag-automatic-color icon="offline_bolt" [text]="power" matRipple *ngIf="power"></tag-automatic-color>
      </div>
      <div class="tagged-data" *ngIf="materials">
        <tag-automatic-color [keyColor]="technology+'_'+power" *ngFor="let material of materials" [text]="material | humanizeDescriptionMaterial"></tag-automatic-color>
      </div>
    </div> -->
  `,
    styleUrls: ['./list-order-item.component.css'],
})
export class ListOrderItemComponent {
  @Output() onChangeSelected =  new EventEmitter();
  @Output() onClickAction =  new EventEmitter();
  @Output() onLinkClick =  new EventEmitter();
  @Input() state;
  @Input() luminaryNumber;
  @Input() orderno;
  @Input() project;
  @Input() address;
  @Input() neighborhood;
  @Input() referencePoint;
  @Input() estimatedResolutionDate;
  @Input() orderDescription;
  @Input() actions;
  @Input() selected;
  @Input() technician;
  @Input() resolutionDate;
  @Input() selector;
  @Input() technology;
  @Input() power;
  @Input() oldTechnology;
  @Input() oldPower;
  @Input() materials;
  @Input() luminary;
  @Input() newLuminaryNumber;
  @Input() oldLuminaryNumber;
  @Input() type;
  @Input() pendingSync;

  onChange(event) {
    this.onChangeSelected.emit(event)
  }

  linkClicked() {
    this.onLinkClick.emit();
  }

  onClickActionEvent(action) {
    this.onClickAction.emit(action);
  }
}
