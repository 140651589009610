<simple-header title="Selecciona los usuarios" (action)="close()" icon="close"></simple-header>
<list-users-composition
  [maxSelectedUsers]="maxSelectedUsers"
  [belongsToMyProjects]="belongsToMyProjects"
  [selectedUsers]="selectedUsers"
  [disabledUsers]="disabledUsers"
  [selector]="multipleSelection"
  [project]="project"
  [roleId]="roleId"
  scrollMode="infinite"
  (onClickItem)="onSelectUser($event)"
  (doAction)="onDoAction($event)"></list-users-composition>
<button (click)="accept()" color="primary" mat-raised-button *ngIf="multipleSelection">
  Aceptar
</button>
