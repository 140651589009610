import { ImagePanelComponent } from '@ildes/basic/image-panel/image-panel.component';
import { DropdownSelectorComponent } from '@ildes/basic/dropdown-selector/dropdown-selector.component';
import { DateSelectorComponent } from '@ildes/basic/date-selector/date-selector.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NeighborhoodSelectorComponent } from '../../basic/neighborhood-selector/neighborhood-selector.component';
import { ProjectSelectorComponent } from '../../basic/project-selector/project-selector.component';
import { MatMenuModule } from '@angular/material/menu';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { resumedColumns, parse } from './utils';
const SIZE = 200;

const state = [
  {
    id: 'COMPLETED',
    description: 'Cerrados'
  },
  {
    id: 'NEW',
    description: 'Abiertos'
  },
];

const REQUEST_MAINTENANCE_ID = 'MAINTENANCE';
const REQUEST_OTHER_ID = 'OTHERS';
const EXPANSION_ID = 'EXPANSION';
const REQUEST_TYPES = [
    {
        id: REQUEST_MAINTENANCE_ID,
        description: 'Mantenimiento',
    },
    {
        id: EXPANSION_ID,
        description: 'Expansión',
    },
    {
        id: 'TAX',
        description: 'Impuesto alumbrado público',
    }
    ,
    {
        id: REQUEST_OTHER_ID,
        description: 'Otros',
    }
];

const ACCOUNTABLE_SECTIONS_TYPES = [
  {
    id: 'ADMINISTRATIVE',
    description: 'Adminsitrativa'
  },
  {
    id: 'OPERATIVE',
    description: 'Operativa'
  },
];
@Component({
    selector: 'app-reporting-pqrs',
    standalone: true,
    imports: [
      ReactiveFormsModule,
      CommonModule,
      SimpleHeaderComponent,
      MatPaginatorModule,
      MatProgressBarModule,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      DateSelectorComponent,
      MatDatepickerModule,
      MatIconModule,
      NeighborhoodSelectorComponent,
      ProjectSelectorComponent,
      MatMenuModule,
      DropdownSelectorComponent,
      MatButtonModule,
      ImagePanelComponent
    ],
    template: `
    <div class="container">
        <simple-header [title]="title" [back]="true" icon="close"></simple-header>
        <section class="filter-container">
          <div class="field">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Número de pqr</mat-label>
                <input matInput [formControl]="pqrno" maxlength="90">
                <button mat-icon-button matSuffix (click)="this.pqrno.setValue('')" >
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Cédula del peticionario</mat-label>
                <input matInput [formControl]="identification" maxlength="90">
                <button mat-icon-button matSuffix (click)="this.identification.setValue('')" >
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
          </div>
          <div class="field">
            <dropdown-selector class="full-width margin-top-8" label="Estado" [types]="statees" [(selected)]="state"></dropdown-selector>
          </div>
          <div class="field">
            <dropdown-selector class="full-width margin-top-8" label="Área encargada" [types]="accountableSectionTypes" [(selected)]="accountableSectionType"></dropdown-selector>
          </div>
          <div class="field">
            <dropdown-selector class="full-width margin-top-8" label="Tipo de solicitud" [types]="requestTypes" [(selected)]="requestType"></dropdown-selector>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Cerrado después de</mat-label>
                <input matInput [formControl]="startDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Cerrado antes de</mat-label>
                <input matInput [formControl]="endDate" [matDatepicker]="picker2">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="field">
            <app-project-selector [formControl]="projectForm"></app-project-selector>
          </div>
          <button (click)="list()" mat-button>Aplicar filtros</button>
          <button (click)="exportData()" mat-button>Exportar</button>
        </section>
        <h3>Resultados de búsqueda</h3>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <app-image-panel class="margin-top-16" *ngIf="!parsedItems?.length && !loading"></app-image-panel>
        <div class="container-table" *ngIf="!loading && parsedItems?.length">
          <table [width]="size">
            <tr class="header">
              <th *ngFor="let columnName of header">{{columnName}}</th>
            </tr>
            <tr *ngFor="let row of parsedItems">
              <td *ngFor="let columnValue of row">
                <span *ngIf="!columnValue.config?.link" [innerHTML]="columnValue.value"></span>
                <button mat-button *ngIf="columnValue.config?.link && columnValue.value">{{columnValue.value}}</button>
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          *ngIf="!loading && parsedItems"
          [pageIndex]="currentPage"
          [length]="pagination?.totalDocs"
          pageSize="20"
          (page)="paginate($event)">
        </mat-paginator>

    </div>`,
    styleUrls: ['./reporting-pqrs.component.css']
})
export class ReportingPqrsComponent {
  pqrno = new FormControl('');
  identification = new FormControl('');
  statees = state;
  state;
  requestTypes = REQUEST_TYPES;
  requestType;
  accountableSectionTypes = ACCOUNTABLE_SECTIONS_TYPES;
  accountableSectionType;
  currentPage;
  loading;
  items;
  pagination;
  header;
  parsedItems;
  selectedTechnician;
  technician = new FormControl('Todos');
  projectForm = new FormControl({
    typed: '',
    selected: undefined
  });
  neighborhoodForm = new FormControl({
    typed: '',
    selected: undefined
  });
  project = null;
  startDate = new FormControl();
  endDate = new FormControl();
  size;
  columnsConfig
  public title = 'PQRS';
  constructor(
    public apiManager: ApiManagerService,
    public dialog: MatDialog
  ) {

    this.columnsConfig = resumedColumns;
    this.header = Object.values(this.columnsConfig).map(({name}) => name);
    this.size = `${SIZE * this.header.length}px`;
  }

  ngOnInit(): void {
    this.list();
  }

  getFilter() {
    const pqrno = this.pqrno.value;
    const identification = this.identification.value;
    const start = this.startDate.value;
    const end = this.endDate.value;
    const state = this.state?.id;
    const accountableSection = this.accountableSectionType?.id;
    const requestType = this.requestType?.id;
    const project = this.projectForm.value?.selected?.id;

    return {
      ...identification && { 'petitioner.identification': identification },
      ...pqrno && { pqrno },
      ...start && { startResponseDate: start.toISOString() },
      ...end && { endResponseDate: end.toISOString() },
      ...state && { state },
      ...accountableSection && { accountableSection: accountableSection },
      ...requestType && { requestType },
      ...project && { projectId: project },
    }
  }

  list(pageIndex = 0) {
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    let filter: any = this.getFilter();
    filter = {...filter}
    this.loading = true;
    this.fetch(page, filter).subscribe((data: any) => {
      this.loading = false;
      this.items = data?.data;
      if (!this.items) {
        this.pagination = null;
        this.parsedItems = null;
        return;
      }
      this.size = `${SIZE * this.header.length}px`;
      this.parsedItems = parse(this.items, this.columnsConfig);
      this.pagination = data.pagination;
    }, () => {
      this.loading = false;
    });
  }

  fetch(page, filter) {
    return this.apiManager.listPQRS({...page, ...filter});
  }

  paginate(event) {
    this.list(event.pageIndex);
  }

  removeTechnician() {
    this.selectedTechnician = null;
    this.technician = new FormControl('Todos');
  }

  fetchCsv(filter) {
    return this.apiManager.downloadReportPQRs(filter);
  }
  /* TODO: esto en móvil seguro q no abre nada */
  exportData() {
    let filter: any = this.getFilter();
    filter = {...filter};
    this.loading = true;
    this.fetchCsv(filter).subscribe((data: any) => {
      const url= window.URL.createObjectURL(data);
      window.open(url);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
