import { humanizeDate } from "../../utils/formatters";

function getProp( object, keys, defaultVal ){
  keys = Array.isArray( keys )? keys : keys.split('.');
  object = object[keys[0]];
  if( object && keys.length>1 ){
    return getProp( object, keys.slice(1), defaultVal );
  }
  return object === undefined? defaultVal : object;
}

export const columns = {
  'dpto': {
    name: 'Departamento',
    path: 'project.address.department',
  },
  'mpio': {
    name: 'Municipio',
    path: 'project.address.city',
  },
  'technician': {
    name: 'Técnico',
    parse: (item) => {
      return item.technician ? `${item.technician.name} ${item.technician.surname}`: '-'
    }
  },
  'material': {
    name: 'Material'
  },
  'amount': {
    name: 'Cantidad'
  },
  'totalCost': {
    name: 'Coste total ($CO)'
  }
};


