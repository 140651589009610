import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { SessionStore } from '../stores/session.store';

@Directive({
  selector: '[allowAction]',
  standalone: true,
})
export class AllowActionDirective implements OnInit {

  @Input('allowAction') action;
  @Input('config') config = 'hideIfNotCompliance';
  constructor(
    public el: ElementRef
  ) {

  }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance();

    SessionStore.getInstance().get$().subscribe((data) => {
      if (!data?.user) {

        return;
      }
      if (this.config !== 'showWhenNotCompliance') {
        this.el.nativeElement.style.display = this.hasPermission(data.user, this.action) ? 'block' : 'none';
      } else {
        this.el.nativeElement.style.display = this.hasPermission(data.user, this.action) ? 'none' : 'block';
      }
      
    });
  }

  // private hasPermission(user, action){
  //   const [family, actionName] = action;
  //
  //   return user.permissions[family]&&user.permissions[family][actionName];
  // }

  private hasPermission(user, action){
    return action.reduce((result, currentAction) => {
      const [family, actionName] = currentAction.split('.');

      return result && (user.permissions[family] && user.permissions[family][actionName]);
    }, true);
  }
}
