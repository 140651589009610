import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../services/api-manager.service';

const ROLES = [
  {
    id: 'ADMINISTRATIVE_ASSISTANT',
    description: 'Asistente administrativo'
  },
  {
    id: 'LEADER',
    description: 'Líder'
  },
  {
    id: 'TECHNICIAN',
    description: 'Técnico electricista'
  },
  {
    id: 'ADMIN',
    description: 'Administrador'
  },
  {
    id: 'BUYANDLOGISTIC',
    description: 'Compras y logística'
  },
  {
    id: 'DRIVER',
    description: 'Conductor'
  }
];

const CONTRACT_TYPES = [
  {
    id: 'OBRA',
    description: 'Obra y servicio'
  }, {
    id: 'INDEFINIDO',
    description: 'Indefinido'
  }
];

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  user;
  role;
  contractType;
  roles;
  contractTypes;
  loading;
  constructor(
    private apiManager: ApiManagerService
  ) { }

  ngOnInit(): void {
    this.roles = ROLES;
    this.contractTypes = CONTRACT_TYPES;
    this._resetForm();
  }

  create() {
    const { email, name, surname } = this.user.controls;

    this.loading = true;
    this.apiManager.createUser({
      email: email.value, name: name.value, surname: surname.value, role: this.role, contractType: this.contractType
    }).subscribe(() => {
      this.loading = false;
      this._resetForm();
    }, (data) => {
      this.loading = false;
    })
  }

  _resetForm() {
    this.user = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });

    this.role = ROLES[0];
    this.contractType = CONTRACT_TYPES[0];
  }

}
