import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionStore } from '../../stores/session.store';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

const MAX_ACTIONS = 2;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  selector: 'contextual-action',
  templateUrl: './contextual-action.component.html',
  styleUrls: ['./contextual-action.component.css']
})
export class ContextualActionComponent implements OnInit {

  @Input() actions;
  @Input() collapseAll;
  @Output() actionEvent =  new EventEmitter();
  sessionStore;

  constructor() { }

  ngOnInit(): void {
    this.sessionStore = SessionStore.getInstance().get();
  }

  get allowedActions() {
    return this.actions.filter( (action) => {
      if (!action.permission) {
        return true;
      }

      return this.sessionStore.hasPermission(action.permission);
    });
  }

  get firstLevelActions() {
    if (this.collapseAll) {
      return [];
    }
    return this.allowedActions.slice(0, MAX_ACTIONS);
  }

  get secondLevelActions() {
    return this.allowedActions.slice(this.collapseAll ? 0: MAX_ACTIONS, this.allowedActions.length);
  }

  onClickActionEvent(action) {
    this.actionEvent.emit(action);
  }

}
