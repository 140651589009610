<div class="container">
  <div class="scrollable-content">
    <simple-header title="Editar datos de luminaria" [back]="true" icon="close"></simple-header>
    <form [formGroup]="luminaryFormGroup" autocomplete="off">
      <div class="inline-flex padding-right-48">
        <predictive-searcher
          formControlName="selectedProjectForm"
          label="Proyecto"
          [service]="searchProjectAdapter"
          class="column margin-right-8"></predictive-searcher>
        <mat-form-field appearance="outline" class="column">
          <mat-label>Número</mat-label>
          <input matInput maxlength="20" formControlName="number">
          <mat-error>
              El número es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="inline-flex">
        <div class="inline-flex column">
          <predictive-searcher
            [required]="true"
            label="Tecnología"
            formControlName="technologyForm"
            [service]="searchLuminaryTypesAdapter"
            class="column margin-right-8"></predictive-searcher>
          <mat-form-field appearance="outline" class="column margin-right-8">
            <mat-label>Potencia</mat-label>
            <input formControlName="power" matInput maxlength="20" (focusout)="fetchAsocMaterials()">
            <mat-error>
                La potencia es obligatoria
            </mat-error>
          </mat-form-field>
        </div>
        <div class="padding-top-16">
          <mat-icon *ngIf="!luminaryMaterials?.length">warning</mat-icon>
          <mat-icon *ngIf="luminaryMaterials?.length">fact_check</mat-icon>
        </div>
      </div>
      <div class="inline-flex padding-right-48">
        <predictive-searcher
          label="Fabricante"
          formControlName="makerForm"
          [service]="searchMakerTypesAdapter"
          class="column margin-right-8"></predictive-searcher>
        <predictive-searcher
          label="Modelo"
          formControlName="modelForm"
          [service]="searchModelAdapter"
          class="column"></predictive-searcher>
      </div>
      <div class="padding-right-48">
        <input-place
            label="Ubicación"
            placeholder="CLL 33 45"
            dark
            [requiredGeoposition]="true"
            formControlName="location"
            appearance="fill"></input-place>
      </div>
      <div>
      </div>
      <div class="inline-flex padding-right-48">
        <predictive-searcher
          label="Barrio"
          formControlName="neighborhoodForm"
          [service]="searchNeighborhoodAdapter"
          class="column margin-right-8"></predictive-searcher>
        <dropdown-selector class="column"  label="Tipo de vía Retilap" [types]="retilap" [(selected)]="selectedRetilap"></dropdown-selector>
      </div>
      <div class="inline-flex">
        <mat-form-field appearance="outline" class="column">
          <mat-label>Observaciones de ruta o punto de referencia</mat-label>
          <input matInput formControlName="pathObservations"/>
        </mat-form-field>
      </div>
      <div class="align-center">
        <div class="padding-bottom-8">
          <button (click)="save()" [disabled]="luminaryFormGroup.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
        </div>
        <div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </form>
  </div>
</div>
