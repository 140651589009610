import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchLuminaryDialogComponent } from '../../views/advanced-search-luminary-dialog/advanced-search-luminary-dialog.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FileHelperService } from '@ildes/services/file-helper.service';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import { ListMaintenancesPendingSyncDialogComponent } from '../list-maintenances-pending-sync-dialog/list-maintenances-pending-sync-dialog.component';

const MAP = {
  id: 'select',
  icon: 'map',
  description: 'Ver mapa'
};

const CLOSE = {
  icon: 'close',
  id: 'cancel',
  description: 'Volver',
  disabled: false,
};

const DOWNLOAD = {
  icon: 'download_2',
  id: 'save',
  description: 'Descargar CSV',
  disabled: false,
};

@Component({
  selector: 'app-list-luminaries',
  templateUrl: './list-luminaries.component.html',
  styleUrls: ['./list-luminaries.component.css']
})
export class ListLuminariesComponent implements OnInit {
  advancedFilter: any;
  groupActions = [DOWNLOAD, MAP, CLOSE]
  labelByFilter = {
    project: 'Proyecto:',
    number: 'Número:',
    withoutNumber: 'Sin número',
    location: 'Ubicación:',
    neighborhood: 'Barrio:',
  };
  luminaries;
  pagination;
  loading;
  currentPage = 0;
  searchProjectAdapter;
  downloading;
  subscribeLuminariesState;
  pendingSyncLuminaries;
  searchFormGroup = new UntypedFormGroup({
    selectedProjectForm: new UntypedFormControl(),
    number: new UntypedFormControl(),
  });
  constructor(
    private store: Store<AppState>,
    private apiManager: ApiManagerService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private fileHelper: FileHelperService
  ) { }

  ngOnInit(): void {
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };

    this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      //this.filter = params;
      if (params.projectId) {
        this.searchFormGroup.get('selectedProjectForm').setValue({selected: {id: params.projectId, name: params.projectName}, typed: params.projectName})
      }
      this.searchFormGroup.get('number').setValue(params.number)

      if (params.lat) {
        this.advancedFilter = this.advancedFilter || {};
        this.advancedFilter.location = {};
        this.advancedFilter.location.lat = params.lat;
        this.advancedFilter.location.lng = params.lng;
        this.advancedFilter.location.typed = params.typed;
      }

      if (Boolean(params.withoutNumber)) {
        this.advancedFilter = this.advancedFilter || {};
        this.advancedFilter.withoutNumber = true;
      }

      if (params.neighborhood) {
        this.advancedFilter = this.advancedFilter || {};
        this.advancedFilter.neighborhood = params.neighborhood;
      }
      this.list();
      this.subscribeLuminariesState = this.store.select('luminaries').subscribe((luminaries) => {
        this.pendingSyncLuminaries = luminaries;
      });
    });
  }

  paginate(event) {
    this.list(event.pageIndex);
  }

  list(pageIndex = 0) {
    this.luminaries = undefined;
    this.loading = true;
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const filter: any = this.getFilter();
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.queryParams,
        replaceUrl: true
      }
    );

    this.apiManager.listLuminaries({...page, ...filter}).subscribe((luminaries: any) => {
      this.loading = false;
      this.luminaries = luminaries.data;
      this.pagination = luminaries.pagination;
    }, (data) => {
      this.loading = false;
    });
  }

  onSelectedValue({ option }) {
    this.currentPage = 0;
    //this.filter = { project: Object.assign({}, { selected: option.value }, { typed: option.value.name }) };

    this.list();
  }

  findByNumber() {
    this.list();
  }

  advancedSearch() {
    const dialog = this.dialog.open(AdvancedSearchLuminaryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { filter: this.advancedFilter }
    });

    dialog.afterClosed().subscribe((data = { closed: false }) => {

      if (data.closed) {
        return;
      }
      this.advancedFilter = data;
      if (this.advancedFilter.project?.selected?.id) {
        const selectedProject = this.advancedFilter.project?.selected;
        this.searchFormGroup.get('selectedProjectForm').setValue({selected: {id: selectedProject.id, name: selectedProject.name}, typed: selectedProject.name});
      }
      if (this.advancedFilter.number) {
        this.searchFormGroup.get('number').setValue(this.advancedFilter.number)
      }

      this.list();

    });
  }

  get humanizeFilter(): Array<any> {
    if (!this.advancedFilter) {
      return [];
    }

    const keys = Object.keys(this.advancedFilter || {});
    return keys.filter( (criteria) => this.advancedFilter[criteria] || this.advancedFilter[criteria] === false).map((key) => ({
      key: this.labelByFilter[key],
      // value: this.filter[key]
      value: this.humanizeValue(key, this.advancedFilter[key])
    }));
  }

  humanizeValue(key, value) {
    switch(key) {
      case 'number':
      case 'neighborhood':
        return value;
      break;
      case 'withoutNumber':
        return '';
      break;
      case 'location':
        return `(${value.lat}, ${value.lng})`;
      break;
      default:
        return value.typed;
    }
  }

  removeFilters() {
    this.advancedFilter = null;

    this.pagination = null;
    this.list();
  }

  get filter2() {
    const selectedProjectId = this.searchFormGroup.get('selectedProjectForm').value?.selected?.id;
    const number = this.searchFormGroup.get('number').value;

    // if (typeof this.filter?.withoutNumber === 'boolean' && this.filter?.withoutNumber) {
    //   filter.number = null;
    // } else if (this.filter?.number) {
    //  filter.number = this.filter.number;
    //}

    return {
      ...selectedProjectId && {projectId: this.searchFormGroup.get('selectedProjectForm').value?.selected?.id},
      ...number && { number }
    }
  }

  get queryParams() {
    const selectedProject = this.searchFormGroup.get('selectedProjectForm').value?.selected;
    const number = this.searchFormGroup.get('number').value;
    const location = this.advancedFilter?.location;
    const queryParams: Params = {};

    if (selectedProject) {
      queryParams.projectId = selectedProject.id;
      queryParams.projectName = selectedProject.name;
    }

    if (number) {
      queryParams.number = number;
    }
    if (location) {
      queryParams.lat = location.lat;
      queryParams.lng = location.lng;
      queryParams.typed = location.typed;
    }

    if (this.advancedFilter?.withoutNumber) {
      queryParams.withoutNumber = true;
    }

    if (this.advancedFilter?.neighborhood) {

      queryParams.neighborhood = this.advancedFilter?.neighborhood;
    }

    return queryParams;
  }

  private getFilter() {
    const filter: any = {};

    if (this.filter2.projectId) {
      filter.projectId = this.filter2.projectId;
    }

    if (this.advancedFilter?.neighborhood) {
      filter.neighborhood = this.advancedFilter.neighborhood;
    }

    if (typeof this.advancedFilter?.withoutNumber === 'boolean' && this.advancedFilter?.withoutNumber) {
      filter.number = null;
    } else if (this.filter2?.number) {
      filter.number = this.filter2.number;
    }

    if (this.advancedFilter?.location) {
      filter.latitude = this.advancedFilter.location.lat;
      filter.longitude = this.advancedFilter.location.lng;
      filter.maxDistance = 50;
    }

    if (this.advancedFilter?.number) {
      filter.number = this.advancedFilter.number;
    }

    return filter;
  }

  gotoLuminaryDetail(id) {
    this.router.navigate(['/app/luminary'], { queryParams: { id: id } });
  }

  download() {
    this.downloading = true;
    const filter: any = this.getFilter();

    this.apiManager.downloadLuminaries({...filter}).subscribe((data: any) => {
      this.fileHelper.open('luminaries', data);
      this.downloading = false;
    }, () => {
      this.downloading = false;
    });
  }

  onClickHeaderAction(action) {
    switch(action.id) {
      case MAP.id:
        this.router.navigate(['/app/list-luminaries/map']);
        break;
      case DOWNLOAD.id:
        this.download();
        break;
      default:
        this.location.back();
    }
  }
  get numElementsPendingSync() {
    return this.pendingSyncLuminaries?.length;
  }

  public openPendingSyncLuminariesDialog() {
    const dialog = this.dialog.open(ListMaintenancesPendingSyncDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { luminaries: this.pendingSyncLuminaries }
    });

    dialog.afterClosed().subscribe((data) => {
      if (data.close) {
        return;
      }

      if (data.selectedLuminary) {
        if (data.selectedLuminary.id) {
          this.router.navigate(['/app/luminary'], { queryParams: { id: data.selectedLuminary.id} });
        } else {
          this.router.navigate(['app/list-luminaries/create'], { state: data.selectedLuminary });
        }
      }
    })
  }
}
