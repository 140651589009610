import { humanizeDate } from './../utils/formatters';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return humanizeDate(value);
  }

}
