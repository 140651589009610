import { ListPqrItemComponent } from './../../basic/list-pqr-item/list-pqr-item.component';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { AllowActionDirective } from '@ildes/directives/allow-action.directive';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { ImagePanelComponent } from '@ildes/basic/image-panel/image-panel.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SessionStore } from '@ildes/stores/session.store';
import { FileHelperService } from '@ildes/services/file-helper.service';

const DOWNLOAD_ATTENTION_FILE = {
  id: 'download_attention',
  description: 'Descargar Atención PQR',
  icon: 'assignment_ind',
  // permission: {
  //   family: 'maintenances',
  //   actionName: 'update'
  // },
  // conditions: [
  //   {
  //     key: 'state',
  //     value: 'NEW',
  //     equal: true
  //   }
  // ]
};

const SECTIONS_BY_ROLES = {
  LEADER: ['OPERATIVE']
};

@Component({
    selector: 'app-list-pqrs',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SimpleHeaderComponent,
        MatTabsModule,
        MatButtonModule,
        AllowActionDirective,
        MatIconModule,
        RouterLink,
        ImagePanelComponent,
        ListPqrItemComponent,
        MatDividerModule,
        MatPaginatorModule,
        MatProgressBarModule
    ],
    template: `
      <div class="container">
        <simple-header title="PQRs" [back]="true" icon="close"></simple-header>
        <nav mat-tab-nav-bar [tabPanel]="tabPanel">
          <a mat-tab-link [active]="tabActive==='ALL'" (click)="changeTab('ALL')"> Todos </a>
          <a mat-tab-link [active]="tabActive==='NEW'" (click)="changeTab('NEW')"> Pendientes </a>
          <a mat-tab-link [active]="tabActive==='COMPLETED'" (click)="changeTab('COMPLETED')"> Finalizados </a>
        </nav>
        <mat-tab-nav-panel #tabPanel class="content-tab-panel">
          <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
          <app-image-panel class="margin-top-16" *ngIf="!filteredPqrs?.length && !loading"></app-image-panel>
          <div *ngFor="let pqr of filteredPqrs" class="margin-top-8">
            <list-pqr-item
              [state]="pqr.state"
              [actions]="actions"
              [orderno]="pqr.orderno"
              (onActionClick)="onActionClick($event, pqr)"
              (onClickItem)="gotoPqrDetail(pqr)"
              [type]="pqr.requestType"
              [responseDays]="pqr.responseDays"
              [project]="pqr.project?.name"
              [address]="pqr.project?.address"
              [neighborhood]="pqr.neighborhood"
              [referencePoint]="pqr.referencePointDescription"
              [maintenance]="pqr.maintenance"
              [description]="pqr.description"
              [registrationDate]="pqr.registrationDate"
              [responseDate]="pqr.responseDate"
              [accountableSection]="pqr.accountableSection"
              [receptor]="pqr.receptor?.name+' '+pqr.receptor?.surname"
              [petitioner]="pqr.petitioner"
            ></list-pqr-item>
            <mat-divider></mat-divider>
          </div>
        </mat-tab-nav-panel>
        <mat-paginator
            [pageIndex]="currentPage"
            [length]="pagination?.totalDocs"
            pageSize="20"
            (page)="paginate($event)">
        </mat-paginator>
        <button mat-fab color="primary" class="fab-button" [allowAction]="['pqrs.create']" [routerLink]="['/app/list-pqrs/create']"><mat-icon>add</mat-icon></button>
      </div>
    `,
    styleUrls: ['./list-pqrs.component.css'],
})
export class ListPqrsComponent implements OnInit {
    constructor(
      public apiManager: ApiManagerService,
      private router: Router,
      private fileHelper: FileHelperService,
    ) {

    }
    ngOnInit(): void {
      const sessionStore = SessionStore.getInstance();
      this.user = sessionStore.get().user;
      this.filteredSections = SECTIONS_BY_ROLES[this.user.role.id];
      this.fetchPQRS();
    }
    filteredSections;
    user;
    actions = [DOWNLOAD_ATTENTION_FILE];
    pagination;
    tabActive = 'ALL';
    loading;
    filteredPqrs;
    currentPage = 0;
    downloading;

    changeTab(tab) {
      this.tabActive=tab;
      this.fetchPQRS();
    }

    fetchPQRS(pageIndex = 0) {
      this.currentPage = pageIndex;
      const page = pageIndex ? { page: pageIndex + 1 } : {};
      const filter = {
        ...this.tabActive !== 'ALL' && { state: this.tabActive},
        ...this.filteredSections && { accountableSection: this.filteredSections}
      };

      this.loading = true;
      this.apiManager.listPQRS({...page, ...filter}).subscribe((data: any) => {
        this.filteredPqrs = data.data;
        this.loading = false;
        this.pagination = data.pagination;
      }, () => {
        this.loading = false;
      });
    }

    paginate(event) {
      this.fetchPQRS(event.pageIndex);
    }

    gotoMaintenanceDetail(maintenance) {
      this.router.navigate(['/app/detail-maintenance'], { queryParams: { id: maintenance.id } });
    }

    gotoPqrDetail(pqr) {
      if (pqr.accountableSection === 'OPERATION') {
        this.gotoMaintenanceDetail(pqr.maintenance);

        return;
      }
      this.router.navigate(['/app/detail-pqr'], { state: { pqr } });
    }

    onActionClick($event, pqr) {
      switch ($event.id) {
        case DOWNLOAD_ATTENTION_FILE.id:
          this.downloadPQR(pqr.id)
          break;

        default:
          break;
      }

    }

    downloadPQR(pqrId) {
      this.downloading = true;
      this.apiManager.downloadPQR(pqrId, {}, { contentType: 'application/pdf', error: true }).subscribe(async (data: any) => {
        this.downloading = false;
        this.fileHelper.open(pqrId, data);
      }, () => {
        this.downloading = false;
      })
    }
}
