import { DropdownSelectorComponent } from '@ildes/basic/dropdown-selector/dropdown-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ACTIVITY_TYPES } from '@ildes/config/constants';
@Component({
    selector: 'app-list-dynamic-activities',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        DropdownSelectorComponent,
        MatDividerModule
    ],
    template: `
    <div>
      <dropdown-selector class="column" [(selected)]="selected" label="Seleccione para agregar una actividad." [types]="typeList" (selectedChange)="addElement($event)" ></dropdown-selector>
      <div *ngFor="let activity of readOnlyList">
        <div class="list">
          <div class="flex-1">
            {{activity.description}}<span *ngIf="activity.extraData"> ({{activity.extraData}}) </span>
          </div>
          <button disabled  mat-icon-button aria-label="eliminar">
            <mat-icon>highlight_off</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngFor="let activity of selectedActivities">
        <div class="list">
          <div class="flex-1">
            {{activity.description}}
          </div>
          <button (click)="remove(activity)" mat-icon-button aria-label="eliminar">
            <mat-icon>highlight_off</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    `,
    styleUrls: ['./list-dynamic-activities.component.css']
})
export class ListDynamicActivitiesComponent implements OnInit{

  ngOnInit(): void {
    this.onChangeActivities.emit([...this.readOnlyList || [], ...this.selectedActivities || []]);
  }

  selected;
  private _readOnlyList = [{
    id: 'CONNECTIONS_ADJUST',
    description: 'Ajuste de conexiones',
  }, {
    id: 'CLEAN_ELECTRONIC',
    description: 'Limpieza de óptica',
  },{
    id: 'CLEAN_OPTIC',
    description: 'Limpieza de elementos electrónicos',
  }];

  @Input() set readOnlyList(value) {
    this._readOnlyList = value;

    this.onChangeActivities.emit([...this.readOnlyList, ...this.selectedActivities || []]);
  }

  get typeList() {
    return this.types.filter(( {id} ) => !(this.selectedActivities || []).find((activity) => activity.id === id ));
  }

  get readOnlyList() {
    return this._readOnlyList;
  }

  @Output() onChangeActivities = new EventEmitter();
  @Input() selectedActivities;

  @Input() types = ACTIVITY_TYPES;

  addElement(e) {
    if (!e) {
      return;
    }
    this.selectedActivities = [...this.selectedActivities || [], {...e}];

    this.selected = {};
    this.onChangeActivities.emit([...this.readOnlyList, ...this.selectedActivities]);
  }
  remove(actToRemove) {
    this.selectedActivities = this.selectedActivities.filter(( activity ) => activity !== actToRemove);
    this.onChangeActivities.emit([...this.readOnlyList, ...this.selectedActivities]);
  }
}
