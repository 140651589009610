import { Action, createReducer, State, on  } from '@ngrx/store';
import * as actions from './daily-questionaries.actions';
import { ActionReducer } from '@ngrx/store';

const initState = (state, action) => {
  return action.questionaries;
}

const newQuestionary = (state, action) => {
 return [...state, {
    ...{ date: action.date, orderId: action.orderId, projectId: action.projectId,  answers: action.answers, pendingSync: action.pendingSync }
  }];
}

const sameDay = (day1,day2) => {
  return (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  );
}
const update = (state, action) => {
  const { changes } = action;

  return state.map((questionary) => {
    if ((questionary.orderId === action.orderId || questionary.projectId === action.projectId) && sameDay(questionary.date, action.date)) {
      return {
        ...questionary,
        ...action.projectId && {projectId: action.projectId},
        ...changes.answers !== undefined && { answers: changes.answers },
        ...changes.pendingSync !== undefined && { pendingSync: changes.pendingSync }
      };
    }
    return questionary;
  });
}


const initialState = [];
const questionaries = createReducer(
  initialState,
  on(actions.initState, initState),
  on(actions.newQuestionary, newQuestionary),
  on(actions.update, update)
);

export function reducer(state: any[] | undefined, action: Action) {
  return questionaries(state, action);
}
