import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import constants from '@ildes/config/constants';
import { Location } from '@angular/common';
import { ToastService } from '@ildes/services/toast.service';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/list-luminaries/list-luminaries.actions';

@Component({
  selector: 'app-edit-post-and-luminary-data',
  templateUrl: './edit-post-and-luminary-data.component.html',
  styleUrls: ['./edit-post-and-luminary-data.component.css']
})
export class EditPostAndLuminaryDataComponent implements OnInit {

  luminary;
  photos = [];
  types = constants.TYPE;
  special = constants.SPECIAL;
  anomaly = constants.ANOMALY;
  switchedon = constants.SWITCHEDON;
  electricNetwork = constants.ELECTRIC_NETWORK;
  post = constants.POST;
  postClass = constants.POST_CLASS;
  installationZones = constants.INSTALLATION_ZONES;
  selectedType = null;
  selectedSpecial = null;
  selectedAnomaly = null;
  selectedSwitchedon = null;
  selectedElectricNetwork = null;
  selectedPostType = null;
  selectedPostClass = null;
  selectedInstallationZone = null;
  particular = false;
  loading;
  selectedSpaceType;
  spaceTypes = constants.SPACE_TYPES;
  private dirtyPostType = false;

  formGroup = new UntypedFormGroup({
    observations: new UntypedFormControl(''),
    postNumber: new UntypedFormControl(),
    postHeight: new UntypedFormControl(),
  });

  constructor(
    private store: Store<AppState>,
    private apiManager: ApiManagerService,
    private location: Location,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    const state:any = this.location.getState();

    this.luminary = state.luminary;
    if (!this.luminary) {
      this.location.back();

      return;
    }

    this.photos = this.luminary.photos;
    this.formGroup.controls.observations.setValue(this.luminary.observations || '');
    this.formGroup.controls.postNumber.setValue(this.luminary.postNumber || '');
    this.formGroup.controls.postHeight.setValue(this.luminary.postHeight || '');

    this.selectedSpaceType = this.spaceTypes.find(({ id }) => this.luminary.spaceType?.id === id);
    this.selectedType = this.types.find(({ id }) => this.luminary.luminaryType?.id === id);
    this.selectedSpecial = this.special.find(({ id }) => this.luminary.special?.id === id);
    this.selectedAnomaly = this.anomaly.find(({ id }) => this.luminary.anomaly?.id === id);
    this.selectedSwitchedon = this.switchedon.find(({ id }) => this.luminary.switchedon?.id === id);
    this.selectedElectricNetwork = this.electricNetwork.find(({ id }) => this.luminary.electricNetwork?.id === id);
    this.selectedPostType = this.post.find(({ id }) => this.luminary.postType?.id === id);
    this.selectedPostClass = this.postClass.find(({ id }) => this.luminary.postClass?.id === id);
    this.particular = this.luminary.particularUse;
    this.selectedInstallationZone = this.installationZones.find(({ id }) => this.luminary.installationZone?.id === id);
  }

  changeElectricNetwork(changeRecord) {
    if (this.dirtyPostType) {
      return;
    }

    this.selectedPostType = changeRecord;
  }

  changePostType(changeRecord) {
    if (!this.dirtyPostType && changeRecord === this.selectedElectricNetwork) {
      return;
    }

    this.dirtyPostType = true;
  }

  get observations() {
    return this.formGroup.get('observations')?.value
  }

  get postNumber() {
    return this.formGroup.get('postNumber')?.value
  }

  get postHeight() {
    return this.formGroup.get('postHeight')?.value
  }

  save() {
    this.loading = true;

    const luminary = {
      ...this.luminary.project?.id ? {
        project: this.luminary.project?.id,
        projectName: this.luminary.project?.name
      }: {
        project: this.luminary.project,
        projectName: this.luminary.projectName,
      },
      photos: this.photos,
      special: this.selectedSpecial,
      anomaly: this.selectedAnomaly,
      switchedon: this.selectedSwitchedon,
      electricNetwork: this.selectedElectricNetwork,
      postType: this.selectedPostType,
      postClass: this.selectedPostClass,
      particularUse: this.particular,
      luminaryType: this.selectedType,
      observations: this.observations,
      installationZone: this.selectedInstallationZone,
      postNumber: this.postNumber,
      spaceType: this.selectedSpaceType,
      postHeight: this.postHeight
    };

    this.apiManager.updateLuminary(this.luminary.id, {...this.luminary, ...luminary}).subscribe(() => {
      this.loading = false;

      this.store.dispatch(
        actions.remove({ _id: this.luminary._id})
      )
      this.location.back();
    }, (error) => {
      this.loading = false;
      if (!error.code || error.code > 0) {
        return;
      }

      const luminaryMerged = {
        ...this.luminary,
       ...luminary
      };

      if (this.luminary._id) {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.update({ id: this.luminary._id, changes: luminaryMerged })
        )
      } else {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.create({ luminary: luminaryMerged })
        )
      }
      this.location.back();
    });
  }

}
