<div class="data">
  <div class="basic-data">
    <div class="actionable" matRipple role="button" (click)="linkClicked()">
      <div>
        <span class="name">{{name}}</span> ({{prefix || '-'}})
      </div>
      <div class="subtitle">
        <span>{{duration}} ({{startDate | formatDate}} - {{endDate | formatDate}})</span>
      </div>
      <div class="subtitle">
        <span> {{contractType?.description || contractType}}</span>
        <span> | {{contractNumber}}</span>
      </div>
    </div>
    <div class="participants margin-bottom-8">
      <profile-avatar
        [bordered]="true"
        class="participant"
        size="small"
        [name]="participant.name"
        [surname]="participant.surname"
        [image]="participant.photo | imagePath"
        *ngFor="let participant of participants | slice:0:5;"></profile-avatar>
      <div class="more actionable" matRipple (click)="onAddParticipants()" *ngIf="participants?.length>5">... +{{participants.length - 5}}</div>
      <div class="more actionable" matRipple (click)="onAddParticipants()" *ngIf="!participants||participants?.length<=5">...añadir</div>
    </div>
  </div>
  <!-- TODO: Controlar número de mantenimientos-->
  <div class="container-stats" *ngIf="false">
    <div class="stat">
      <div class="label">Mant. activos</div>
      <div class="value">100</div>
    </div>
    <div class="stat">
      <div class="label">Nuevas inst.</div>
      <div class="value">100/1223</div>
    </div>
    <div class="stat">
      <div class="label">Modernizaciones</div>
      <div class="value">400/1223</div>
    </div>
  </div>
</div>
<div class="actions" *ngIf="actions">
  <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="actionEvent($event)"></contextual-action>
</div>
