<mat-toolbar>
  <button (click)="snav.toggle()" mat-icon-button aria-label="abrir menú">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="column-full-space">
    <img src="./assets/images/logo.png" class="logo">
  </div>
  <!-- <button (click)="openSettings()" mat-icon-button aria-label="ajustes de sesión">
    <mat-icon>settings</mat-icon>
  </button> -->
</mat-toolbar>
<mat-drawer-container class="container" >
  <mat-drawer #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!this.mobileQuery.matches" class="drawer-menu" color="primary">
    <composition-basic-profile (onLinkClick)="gotoProfile()" [name]="user?.name" [surname]="user?.surname" [description]="user?.role?.description" [image]="user?.photo | imagePath"></composition-basic-profile>
    <mat-divider></mat-divider>
    <div class="padding-top-8 paddin-bottom-8" *ngIf="user?.role?.id === 'TECHNICIAN' || user?.role?.id === 'DRIVER'">
      <button (click)="configureGroup()" mat-button class="btn-menu" *ngIf="!user?.coworkers?.length">
        <mat-icon>group_add</mat-icon>Configurar grupo de trabajo
      </button>
      <list-user-item
        (click)="configureGroup()"
        *ngIf="user.coworkers?.length"
        [name]="user?.coworkers[0].name"
        [surname]="user?.coworkers[0].surname"
        class="padding-left-8 center-vertically"
        >
      </list-user-item>
    </div>
    <mat-divider *ngIf="user?.role?.id === 'TECHNICIAN' || user?.role?.id === 'DRIVER'"></mat-divider>
    <mat-action-list>
      <button [routerLink]="['/app/dashboard/maintenances']" mat-list-item class="btn-menu" *ngIf="user?.role?.id === 'TECHNICIAN' || user?.role?.id === 'DRIVER'">
        <mat-icon matListItemIcon>dashboard</mat-icon><span>Dashboard</span>
      </button>
      <button mat-list-item class="btn-menu" [routerLink]="['/app/list-luminaries']">
        <mat-icon matListItemIcon>batch_prediction</mat-icon><span>Luminarias</span>
      </button>
      <button mat-list-item class="btn-menu" [allowAction]="['pqrs.list']" [routerLink]="['/app/list-pqrs']">
        <mat-icon matListItemIcon>receipt_long</mat-icon><span>PQRs</span>
      </button>
      <button mat-list-item class="btn-menu" [allowAction]="['maintenances.listAssignedToMe']" [routerLink]="['/app/list-orders/maintenances']">
        <mat-icon matListItemIcon>build</mat-icon><span>Órdenes</span>
      </button>
      <button mat-list-item class="btn-menu" [allowAction]="['maintenances.list']" [routerLink]="['/app/list-orders/maintenances']">
        <mat-icon matListItemIcon>build</mat-icon><span>Órdenes</span>
      </button>
      <button mat-list-item class="btn-menu" [allowAction]="['users.listMyBelongsToMyProjects', 'users.detail']" [routerLink]="['/app/technicians']">
        <mat-icon matListItemIcon>engineering</mat-icon><span>Técnicos</span>
      </button>
      <button mat-list-item class="btn-menu" [allowAction]="['maintenances.list', 'pqrs.list']"[routerLink]="['/app/reports']">
        <mat-icon matListItemIcon>text_snippet</mat-icon><span>Informes</span>
      </button>
      <button mat-list-item class="btn-menu" [allowAction]="['materials.create']" (click)="gotoControlPanel()">
        <mat-icon matListItemIcon>settings</mat-icon><span>Panel</span>
      </button>
      <button mat-list-item class="btn-menu" (click)="gotoMailbox()">
        <mat-icon matListItemIcon>mail</mat-icon><span>Buzón</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-list-item class="btn-menu" (click)="exit()">
        <mat-icon matListItemIcon>exit_to_app</mat-icon><span>Salir</span>
      </button>
    </mat-action-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
