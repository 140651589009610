import { Pipe, PipeTransform } from '@angular/core';
function getProp( object, keys, defaultVal ){
  keys = Array.isArray( keys )? keys : keys.split('.');
  object = object[keys[0]];
  if( object && keys.length>1 ){
    return getProp( object, keys.slice(1), defaultVal );
  }
  return object === undefined? defaultVal : object;
}
@Pipe({
  name: 'filterActionsMaintenance',
  standalone: true,
})
export class FilterActionsMaintenancePipe implements PipeTransform {

  private pendingSync(pendingSync) {
    return Object.keys(pendingSync || { }).find((key) => {
      return pendingSync[key];
    })
  }

  transform(actions: any[], maintenance: any): unknown {
    // maintenance.state !== 'CLOSED' ? actions : actionsClosed;

    return actions.filter((action) => {
      if (!action.conditions?.length) {
        return true;
      }
      return action.conditions.filter(({ key, value, equal }) => {
        if (equal && key === 'pendingSync' && value) {
          return this.pendingSync(maintenance.pendingSync);
        }
        const maintenanceValue = getProp(maintenance, key, undefined);
        return equal ? maintenanceValue === value: maintenanceValue !== value
      })?.length === action.conditions.length;
    });
  }

}
