import { update } from './../technician-dashboard-maintenances/daily-questionaries.actions';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import projectDailyQuestionary from '../repair-maintenance/questions-high-permission';
import { AppState } from '@ildes/app.reducer';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';
import { Store } from '@ngrx/store';
import { Observable, concat, defer } from 'rxjs';
import {map, mergeMap, tap } from 'rxjs/operators';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';
import { SessionStore } from '@ildes/stores/session.store';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { FileHelperService } from '@ildes/services/file-helper.service';

const DISCARD_CHANGES = {
    id: 'discard',
    description: 'Descartar',
    icon: 'trash'
};
const DOWNLOAD_DOCUMENTATION = {
  id: 'download',
  description: 'Descargar permiso de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};


const DOWNLOAD_ORDER_TASK = {
  id: 'download_order',
  description: 'Descargar orden de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};

const ACTIONS = [ DOWNLOAD_DOCUMENTATION ];

function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) =>  Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
        return defer(() => {
            onSubscribe();
            return source;
        });
    };
}
@Component({
    selector: 'app-technician-dashboard-expansions',
    template: `
      <pull-to-refresh [scrollableContent]="scrolledContent" [target]="container" (refresh)="refresh()" ></pull-to-refresh>
      <div class="container" #container>
        <div class="container">
          <div class="filter-container">
            <mat-button-toggle-group (change)="setMaintenanceFilter($event)" >
              <mat-button-toggle [checked]="true" value="NEW">Pendientes</mat-button-toggle>
              <mat-button-toggle value="IN_PROGRESS">En curso</mat-button-toggle>
              <mat-button-toggle value="CLOSED">Cerrados</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <section class="container">
            <mat-progress-bar mode="indeterminate" *ngIf="loadingFetch"></mat-progress-bar>
            <div class="scrollable-content" #scrolledContent>
              <section *ngIf="state !== 'IN_PROGRESS' && !loadingFetch">
                <div *ngFor="let order of expansions" class="item">
                  <app-list-order-item
                    type="Expansión"
                    [actions]="(actionsExpansionsClosed | filterActionsMaintenance:order)"
                    (onClickAction)="doAction(order, $event)"
                    (onLinkClick)="!pendingRequest && openExpansion(order)"
                    [state]="order.state"
                    [orderno]="order.orderno"
                    [estimatedResolutionDate]="order.estimatedResolutionDate"
                    [resolutionDate]="order.resolutionDate"
                    [technician]="order.technician"
                    [project]="order.project?.name || 'Sin proyecto'"
                    [address]="order.address|| order.luminary?.address"
                    [ubication]="order.geolocation || order.luminary?.geolocation"
                    [neighborhood]="order.neighborhood || order.luminary?.neighborhood"
                    [orderDescription]="order.orderDescription"
                    [luminary]="order.luminary"
                  ></app-list-order-item>
                  <mat-divider></mat-divider>
                </div>
                <div *ngIf="!expansions?.length">
                    <app-image-panel text="No existen expansiones" class="margin-top-16" ></app-image-panel>
                </div>
              </section>
              <section *ngIf="state === 'IN_PROGRESS'">
                <section *ngIf="pendingSyncDailyQuestionaries?.length && unsyncExpansionList?.length">
                  <h3>Existen cuestionarios diarios sin guardar</h3>
                  <mat-progress-bar mode="indeterminate" *ngIf="pendingQuestionaryRequest"></mat-progress-bar>
                  <app-alert-dialog
                      *ngIf="!pendingQuestionaryRequest"
                      text="Al guardar todo también se guardarán los cuestionarios diarios."
                      status="advise"></app-alert-dialog>
                </section>
                <section *ngIf="unsyncExpansionList?.length">
                  <h3>Expansiones pendientes de guardar</h3>
                  <div *ngFor="let unsyncExpansion of unsyncExpansionList" class="item">
                      <app-list-order-item
                        type="Expansión"
                        [pendingSync]="true"
                        [actions]="actionsExpansions"
                        (onClickAction)="!pendingRequest && onActionClick(unsyncExpansion, $event)"
                        (onLinkClick)="!pendingRequest && selectExpansion(unsyncExpansion)"
                        state="IN_PROGRESS"
                        [orderno]="unsyncExpansion.orderno || 'Pendiente de enumerar'"
                        [technician]="this.user"
                        [project]="unsyncExpansion.selectedProject?.name || unsyncExpansion.luminary?.projectName || 'Sin proyecto'"
                        [address]="unsyncExpansion.luminary?.address"
                        [ubication]="unsyncExpansion.luminary?.geolocation"
                        [neighborhood]="unsyncExpansion.luminary?.neighborhood"
                        [luminary]="unsyncExpansion.luminary"
                      ></app-list-order-item>
                      <!-- <list-luminary-item
                          (onActionClick)="!pendingRequest && onActionClick(unsyncExpansion, $event)"
                          (onLinkClick)="!pendingRequest && selectExpansion(unsyncExpansion)"
                          [actions]="actionsExpansions"
                          [number]="unsyncExpansion.luminary ? unsyncExpansion.luminary?.number: 'Pendiente crear luminaria'"
                          [project]="unsyncExpansion.selectedProject?.name || unsyncExpansion.luminary?.projectName || 'Sin proyecto'"
                          [address]="unsyncExpansion.luminary?.address"
                          [ubication]="unsyncExpansion.luminary?.geolocation"
                          [neighborhood]="unsyncExpansion.luminary?.neighborhood"
                          [technology]="unsyncExpansion.luminary?.technology"
                          [power]="unsyncExpansion.luminary?.power"
                      ></list-luminary-item> -->
                      <mat-progress-bar mode="indeterminate" *ngIf="loading[unsyncExpansion.id]"></mat-progress-bar>
                      <div class="padding-bottom-8">
                          <i>
                              <span *ngIf="!unsyncExpansion.resolutionDate"  class="advise"> Pendiente de completar el proceso </span>
                          </i>
                      </div>
                      <mat-divider></mat-divider>
                  </div>
                  <div class="align-center padding-top-8" *ngIf="hasPendingSync">
                      <button [disabled]="pendingRequest" mat-button (click)="sync()" class="button-size-200">
                          <mat-icon class="icon-button">cloud_upload</mat-icon>Guardar todo
                      </button>
                  </div>
                </section>
                <div *ngIf="!unsyncExpansionList?.length">
                    <app-image-panel text="No hay expansiones pendientes de sincronizar" class="margin-top-16" ></app-image-panel>
                </div>
              </section>
            </div>
            <mat-paginator
                *ngIf="!loadingFetch && pagination && expansions.length && state !== 'IN_PROGRESS'"
                class="bottom-space"
                [pageIndex]="currentPage"
                [length]="pagination?.totalDocs"
                pageSize="20"
                (page)="paginate($event)">
            </mat-paginator>
          </section>
        </div>
      </div>
      <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/create-expansion']"><mat-icon>add</mat-icon></button>
    `,
    styleUrls: ['./technician-dashboard-expansions.component.css'],
})
export class TechnicianDashboardExpansionsComponent implements OnInit {
    unsyncExpansionList;
    loading = {};
    loadingFetch = false;
    loadingQuestionary = {};
    actionsExpansions = [
        DISCARD_CHANGES
    ];
    actionsExpansionsClosed = [
      DOWNLOAD_DOCUMENTATION,
      DOWNLOAD_ORDER_TASK
    ];
    error = false;
    dailyQuestionaries;
    state = 'NEW';
    expansions;
    pagination;
    currentPage = 0;
    downloading = false;
    user;
    constructor(
        private store: Store<AppState>,
        private apiManager: ApiManagerService,
        private router: Router,
        private unsyncExpansions: UnsyncExpansionsService,
        private dialog: MatDialog,
        private fileHelper: FileHelperService
    ) {
        this.unsyncExpansionList = this.unsyncExpansions.getItems()?.filter(({state, unsync}) => unsync || state !== 'CLOSED' );

        this.store.dispatch(
            actionsQuestionaries.loadDailyQuestionaries()
        );

        this.store.select('dailyQuestionaries').subscribe((data) => {
            this.dailyQuestionaries = data.filter(({ pendingSync }) => pendingSync);
        });
    }

    ngOnInit(): void {
      this.user = SessionStore.getInstance().get().user;
      this.fetchExpansions();
    }

    get pendingQuestionaryRequest() {
        return Object.values(this.loadingQuestionary).some((value) => value );
    }
    get pendingRequest() {
        return Object.values(this.loading).some((value) => value );
    }

    public gotoDetail(order) {
      this.dialog.open(OrderDetailComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data: {
          expansion: order
        }
      });
    }

    onActionClick(expansion, event) {
      switch(event.id) {
        case DISCARD_CHANGES.id:
          this.unsyncExpansionList = this.unsyncExpansions.removeItem(expansion);

          break;
        default:
          break;
      }
    }

    selectExpansion(expansion) {
      this.router.navigate(['/app/create-expansion'], { queryParams: { id: expansion.id} })
    }

    openExpansion(expansion) {
      if (expansion.state === 'CLOSED') {
        this.gotoDetail(expansion);

        return;
      }
      this.router.navigate(['/app/create-expansion'], { queryParams: { id: expansion.id, orderno: expansion.orderno} })
    }

    get hasPendingSync() {
        return !!this.unsyncExpansionList?.filter(( {resolutionDate } ) => resolutionDate).length;
    }

    get pendingSyncDailyQuestionaries() {
        return this.dailyQuestionaries?.filter(({ pendingSync })=>pendingSync);
    }

    sync() {
        this.error = false;
        const requestsLuminaries = this.unsyncExpansionList.filter(({resolutionDate}) => resolutionDate).map((expansion) => {
            return this.unsyncExpansions.sync(expansion, expansion.update).pipe(
                doOnSubscribe(() => {
                    this.loading[expansion.id] = true;
                }),
                tap(() => ({
                    next: this.loading[expansion.id] = false
                })),
                map(() => {
                    return expansion;
                })
            )
        });

        const requestQuestionaries = [...this.pendingSyncDailyQuestionaries].map((questionary) => {
            const key = `${questionary.projectId}_${questionary.date}`;

            return this.syncDailyQuestionary(questionary).pipe(
                doOnSubscribe(() => {
                    this.loadingQuestionary[key] = true;
                }),
                tap(() => ({
                    next: this.loadingQuestionary[key] = false
                })),
                map(() => {
                    return questionary;
                })
            )
        });
        const userId = this.user.id;

        concat(...[...requestQuestionaries, ...requestsLuminaries]).subscribe(() => {
            this.unsyncExpansionList = this.unsyncExpansions.getItems().filter(({state, unsync}) => unsync || state !== 'CLOSED' );

            if (this.unsyncExpansionList?.length) {
                return;
            }
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
          }, (error) => {
            this.loading = {};
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
            if (error && error.code <= 0) {
              return;
            }
            this.error = true;
          });

    }

    private syncDailyQuestionary(dailyQuestionary) {
        const projectId = dailyQuestionary.projectId;
        const questionaryDate = dailyQuestionary.date;

        return this.apiManager.updateDailyQuestionary(projectId, {
            questions: projectDailyQuestionary,
            answers: dailyQuestionary.answers,
            date: questionaryDate,
        }).pipe(
            tap(() => {
                this.store.dispatch(
                    actionsQuestionaries.update({
                        changes: { pendingSync: false },
                        date: new Date(questionaryDate),
                        projectId: projectId,
                    })
                );
            })
        )
    }

    setMaintenanceFilter(event) {
      this.state = event.value;
      this.pagination = null;
      if (this.state === 'IN_PROGRESS') {
        return
      }
      if (this.state === 'CLOSED') {
        this.fetchExpansions(1, 20);
      } else {
        this.fetchExpansions();
      }
    }

    fetchExpansions(pageIndex = 1, limit = 0, cache = true) {
      this.currentPage = pageIndex - 1;
      let page;
      const sessionStore = SessionStore.getInstance();
      const userId = sessionStore.get().user.id;
      this.loadingFetch = true;

      page = limit ? { page: pageIndex, limit } : {};

      //this.apiManager.getExpansions({ assigned: userId, ...this.state && {state: this.state}, ...page}).subscribe((data: any) => {
      this.unsyncExpansions.fetchExpansions( { state: this.state, ...page}, cache).subscribe((data: any) => {
        this.loadingFetch = false;
        this.expansions = data.data;
        this.pagination = limit && data.pagination;
      }, () => {
        this.loadingFetch = false;
      })
    }

    refresh() {
      if (this.state === 'IN_PROGRESS') {
        return;
      }
      this.fetchExpansions(this.pagination?.page, this.pagination?.limit, false);
    }

    doAction(order, action) {
      switch(action.id) {
        case DOWNLOAD_DOCUMENTATION.id:

          this.downloadDocumentation(order.id).subscribe((data) => {
            this.open(data);
          });
        break;
        case DOWNLOAD_ORDER_TASK.id:
          this.open(order.orderTaskDocumentPath);
        break;

      }

    }

    downloadDocumentation(orderId) {
      return this.apiManager.getTasks({
        type: 'DOCUMENT_SIGN',
        subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
        'metadata.expansion': orderId
      }).pipe(
        mergeMap((taskData: any) => {
          const task = taskData.data[0];
          if (!task.usersWhoPerformTheTask?.length) {
            return [task.metadata.documentName];
          } else {
            return this.apiManager.getEvidence({ taskId: task.id })
              .pipe(
                map(({ data }: any) => data.signedDocumentName)
              )
          }
        })
      );
    }

    open(fileName) {
      this.downloading = true;
      this.fileHelper.downloadPdf(fileName).subscribe(async (data: any) => {
        this.downloading = false;
      }, () => {
        this.downloading = false;
      })
    }

    paginate(event) {
      this.fetchExpansions(event.pageIndex + 1, 20, false);
    }
}
