import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BottomNavigationComponent } from '@ildes/basic/bottom-navigation/bottom-navigation.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';

@Component({
    selector: 'app-list-orders',
    standalone: true,
    imports: [
        CommonModule,
        SimpleHeaderComponent,
        RouterModule,
        BottomNavigationComponent
    ],
    template: `
      <div class="container" #container>
          <router-outlet></router-outlet>
      </div>
      <app-bottom-navigation [buttons]="buttons"></app-bottom-navigation>
    `,
    styleUrls: ['./list-orders.component.css'],
})
export class ListOrdersComponent {

  buttons=[
    {
      icon:'build',
      text: 'Mantenimientos',
      url:'/app/list-orders/maintenances',
    },
    {
      icon:'wb_incandescent',
      text: 'Expansiones',
      url:'/app/list-orders/expansions',
    },
    {
      icon:'refresh',
      text: 'Modernizaciones',
      url:'/app/list-orders/modernizations',
    }
  ]
}
