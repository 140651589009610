import { ActivatedRoute } from '@angular/router';
import { ListOrderItemComponent } from '@ildes/basic/list-order-item/list-order-item.component';
import { ListMaintenanceItemComponent } from '@ildes/basic/list-maintenance-item/list-maintenance-item.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { first, map } from 'rxjs/operators';
import { MatDividerModule } from '@angular/material/divider';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    selector: 'app-luminary-history',
    standalone: true,
    imports: [
        CommonModule,
        SimpleHeaderComponent,
        ListMaintenanceItemComponent,
        ListOrderItemComponent,
        MatDividerModule,
        MatProgressBarModule,
    ],
    template: `
    <div class="container">
      <div class="scrollable-content">
        <simple-header title="Histórico de luminaria" [back]="true" icon="close" ></simple-header>
        <div *ngFor="let order of history" class="margin-top-8">
          <list-maintenance-item
            *ngIf="order.type.id !== 'EXPANSION' && order.type.id !== 'MODERNIZATION'"
            [newLuminaryNumber]="order.newLuminaryNumber"
            [newTechnology]="order.newTechnology"
            [newPower]="order.newPower"
            [luminaryOfTemporalUse]="order.luminaryOfTemporalUse"
            [parent]="order.parent"
            (onLinkClick)="gotoDetail(order, $event)"
            [selector]="selector"
            [state]="order.state"
            [type]="order.type"
            [pqr]="order.pqr?.length === 0 ? undefined : order.pqr"
            [luminaryNumber]="order.oldLuminaryNumber || order.luminary?.number"
            [project]="order.project?.name || 'Sin proyecto'"
            [address]="order.address"
            [orderno]="order.orderno"
            [neighborhood]="order.neighborhood"
            [referencePoint]="order.locationReferenceDescription"
            [painType]="order.painType"
            [painDescription]="order.painDescription"
            [technician]="order.technician"
            [power]="order.oldPower || order.luminary?.power"
            [technology]="order.oldTechnology || order.luminary?.technology"
            [estimatedResolutionDate]="order.estimatedResolutionDate"
            [repairmentDate]="order.resolutionDate"
            [materials]="order.materials"
          ></list-maintenance-item>
          <app-list-order-item
            *ngIf="order.type.id === 'EXPANSION' || order.type.id === 'MODERNIZATION'"
            [luminary]="order.luminary"
            [type]="order.type.description"
            [luminaryNumber]="order.luminaryNumber"
            [state]="order.state"
            [orderno]="order.orderno"
            (onLinkClick)="gotoDetail(order)"
            [estimatedResolutionDate]="order.estimatedResolutionDate"
            [resolutionDate]="order.resolutionDate"
            [technician]="order.technician"
            [project]="order.project?.name || 'Sin proyecto'"
            [address]="order.address || order.luminary?.address"
            [ubication]="order.geolocation || order.luminary?.geolocation"
            [neighborhood]="order.neighborhood || order.luminary?.neighborhood"
            [orderDescription]="order.orderDescription"
            [orderDescription]="order.orderDescription"
            [technology]="order.newTechnology"
            [power]="order.newPower"
            [oldPower]="order.oldPower"
            [oldTechnology]="order.oldTechnology"
            [oldLuminaryNumber]="order.oldLuminaryNumber"
            [newLuminaryNumber]="order.newLuminaryNumber"
          ></app-list-order-item>
          <div>
              <mat-progress-bar *ngIf="order.loading" mode="indeterminate"></mat-progress-bar>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
    `,
    styleUrls: ['./luminary-history.component.css'],
})
export class LuminaryHistoryComponent implements OnInit {
  history;
  id;
  loading;
  constructor(
    private apiManager: ApiManagerService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {

  }
  async gotoDetail(order) {
    const data: any = {};
    let request = null;
    order.loading = true;
    if (order.type.id === 'EXPANSION') {
      request = this.apiManager.getExpansionDetail(order._id)
      .pipe(
        map((dataRes: any) => ({expansion: dataRes.data}))
      )
    } else if (order.type.id === 'MODERNIZATION') {
      request = this.apiManager.getModernizationDetail(order._id)
        .pipe(
          map((dataRes: any) => ({modernization: dataRes.data}))
        )
    } else {
      request = this.apiManager.getDetailMaintenance(order._id)
        .pipe(
          map((dataRes: any) => ({maintenance: dataRes}))
        )
    }

    request.subscribe((data) => {
      order.loading = false;
      this.dialog.open(OrderDetailComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data
      });
    });
  }

  ngOnInit(): void {
    this.route.queryParams.pipe((first())).subscribe(params => {
      this.id = params.id;
      this.loading = true;
      this.apiManager.getHistoryLuminary(this.id).subscribe((data:any) => {
        this.history = data?.data;
        this.loading = false;
      });
    });
  }
}
