import { FileHelperService } from './../../services/file-helper.service';
import { ListOrderItemComponent } from './../../basic/list-order-item/list-order-item.component';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterModule } from '@angular/router';
import { LoadingComponent } from '@ildes/basic/loading/loading.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { AllowActionDirective } from '@ildes/directives/allow-action.directive';
import { ImagePanelComponent } from '@ildes/basic/image-panel/image-panel.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FilterActionsMaintenancePipe } from '@ildes/pipes/filter-actions-maintenance.pipe';
import { MatDialog } from '@angular/material/dialog';
import { SelectUsersDialogComponent } from '@ildes/basic/select-users-dialog/select-users-dialog.component';
import { map, mergeMap } from 'rxjs/operators';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { SessionStore } from '@ildes/stores/session.store';

const DELETE_MAINTENANCE = {
  id: 'delete',
  description: 'Eliminar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'update'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const EDIT_MAINTENANCE = {
  id: 'edit',
  description: 'Modificar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'update'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const ASSIGN_ACTION = {
  id: 'assign',
  description: 'Asignar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'assignToTechnician'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const SELECT_ACTION = {
  id: 'select',
  icon: 'assignment_ind',
  description: 'Seleccionar',
  permission: {
    family: 'maintenances',
    actionName: 'assignToTechnician'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const ASSIGN_TO_TECHNICAL = {
  icon: 'assignment_ind',
  id: 'assign_to_technical',
  description: 'Asignar a técnico',
  disabled: false
};

const CANCEL_ASSIGN = {
  icon: 'close',
  id: 'cancel',
  description: 'Cancelar selección',
  disabled: false,
};

const GO_TO_DETAIL = {
  icon: 'close',
  id: 'detail',
  description: 'Ver expansión',
  disabled: false,
};
const REPAIR_MAINTENANCE = {
  id: 'fixMaintenance',
  description: 'Reparar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'repair'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: false
    }
  ]
};

const DOWNLOAD_DOCUMENTATION = {
  id: 'download',
  description: 'Descargar permiso de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};

const DOWNLOAD_ORDER_TASK = {
  id: 'download_order',
  description: 'Descargar orden de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};

const ACTIONS = [ REPAIR_MAINTENANCE, GO_TO_DETAIL, ASSIGN_ACTION, SELECT_ACTION, DELETE_MAINTENANCE, DOWNLOAD_DOCUMENTATION, DOWNLOAD_ORDER_TASK ];
const GROUP_ACTIONS = [ ASSIGN_TO_TECHNICAL, CANCEL_ASSIGN];

@Component({
    selector: 'app-list-expansions',
    standalone: true,
    imports: [
        CommonModule,
        SimpleHeaderComponent,
        MatTabsModule,
        AllowActionDirective,
        LoadingComponent,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        ImagePanelComponent,
        ListOrderItemComponent,
        MatDividerModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        FilterActionsMaintenancePipe,

    ],
    template: `
    <div class="container">
      <simple-header title="Expansiones" [back]="true" icon="close" [actions]="groupActions" (action)="onClickHeaderAction($event)" ></simple-header>
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link [active]="tabActive==='ALL'" (click)="changeTab('ALL')"> Todos </a>
        <a mat-tab-link [active]="tabActive==='NEW'" (click)="changeTab('NEW')"> Pendientes </a>
        <a mat-tab-link [active]="tabActive==='CLOSED'" (click)="changeTab('CLOSED')"> Finalizados </a>
      </nav>
      <mat-tab-nav-panel #tabPanel class="content-tab-panel">
        <div class="flex">
          <div class="scrollable-content">
            <mat-form-field appearance="fill" class="margin-top-16" *ngIf="showAssigmentFilter && tabActive!=='CLOSED'">
              <mat-label>Filtrar por asignación</mat-label>
              <mat-select (selectionChange)="onChangeAssigned($event)">
                <mat-option>
                  Todos
                </mat-option>
                <mat-option value="false">
                  No asignado
                </mat-option>
                <mat-option value="true">
                  Asignado
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div style="height: 30px;margin: auto;text-align: center;" *ngIf="loading">
              <app-loading diameter="30"></app-loading>
            </div>
            <div *ngFor="let order of expansions" class="item">
              <app-list-order-item
                [selected]="order.selected"
                [luminary]="order.luminary"
                [selector]="selector"
                (onClickAction)="onClickAction($event, order)"
                [actions]="actions | filterActionsMaintenance:order"
                [state]="order.state"
                [orderno]="order.orderno"
                (onChangeSelected)="onChangeSelected(order, $event)"
                (onActionClick)="!pendingRequest && onActionClick(order, $event)"
                (onLinkClick)="!pendingRequest && gotoDetail(order)"
                [estimatedResolutionDate]="order.estimatedResolutionDate"
                [resolutionDate]="order.resolutionDate"
                [technician]="order.technician"
                [project]="order.project?.name || 'Sin proyecto'"
                [address]="order.address|| order.luminary?.address"
                [ubication]="order.geolocation || order.luminary?.geolocation"
                [neighborhood]="order.neighborhood || order.luminary?.neighborhood"
                [orderDescription]="order.orderDescription"
                [orderDescription]="order.orderDescription"
              ></app-list-order-item>
              <mat-divider></mat-divider>
            </div>
            <app-image-panel class="margin-top-16" *ngIf="!expansions?.length && !loading"></app-image-panel>
          </div>
          <mat-paginator
            *ngIf="!loading && expansions?.length"
            [pageIndex]="currentPage"
            [length]="pagination?.totalDocs"
            pageSize="20"
            (page)="paginate($event)">
          </mat-paginator>
        </div>
      </mat-tab-nav-panel>
      <button *ngIf="!isTechnicalRole" mat-fab color="primary" class="fab-button" [allowAction]="['maintenances.create']" [routerLink]="['/app/list-maintenances/create']"><mat-icon>add</mat-icon></button>
    </div>

    `,
    styleUrls: ['./list-expansions.component.css']
})
export class ListExpansionsComponent implements OnInit {
  downloading;
  tabActive = 'ALL';
  loading = false;
  expansions;
  actionsExpansions = [];
  pagination;
  currentPage = 0;
  selector = false;
  groupActions;
  selectedOrders = [];
  actions = ACTIONS;
  constructor(
    private apiManager: ApiManagerService,
    private router: Router,
    private dialog: MatDialog,
    private fileHelper: FileHelperService,
  ) {

  }
  ngOnInit(): void {
    this.fetchExpansions();
  }

  get showAssigmentFilter() {
    const user = SessionStore.getInstance().get().user;

    return !user.permissions?.maintenances?.listAssignedToMe || user?.permissions?.maintenances?.list
  }

  get isTechnicalRole() {
    const role =  SessionStore.getInstance().get().user.role.id;

    return ['DRIVER', 'TECHNICIAN'].indexOf(role) >= 0;
  }
  changeTab(tab) {
    this.tabActive = tab;
    this.fetchExpansions();
  }
  fetchExpansions(pageIndex = 0, isAssigned = undefined) {
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const params = {
      ...this.tabActive !== 'ALL' && { state: this.tabActive },
      ...page,
      ...isAssigned !== undefined && { isAssigned }
    };
    this.loading = true;
    this.expansions = [];
    this.apiManager.getExpansions(params).subscribe((data: any) => {
      this.expansions = data?.data;
      this.pagination = data.pagination;
      this.loading = false;
    }, () => {
      this.loading = false;
    })

  }

  onChangeAssigned(event) {

    this.fetchExpansions(0, event.value);

  }

  paginate(event) {
    this.fetchExpansions(event.pageIndex);
  }


  onClickAction(action, order) {

    switch (action.id) {
      case ASSIGN_ACTION.id:
          this.removeSelected();
          this.selector = false;
          order.selected = true;
          // this.store.dispatch( actions.selectMaintenance({ maintenance: {...maintenance} }))
          this.selectedOrders = [...this.selectedOrders, order];
          this.groupActions = null;
          //this.router.navigate(['/app/list-maintenances/assign-to-technical']);
          this.assignToTechnical();

        break;
      case SELECT_ACTION.id:
          this.selector = true;
          //this.store.dispatch( actions.selectMaintenance({ maintenance: {...maintenance} }))
          this.selectedOrders = [...this.selectedOrders, order];
          order.selected = true;
          this.groupActions = GROUP_ACTIONS;
        break;
      case GO_TO_DETAIL.id:
        this.gotoDetail(order);

        break;
      case REPAIR_MAINTENANCE.id:
        this.selector = false;
        this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: order.id } });

        break;
      case DELETE_MAINTENANCE.id:
        this.selector = false;
        this.remove(order.id)

        break;
      case EDIT_MAINTENANCE.id:
        this.selector = false;

        break;
      case DOWNLOAD_DOCUMENTATION.id:
        this.selector = false;

        this.downloadDocumentation(order.id).subscribe((data) => {
          this.open(data);
        });
        break;
      case DOWNLOAD_ORDER_TASK.id:
        this.selector = false;

        this.open(order.orderTaskDocumentPath);
        break;
      default:
        this.selector = false;
    }
  }

  public gotoDetail(order) {
    this.selector = false;
    this.dialog.open(OrderDetailComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        expansion: order
      }
    });
  }
  downloadDocumentation(id) {
    return this.apiManager.getTasks({
      type: 'DOCUMENT_SIGN',
      subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
      'metadata.expansion': id
    }).pipe(
      mergeMap((taskData: any) => {
        const task = taskData.data[0];
        if (!task.usersWhoPerformTheTask?.length) {
          return [task.metadata.documentName];
        } else {
          return this.apiManager.getEvidence({ taskId: task.id })
            .pipe(
              map(({ data }: any) => data.signedDocumentName)
            )
        }
      })
    );
  }

  open(fileName) {
    this.downloading = true;
    this.fileHelper.downloadPdf(fileName).subscribe(() => {
      this.downloading = false;
    }, () => {
      this.downloading = false;
    })
  }

  remove(id) {
    this.loading = true;
    this.apiManager.removeExpansion(id).subscribe((data: any) => {
      this.loading = false;
      this.fetchExpansions();
    }, () => {
      this.loading = false;
    })
  }

  removeSelected() {
    this.selectedOrders.forEach((order) => {order.selected = false});
    this.selectedOrders = [];
  }

  assignToTechnical() {
    const dialog = this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        roleId: ['DRIVER', 'TECHNICIAN'],
        belongsToMyProjects: true,
        selection: SelectUsersDialogComponent.UNIQUE_SELECTION,
      }
    });
    dialog.afterClosed().subscribe( (data) => {
      this.groupActions = null;
      if (data.close) {
        this.removeSelected();
        return;
      }
      const orders = this.selectedOrders.map((order) => ({...order, ...{ technician: data.selectedUser.id } }));
      this.removeSelected();
      this.loading = true;
      this.apiManager.assignExpansionsToTechnical(orders).subscribe(() => {
        this.loading = false;
        this.fetchExpansions();
      }, () => {
        this.loading = false;
      });
    });
  }

  onClickHeaderAction(action) {
    switch (action.id) {
      case CANCEL_ASSIGN.id:
        this.selector = false;
        this.groupActions = null;
        this.removeSelected();

        break;
      case ASSIGN_TO_TECHNICAL.id:
        this.selector = false;
        this.assignToTechnical();

        break;
      default:
        this.selector = false;
    }
  }

  onChangeSelected(order, value) {
    order.selected = value.checked;
    this.selectedOrders = [...this.selectedOrders, order];
  }


}
