import { FileHelperService } from './../../services/file-helper.service';
import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { Store } from '@ngrx/store';
import { SessionStore } from '@ildes/stores/session.store';
import * as actions from './maintenances.actions';
import { Router } from '@angular/router'
import { AppState } from '@ildes/app.reducer';
import { MatDialog } from '@angular/material/dialog';
import { SelectUsersDialogComponent } from '@ildes/basic/select-users-dialog/select-users-dialog.component';
import { map, mergeMap } from 'rxjs/operators';
import { OrderDetailComponent } from '../order-detail/order-detail.component';

const DELETE_MAINTENANCE = {
  id: 'delete',
  description: 'Eliminar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'update'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const EDIT_MAINTENANCE = {
  id: 'edit',
  description: 'Modificar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'update'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const ASSIGN_ACTION = {
  id: 'assign',
  description: 'Asignar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'assignToTechnician'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const SELECT_ACTION = {
  id: 'select',
  icon: 'assignment_ind',
  description: 'Seleccionar',
  permission: {
    family: 'maintenances',
    actionName: 'assignToTechnician'
  },
  conditions: [
    {
      key: 'state',
      value: 'NEW',
      equal: true
    }
  ]
};

const ASSIGN_TO_TECHNICAL = {
  icon: 'assignment_ind',
  id: 'assign_to_technical',
  description: 'Asignar a técnico',
  disabled: false
};

const CANCEL_ASSIGN = {
  icon: 'close',
  id: 'cancel',
  description: 'Cancelar selección',
  disabled: false,
};

const GO_TO_DETAIL = {
  icon: 'close',
  id: 'detail',
  description: 'Ver mantenimiento',
  disabled: false,
};
const REPAIR_MAINTENANCE = {
  id: 'fixMaintenance',
  description: 'Reparar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'repair'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: false
    }
  ]
};

const DOWNLOAD_DOCUMENTATION = {
  id: 'download',
  description: 'Descargar permiso de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};


const DOWNLOAD_TASK_ORDER = {
  id: 'download_order_task',
  description: 'Descargar orden de trabajo',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'read'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};

const ACTIONS = [ REPAIR_MAINTENANCE, GO_TO_DETAIL, ASSIGN_ACTION, SELECT_ACTION, DELETE_MAINTENANCE, DOWNLOAD_DOCUMENTATION, DOWNLOAD_TASK_ORDER ];
const GROUP_ACTIONS = [ ASSIGN_TO_TECHNICAL, CANCEL_ASSIGN];

@Component({
  selector: 'app-list-maintenances',
  templateUrl: './list-maintenances.component.html',
  styleUrls: ['./list-maintenances.component.css']
})
export class ListMaintenancesComponent implements OnInit {
  public downloading;
  tabActive = 'ALL';
  pagination;
  currentPage = 0;
  loading;
  actions = ACTIONS;
  groupActions;
  selector;
  isAssigned;
  user;
  assigned;
  showAssigmentFilter = true;
  selectedMaintenances = [];
  constructor(
    private apiManager: ApiManagerService,
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private fileHelper: FileHelperService,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(actions.resetSelectedMaintenances());
    const sessionStore = SessionStore.getInstance();
    this.user = sessionStore.get().user;
    this.setAssigned();
  }

  onClickAction({ maintenance, action }) {
    if(!action) {
      this.selector = false;
      if (maintenance.technician?.id === this.user.id && maintenance.state !== 'CLOSED') {
        this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: maintenance.id } });
      } else {
        this.selector = false;
        this.dialog.open(OrderDetailComponent, {
          width: '600px',
          height: '600px',
          panelClass: 'responsive-dialog',
          data: {
            maintenance
          }
        });
      }
      return;
    }
    switch (action.id) {
      case DOWNLOAD_TASK_ORDER.id:
        this.selector = false;

        this.downloadOrderTask(maintenance.id).subscribe((data) => {
          this.fileHelper.open(maintenance.orderno, data);
        });
        break;
      case ASSIGN_ACTION.id:
          this.removeSelected();
          this.selector = false;
          maintenance.selected = true;
          // this.store.dispatch( actions.selectMaintenance({ maintenance: {...maintenance} }))
          this.selectedMaintenances = [...this.selectedMaintenances, maintenance];
          this.groupActions = null;
          //this.router.navigate(['/app/list-maintenances/assign-to-technical']);
          this.assignToTechnical();

        break;
      case SELECT_ACTION.id:
          this.selector = true;
          //this.store.dispatch( actions.selectMaintenance({ maintenance: {...maintenance} }))
          this.selectedMaintenances = [...this.selectedMaintenances, maintenance];
          maintenance.selected = true;
          this.groupActions = GROUP_ACTIONS;
        break;
      case GO_TO_DETAIL.id:
        this.selector = false;
        this.dialog.open(OrderDetailComponent, {
          width: '600px',
          height: '600px',
          panelClass: 'responsive-dialog',
          data: {
            maintenance
          }
        });
        //this.router.navigate(['/app/detail-maintenance'], { queryParams: { id: maintenance.id } });

        break;
      case REPAIR_MAINTENANCE.id:
        this.selector = false;
        this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: maintenance.id } });

        break;
      case DELETE_MAINTENANCE.id:
        this.selector = false;
        this.removeMaintenance(maintenance.id)

        break;
      case EDIT_MAINTENANCE.id:
        this.selector = false;

        break;
      case DOWNLOAD_DOCUMENTATION.id:
        this.selector = false;
        this.downloading = true;
        this.downloadDocumentation(maintenance.id).subscribe((data) => {
          this.fileHelper.downloadPdf(data).subscribe(() => {
            this.downloading = false;
          }, () => {
            this.downloading = false;
          });
        }, () => {
            this.downloading = false;
        });
        break;
      default:
        this.selector = false;
    }
  }

  downloadOrderTask(maintenanceId) {
    return this.apiManager.getOrderTask(maintenanceId, {}, { contentType: 'application/pdf', error: true });
  }

  downloadDocumentation(maintenanceId) {
    return this.apiManager.getTasks({
      type: 'DOCUMENT_SIGN',
      subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
      'metadata.maintenance': maintenanceId
    }).pipe(
      mergeMap((taskData: any) => {
        const task = taskData.data[0];
        if (!task.usersWhoPerformTheTask?.length) {
          return [task.metadata.documentName];
        } else {
          return this.apiManager.getEvidence({ taskId: task.id })
            .pipe(
              map(({ data }: any) => data.signedDocumentName)
            )
        }
      })
    );
  }
  removeMaintenance(id) {
    this.loading = true;
    this.apiManager.removeMaintenance(id).subscribe(() => {
      this.loading = false;
    }, () => {
      this.loading = false;
    })
  }
  assignToTechnical() {
    const dialog = this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        roleId: ['DRIVER', 'TECHNICIAN'],
        belongsToMyProjects: true,
        selection: SelectUsersDialogComponent.UNIQUE_SELECTION,
      }
    });
    dialog.afterClosed().subscribe( (data) => {
      this.groupActions = null;
      if (data.close) {
        return;
      }
      const maintenances = this.selectedMaintenances.map((maintenance) => ({...maintenance, ...{ technician: data.selectedUser.id } }));

      this.loading = true;
      this.apiManager.assignMaintenancesToTechnical(maintenances).subscribe(() => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    });
  }
  changeSelection({ maintenance, checked }) {
    if (checked) {
      this.selectedMaintenances = [...this.selectedMaintenances, maintenance];
      //this.store.dispatch( actions.selectMaintenance({ maintenance: {...maintenance} }))
      maintenance.selected = true;

    } else {
      this.selectedMaintenances = this.selectedMaintenances.filter(({ id }) => {
        return id !== maintenance.id
      })
      // this.store.dispatch( actions.unselectMaintenance({ maintenance: {...maintenance} }))
      maintenance.selected = false;
    }
  }

  onClickHeaderAction(action) {
    switch (action.id) {
      case CANCEL_ASSIGN.id:
        this.selector = false;
        this.groupActions = null;
        this.removeSelected();

        break;
      case ASSIGN_TO_TECHNICAL.id:
        this.selector = false;
        this.assignToTechnical();

        break;
      default:
        this.selector = false;
    }
  }

  private removeSelected() {
    // this.maintenances.forEach((maintenance) => {
    //   maintenance.selected = false;
    // });
    this.store.dispatch(actions.resetSelectedMaintenances());
  }

  private setAssigned() {
    if (this.user?.permissions?.maintenances?.listAssignedToMe && !this.user?.permissions?.maintenances?.list) {
      this.assigned = this.user.id;
      this.showAssigmentFilter = false;
    }
  }

  get isTechnicalRole() {
    const role =  SessionStore.getInstance().get().user.role.id;

    return ['DRIVER', 'TECHNICIAN'].indexOf(role) >= 0;
  }
}
