const fill = (number) => {
  return number.toString().padStart(2, '0')
}

export const humanizeDate = (value, hours = false) => {
  const formatDate = new Date(value);

  let humanizedDate = `${fill(formatDate.getDate())}/${fill((formatDate.getMonth() + 1))}/${formatDate.getFullYear()}`;

  if (hours) {
    humanizedDate = `${humanizedDate} ${fill(formatDate.getHours())}:${fill(formatDate.getMinutes())}`
  }

  return humanizedDate;
}
