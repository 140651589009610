import { MaterialSelectorComponent } from '../../basic/material-selector/material-selector.component';
import { ProjectSelectorComponent } from '../../basic/project-selector/project-selector.component';
import { CitySelectorComponent } from '../../basic/city-selector/city-selector.component';
import { DepartmentSelectorComponent } from '../../basic/department-selector/department-selector.component';
import { NeighborhoodSelectorComponent } from '../../basic/neighborhood-selector/neighborhood-selector.component';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { columns as columnsDetailed, parse } from './detailed-table';
import { columns as columnsResumed } from './resumed-table';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateSelectorComponent } from '@ildes/basic/date-selector/date-selector.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { SelectUsersDialogComponent } from '@ildes/basic/select-users-dialog/select-users-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DropdownSelectorComponent } from '@ildes/basic/dropdown-selector/dropdown-selector.component';
import constants from '@ildes/config/constants';
import { TYPES_MATERIAL } from '@ildes/config/constants';
import { ImagePanelComponent } from '@ildes/basic/image-panel/image-panel.component';

const SIZE = 200;
const REQUEST_TYPES = [
  {
    id: 'AGGREGATED',
    description: 'Resumida',
    columns: columnsResumed
  },
  {
    id: 'DETAILED',
    description: 'Detallada',
    columns: columnsDetailed
  },
];

@Component({
    selector: 'app-reporting-materials',
    standalone: true,

    imports: [
      ImagePanelComponent,
      DepartmentSelectorComponent,
      CitySelectorComponent,
      ReactiveFormsModule,
      CommonModule,
      SimpleHeaderComponent,
      MatPaginatorModule,
      MatProgressBarModule,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      DateSelectorComponent,
      MatDatepickerModule,
      MatIconModule,
      ProjectSelectorComponent,
      MaterialSelectorComponent,
      MatMenuModule,
      DropdownSelectorComponent,
      MatButtonModule,
    ],
    template: `
    <div class="container">
        <simple-header [title]="title" [back]="true" icon="close"></simple-header>
        <section class="filter-container">
          <div class="field">
            <dropdown-selector class="full-width margin-top-8" label="Tipo de consulta" [types]="requestTypes" [(selected)]="requestType"></dropdown-selector>
          </div>
          <div class="field">
              <dropdown-selector class="full-width margin-top-8" label="Tipo de orden" [types]="types" [(selected)]="type"></dropdown-selector>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Cerrado después de</mat-label>
                <input matInput [formControl]="startDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Cerrado antes de</mat-label>
                <input matInput [formControl]="endDate" [matDatepicker]="picker2">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" class="full-width" >
                <mat-label>Técnico</mat-label>
                <input matInput (click)="openSelectUserDialog()" readonly [formControl]="technician" maxlength="90">
                <button mat-icon-button matSuffix (click)="removeTechnician()" >
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
          </div>
          <div class="field">
            <app-project-selector [formControl]="projectForm"></app-project-selector>
          </div>
          <div class="field">
            <app-department-selector [formControl]="departmentForm"></app-department-selector>
          </div>
          <div class="field">
            <app-city-selector [formControl]="cityForm"></app-city-selector>
          </div>
          <div class="field">
            <app-material-selector label="Material" [formControl]="materialForm"></app-material-selector>
          </div>
          <div class="field">
            <dropdown-selector class="full-width margin-top-8" label="Tipo de material" [types]="typesMaterial" [(selected)]="typeMaterial"></dropdown-selector>
          </div>

        </section>
        <section class="button-container">
          <button (click)="list()" mat-button>Aplicar filtros</button>
          <button (click)="exportData()" mat-button>Exportar</button>
        </section>
        <h3>Resultados de búsqueda</h3>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <app-image-panel class="margin-top-16" *ngIf="!parsedItems?.length && !loading"></app-image-panel>
        <div class="container-table" *ngIf="!loading && parsedItems?.length">
          <table [width]="width">
            <tr class="header">
              <th *ngFor="let columnName of header">{{columnName}}</th>
            </tr>
            <tr *ngFor="let row of parsedItems">
              <td *ngFor="let columnValue of row">
                <span *ngIf="!columnValue.config?.link" [innerHTML]="columnValue.value"></span>
                <button mat-button *ngIf="columnValue.config?.link && columnValue.value">{{columnValue.value}}</button>
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          *ngIf="!loading && parsedItems?.length"
          [pageIndex]="currentPage"
          [length]="pagination?.totalDocs"
          pageSize="20"
          (page)="paginate($event)">
        </mat-paginator>
        <app-image-panel text="No se encontraron resultados" *ngIf="!loading && !parsedItems?.length"></app-image-panel>
    </div>`,
    styleUrls: ['./reporting-materials.component.css']
})
export class ReportingMaterialsComponent implements OnInit {
  types = constants.TYPES;
  type;
  currentPage;
  typesMaterial = TYPES_MATERIAL;
  typeMaterial = TYPES_MATERIAL[0];
  requestTypes = REQUEST_TYPES;
  requestType = REQUEST_TYPES[0];
  materialForm =new FormControl({
    typed: '',
    selected: undefined
  });
  cityForm =new FormControl({
    typed: '',
    selected: undefined
  });
  departmentForm =new FormControl({
    typed: '',
    selected: undefined
  });
  loading;
  items;
  pagination;
  header;
  columnsConfig;
  parsedItems;
  selectedTechnician;
  technician = new FormControl('Todos');
  projectForm = new FormControl({
    typed: '',
    selected: undefined
  });
  project = null;
  startDate = new FormControl();
  endDate = new FormControl();
  size;
  public title = 'Material';
  constructor(
    public apiManager: ApiManagerService,
    public dialog: MatDialog
  ) {

  }

  setTableConfig() {
    this.columnsConfig = this.requestType.columns;
    this.header = Object.values(this.columnsConfig).map(({name}) => name);
    this.size = `${SIZE * this.header.length}px`;
  }

  ngOnInit(): void {
    this.list();
  }

  getFilter() {
    const start = this.startDate.value;
    const end = this.endDate.value;
    const type = this.type?.id;
    const participant = this.selectedTechnician?.id
    const project = this.projectForm.value?.selected?.id;
    const material = this.materialForm.value?.typed;
    const department =  this.departmentForm.value?.typed;
    const city =  this.cityForm.value?.typed;
    const typeMaterial =  this.typeMaterial?.id

    return {
      ...start && { startRepairmentDate: start.toISOString() },
      ...end && { endRepairmentDate: end.toISOString() },
      ...type && { orderType: type },
      ...participant && { participant },
      ...project && { project },
      ...material && { material },
      ...department && { department },
      ...city && { city },
      ...typeMaterial && { typeMaterial },
    }
  }

  list(pageIndex = 0) {
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1, limit: 20 } : { };
    const filter: any = this.getFilter();
    this.loading = true;
    this.fetch(page, filter).subscribe((data: any) => {
      this.setTableConfig();
      this.loading = false;
      if (!data?.data) {
        this.items = [];
        this.parsedItems = [];
        this.pagination = undefined;

        return;
      }
      this.items = data.data;
      this.parsedItems = parse(this.items, this.columnsConfig);
      this.pagination = data.pagination;
    }, () => {
      this.loading = false;
    });
  }

  fetch(page, filter) {
    if (this.requestType === REQUEST_TYPES[1]) {
      return this.apiManager.reportDetailedMaterials({...page, ...filter});
    }
    return this.apiManager.reportAggregatedMaterials({...page, ...filter});
  }

  paginate(event) {
    this.list(event.pageIndex);
  }

  openSelectUserDialog() {
    const dialog = this.dialog.open(SelectUsersDialogComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data: { ...this.selectedTechnician && {selected: [{...this.selectedTechnician}]}, selection: SelectUsersDialogComponent.UNIQUE_SELECTION}
      });

    dialog.afterClosed().subscribe((data) => {
        if (data.close) {
            return;
        }
        if (!data.selectedUser){
          this.selectedTechnician = null;
          this.technician = new FormControl('Todos');
        }
        this.selectedTechnician = data.selectedUser;
        this.technician = new FormControl(`${this.selectedTechnician.name} ${this.selectedTechnician.surname}`);
    });
  }

  removeTechnician() {
    this.selectedTechnician = null;
    this.technician = new FormControl('Todos');
  }

  fetchCsv(filter) {
    if (this.requestType === REQUEST_TYPES[1]) {
      return this.apiManager.downloadReportDetailedMaterials(filter);
    }

    return this.apiManager.downloadReportAggregatedMaterials(filter);
  }

  exportData() {
    const filter: any = this.getFilter();
    this.loading = true;
    this.fetchCsv(filter).subscribe((data: any) => {
      const url= window.URL.createObjectURL(data);
      window.open(url);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
