
const TECHNOLOGIES = [
  {
    id: 'MERCURY',
    description: 'Mercurio'
  },
  {
    id: 'SODIUM',
    description: 'Sodio'
  },
  {
    id: 'LED',
    description: 'Led'
  },
  {
    id: 'OTHER',
    description: 'Other'
  },
];
const TYPE = [
  {
    id: 'H',
    description: 'Horizontal'
  },
  {
    id: 'V',
    description: 'Vertical'
  },
  {
    id: 'A',
    description: 'Aabierta'
  },
  {
    id: 'C',
    description: 'Cerrada'
  },
  {
    id: 'HA',
    description: 'Horizontal Abierta'
  },
  {
    id: 'HC',
    description: 'Horizontal Cerrada'
  },
  {
    id: 'VA',
    description: 'Vertical Abierta'
  },
  {
    id: 'VC',
    description: 'Vertical Cerrada'
  },
];
const SPECIAL = [
  {
    id: 'LUMINARY',
    description: 'Luminaria'
  },
  {
    id: 'FAROL',
    description: 'Farol'
  },
  {
    id: 'REFLECTOR',
    description: 'Reflector'
  },
];

const ANOMALY = [
  {
    id: 'OFF',
    description: 'Apagada'
  },
  {
    id: 'DIRECT',
    description: 'Directa'
  },
];
const SWITCHEDON = [
  {
    id: 'REVEL',
    description: 'Revelador'
  },
  {
    id: 'PHOTO',
    description: 'Fotocelda'
  },
  {
    id: 'MANUAL',
    description: 'Operación manual'
  },
];

const ELECTRIC_NETWORK = [
  {
    id: 'DISTRIBUTION',
    description: 'Distribución'
  },
  {
    id: 'EXCLUSIVE',
    description: 'Exclusiva'
  },
];

const UR = [
  {
    id: 'U',
    description: 'U'
  },
  {
    id: 'R',
    description: 'R'
  },
];

const POST = [
  {
    id: 'PRIMARY',
    description: 'Primario'
  },
  {
    id: 'SECONDARY',
    description: 'Secundario'
  },
  {
    id: 'EXCLUSIVE',
    description: 'Exclusivo'
  },
]

const POST_CLASS = [
  {
    id: 'CONCRETE',
    description: 'Concreto'
  },
  {
    id: 'METALIC',
    description: 'Metálico'
  },
];

const RETILAP = [
  {
    id: 'M1',
    description: 'M1'
  },
  {
    id: 'M2',
    description: 'M2'
  },
  {
    id: 'M3',
    description: 'M3'
  },
  {
    id: 'M4',
    description: 'M4'
  },
  {
    id: 'M5',
    description: 'M5'
  },
  {
    id: 'P1',
    description: 'P1'
  },
  {
    id: 'P2',
    description: 'P2'
  },
  {
    id: 'P3',
    description: 'P3'
  },
  {
    id: 'P4',
    description: 'P4'
  },
  {
    id: 'P5',
    description: 'P5'
  },
  {
    id: 'C1',
    description: 'C1'
  },
  {
    id: 'C2',
    description: 'C2'
  },
  {
    id: 'C3',
    description: 'C3'
  },
  {
    id: 'C4',
    description: 'C4'
  },
  {
    id: 'C5',
    description: 'C5'
  },

];

const MAINTENANCE_TYPES = [
  {
    id: 'CORRECTIVE',
    description: 'Mantenimiento Correctivo'
  },
  {
    id: 'PREVENTIVE',
    description: 'Mantenimiento Preventivo'
  },
  {
    id: 'OTHER',
    description: 'Actividades adicionales'
  },
];
const ONLY_CORRECTIVE_PERMISSIONS = ['DRIVER', 'TECHNICIAN'];
const CORRECTIVE = {
  id: 'CORRECTIVE',
  description: 'Mantenimiento Correctivo'
};

const EXPANSION = {
  id: 'EXPANSION',
  description: 'Expansión'
};

const TYPES = [
  CORRECTIVE,
  {
    id: 'PREVENTIVE',
    description: 'Mantenimiento Preventivo'
  },
  {
    id: 'MODERNIZATION',
    description: 'Modernización'
  },
  EXPANSION,
  {
    id: 'OTHER',
    description: 'Actividades adicionales'
  },
];

const PAIN_TYPES = [
  {
    id: 'NO_POWEROFF',
    description: 'Encendida las 24 horas'
  },
  {
    id: 'INTERMITENT',
    description: 'Presenta apagados intermitentes'
  },
  {
    id: 'OFF',
    description: 'Apagada'
  },
  {
    id: 'DISCONNECTED',
    description: 'Desconectada'
  },
  {
    id: 'NO_LUMINARY',
    description: 'No tiene luminaria'
  },
  {
    id: 'OTHER',
    description: 'Otros'
  }
];

const INSTALLATION_ZONES = [
  {
    id: 'URBAN',
    description: 'Urbana'
  },
  {
    id: 'RURAL',
    description: 'Rural'
  }
];

const SPACE_TYPES = [
  {
    id: 'VEHICULAR',
    description: 'Vía vehicular'
  },
  {
    id: 'PARK',
    description: 'Parque'
  },
  {
    id: 'PATH',
    description: 'Sendero peatonal'
  },
  {
    id: 'SPORT',
    description: 'Escenario deportivo'
  },
];

const ELECTRICAL_NETWORK_MATERIAL_TYPES = [
  {
    id: 'AL',
    description: 'Aluminio'
  },
  {
    id: 'CU',
    description: 'Cobre'
  },
]
export const TYPES_MATERIAL = [
  {
    id: 'INSTALLED',
    description: 'Instalados'
  },
  {
    id: 'RETIRED',
    description: 'Retirados'
  },
];

export const TRANSFORMER_INSTALLATION_TYPES = [
  {
    id: 'AIR',
    description: 'Aéreo'
  },
  {
    id: 'PED',
    description: 'Pedestal'
  },
  {
    id: 'SUB',
    description: 'Subterráneo'
  },
];

export const POWER_NETWORK_INSTALLATION_TYPES = [
  {
    id: 'AIR',
    description: 'Aérea'
  },
  {
    id: 'SUB',
    description: 'Subterránea'
  },
];

export const USE_CANALIZATION_TYPES = [
  {
    id: 'E',
    description: 'Exclusiva'
  },
  {
    id: 'S',
    description: 'Compartida'
  },
];

export const USE_TRANSFOMER_TYPES = [
  {
    id: 'E',
    description: 'Exclusiva'
  },
  {
    id: 'D',
    description: 'Distribución'
  },
];

export const PIPE_TYPES = [
  {
    id: 'PVC',
    description: 'PVC'
  },
  {
    id: 'EMC',
    description: 'EMC'
  },
  {
    id: 'IMC',
    description: 'IMC'
  },
];

export const PIPE_ZONE_TYPES = [
  {
    id: 'GREEN',
    description: 'Zona verde'
  },
  {
    id: 'HARD',
    description: 'Zona dura'
  },
  {
    id: 'VEHICULAR',
    description: 'Zona de cruce vehicular'
  },
];

export const ADD_MATERIAL_ACTIVITY = {
  id: 'ADD_MATERIAL',
  description: 'Instalación de material',
};
export const EXTRACT_MATERIAL_ACTIVITY = {
  id: 'EXTRACT_MATERIAL',
  description: 'Retirada de material',
};
export const CONNECTIONS_ADJUST = {
  id: 'CONNECTIONS_ADJUST',
  description: 'Ajuste de conexiones',
};
export const CLEAN_ELECTRONIC = {
  id: 'CLEAN_ELECTRONIC',
  description: 'Limpieza de óptica',
};
export const CLEAN_OPTIC = {
  id: 'CLEAN_OPTIC',
  description: 'Limpieza de elementos electrónicos',
};
export const ACTIVITY_TYPES = [ CONNECTIONS_ADJUST, CLEAN_ELECTRONIC, CLEAN_OPTIC ];

export default {
  POWER_NETWORK_INSTALLATION_TYPES,
  ELECTRICAL_NETWORK_MATERIAL_TYPES,
  TRANSFORMER_INSTALLATION_TYPES,
  USE_CANALIZATION_TYPES,
  USE_TRANSFOMER_TYPES,
  PIPE_TYPES,
  PIPE_ZONE_TYPES,
  ONLY_CORRECTIVE_PERMISSIONS,
  CORRECTIVE,
  EXPANSION,
  TYPES,
  PAIN_TYPES,
  POST_CLASS,
  POST,
  UR,
  ELECTRIC_NETWORK,
  SWITCHEDON,
  ANOMALY,
  SPECIAL,
  TYPE,
  TECHNOLOGIES,
  RETILAP,
  INSTALLATION_ZONES,
  MAINTENANCE_TYPES,
  SPACE_TYPES
}
