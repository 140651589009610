import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fromEvent } from 'rxjs';

const MAX_DISTANCE = 40;
const OFFSET = 5;
const DELAY_TRANSFORM = 600;
@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  selector: 'pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.css']
})
export class PullToRefreshComponent implements OnInit {

  touchstart$ = fromEvent<TouchEvent>(document, 'touchstart')
  touchend$ = fromEvent<TouchEvent>(document, 'touchend')
  touchmove$ = fromEvent<TouchEvent>(document, 'touchmove')
  startY;
  distance = 0;
  mode = 'determinate';
  disabled = false;
  @Input() scrollableContent;
  @Input() target;
  @Input() offsetTop;
  @Output() refresh =  new EventEmitter();
  hidden = true;
  blocked = true;
  constructor(
    private zone: NgZone
  ) {
  }

  get value() {
    return 100 * (this.distance/MAX_DISTANCE);
  }

  ngOnInit(): void {
    this.touchstart$.subscribe((data) => {
      if (this.disabled || this.blocked) {
        return;
      }
      this.startY = data.changedTouches[0].clientY;
      this.target.style.transition = '';
      this.zone.run(() => {
        this.mode = 'determinate';
      });
    });
    this.touchend$.subscribe((data) => {
      this.blocked = false;
      if (this.disabled && this.scrollableContent.scrollTop !== 0) {
        return;
      }
      this.startY = undefined;
      if (this.distance >= MAX_DISTANCE) {
        this.refresh.emit();
        this.disabled = true;
        this.zone.run(() => {
          this.mode = 'indeterminate';
        });
        setTimeout( () => {
          this.zone.run(() => {
            this.hidden = true;
            this.disabled = false;
          });
          this.target.style.transform = `translateY(0px)`;
          this.target.style.transition = '0.2s ease-in 0s';
        }, DELAY_TRANSFORM);
      } else {
        this.target.style.transform = `translateY(0px)`;
        this.target.style.transition = '0.2s ease-in 0s';
      }
      this.zone.run(() => {
        this.distance = 0;
      });
    });
    this.touchmove$.subscribe((data) => {
      if (this.disabled || this.blocked) {
        return;
      }
      this.blocked = this.scrollableContent.scrollTop !== 0;
      if (this.blocked) {
        return;
      }
      const distance = data.changedTouches[0].clientY - this.startY;

      if (distance < 0) {
        this.startY = data.changedTouches[0].clientY;
        this.zone.run(() => {
          this.distance = 0;
        });
      } else if (distance >= MAX_DISTANCE) {
        this.distance = MAX_DISTANCE;
      } else {
        this.zone.run(() => {
          this.hidden = false;
          this.distance = distance;
        });
      }
      this.zone.run(() => {
        this.target.style.transform = `translateY(${this.distance}px)`;
      });
    });
  }

}
