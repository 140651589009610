import { loadLuminaries } from './../list-luminaries/list-luminaries.actions';
import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDrawerContainer, MatDrawerContent, MatSidenavModule } from '@angular/material/sidenav';
import { LoadingComponent } from '@ildes/basic/loading/loading.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { ProjectSelectorComponent } from "../../basic/project-selector/project-selector.component";
import { InputPlaceComponent } from '@ildes/basic/input-place/input-place.component';
import { StorageService } from '@ildes/services/storage-service.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastService } from '@ildes/services/toast.service';

const FILTER = {
  id: 'options',
  icon: 'settings',
  description: 'Ver filtros'
};

const CLOSE = {
  icon: 'close',
  id: 'cancel',
  description: 'Volver',
  disabled: false,
};

const config = {
  led: 'darkgreen',
  sodio: 'blue',
  'metal halide': 'yellow',
  900: '_MarkerA',
  35: '_MarkerB',
  70: '_MarkerC',
  60: '_MarkerD',
  50: '_MarkerE',
  23: '_MarkerF',
  10: '_MarkerG',
  30: '_MarkerH',
  500: '_MarkerI',
  700: '_MarkerJ',
  250: '_MarkerK',
  80: '_MarkerL',
  800: '_MarkerM',
  150: '_MarkerN',
  400: '_MarkerO',
  175: '_MarkerP',
  90: '_MarkerQ',
  unknownTechnology: 'red',
  unknownPower: '_MarkerZ',
};
@Component({
    selector: 'app-luminary-map',
    standalone: true,
    imports: [
      CommonModule,
      GoogleMapsModule,
      LoadingComponent,
      SimpleHeaderComponent,
      MatSidenavModule,
      MatCheckboxModule,
      ReactiveFormsModule,
      MatButtonModule,
      ProjectSelectorComponent,
      InputPlaceComponent,
      MatProgressBarModule
    ],
    template: `
    <div class="container">
        <simple-header title="Luminarias" [actions]="groupActions" (action)="onClickHeaderAction($event)"></simple-header>

        <div class="content">
          <mat-drawer-container from="right" class="drawer-container" autosize>
            <mat-drawer #drawer class="sidenav" mode="over" position="end" class="padding-8 menu-container">
              <div *ngFor="let typeTechAndPower of typesTechAndPower" class="flex padding-8 center">
                <div class="column">
                  <img [src]="typeTechAndPower.marker">
                </div>
                <div class="column technology padding-8">
                  {{typeTechAndPower.technology}}
                </div>
                <div class="column padding-8">
                  {{typeTechAndPower.power}}
                </div>
                <div class="column padding-8">
                  {{typeTechAndPower.qty}}
                </div>
                <div class="column padding-8">
                 <mat-checkbox [formControl]="typeTechAndPower.checked"></mat-checkbox>
                </div>

              </div>
              <div class="container-button">
                <button color="primary" (click)="refresh()" mat-flat-button>Actualizar</button>
              </div>
            </mat-drawer>
            <div class="wrapper">
              <div>
                <app-project-selector [formControl]="projectForm" (selected)="onSelecteProject($event)"></app-project-selector>
              </div>
              <div>
                <input-place
                  [hideGeopositionDetail]="true"
                  label="Ubicación"
                  (selectPlace)="onSelectPlace($event)"
                  placeholder="CLL 33 45"
                  dark
                  [formControl]="place"
                  appearance="fill"></input-place>
              </div>
              <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
              <google-map
                height="100%"
                width="100%"
                [zoom]="17"
                [center]="center"
                class="flex-1"
              >
                <map-marker
                  #markerElem="mapMarker"
                  *ngFor="let marker of markers"
                  [position]="marker.position"
                  [info]="marker.info"
                  [label]="marker.label"
                  [icon]="marker.icon"
                  [options]="marker.options"
                  (mapClick)="openInfo(markerElem, marker.luminary)"
                >
                </map-marker>
                <map-info-window class="map-info-window">
                  <ul *ngIf="infoContent">
                    <li>
                      Número: {{infoContent.number}}
                    </li>
                    <li>
                      Dirección: {{infoContent.address}}
                    </li>
                    <li>
                      Tecnología: {{infoContent.technology}}
                    </li>
                    <li>
                      Potencia: {{infoContent.power}}
                    </li>
                  </ul>

                </map-info-window>
              </google-map>
            </div>


          </mat-drawer-container>
        </div>
    </div>
    `,
    styleUrls: ['./luminary-map.component.css'],
})
export class LuminaryMapComponent implements OnInit {
  place = new FormControl();
  projectForm = new FormControl();
  config = config;
  groupActions = [FILTER, CLOSE];
  zoom = 12
  center;
  loading = false;
  markers = [];
  typesTechAndPower;
  allTokensFiltered;
  infoContent;
  @ViewChild('drawer') menu: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    mapTypeId: 'roadmap',
    minZoom: 8,
    styles: [
      {
          featureType: "poi",
          elementType: "labels",
          stylers: [
                { visibility: "off" }
          ]
      }
    ]
  };
  data;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  constructor(
    private apiManager: ApiManagerService,
    private location: Location,
    public storageService: StorageService,
    public toast: ToastService,
  ) {
  }
  ngOnInit(): void {
    this.apiManager.listByTechnologyAndPower().subscribe((data: any) => {
      this.typesTechAndPower = data.data;
      this.typesTechAndPower.forEach((data) => {
        data.marker = this.getMarker(data);
        data.checked = new FormControl(true);
      })
    });
    const project = this.storageService.getItem('defaultProject');
    this.fetchLuminaries(project?.id);
    if (project) {
      this.projectForm.setValue({ selected: project, typed: project.name });
    }
  }

  getMarker(data) {
    return 'assets/icons/markers/' + (
      (config[data.technology] ? config[data.technology] : config['unknownTechnology']) +
      (config[data.power] ? config[data.power] : config['unknownPower'])
    ) +'.png';
  }

  onSelecteProject(project) {
    this.storageService.setItem('defaultProject', {id: project.id, name: project.name});
    this.fetchLuminaries(project.id)
  }

  fetchLuminaries(projectId = undefined) {
    if (!projectId) {
      return;
    }
    this.loading = true;
    this.apiManager.getBulkLuminaries({ projectId }).subscribe((data:any) => {
      if (!data?.data?.length) {
        this.loading = false;
        this.toast.show('No existen datos', 'advise');

        return;
      }
      this.center = {
        lat: data.data[0].geolocation.coordinates[1],
        lng: data.data[0].geolocation.coordinates[0]
      };
      this.data = data.data;

      const generador = this.performMarker();
      while (true) {
        const marker = generador.next();

        if (marker.done) {
          break;
        }
        if (!marker.value.position.lat) {
          continue;
        }
        this.markers.push(marker.value);
      }

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }


  refresh() {
    this.allTokensFiltered = this.getCheckedTokens();

    const generador = this.hideMarker();
    while (true) {
      const marker = generador.next();

      if (marker.done) {
        break;
      }
    }

    this.menu.close();

  }

  isShowed(luminary) {
    const token = `${luminary.technology?.toLowerCase()}#${luminary.power}`;

    return this.allTokensFiltered.indexOf(token) !== -1;
  }

  getCheckedTokens() {
    return this.typesTechAndPower
      .filter(({checked}) => checked.value)
      .map((luminaryFilter) => `${luminaryFilter.technology?.toLowerCase()}#${luminaryFilter.power}`)
  }

  *performMarker(): Generator<any> {
    let i = 0;
    while (i < this.data.length) {
        const luminary = this.data[i];
        const geoposition = luminary.geolocation?.coordinates || [];
        const technology = luminary.technology?.toLowerCase();
        const power = luminary.power;
        i++;
        yield {
          luminary,
          position: {
            lat: geoposition[1],
            lng: geoposition[0],
          },
          options: {
            visible: true
          },
          // label: {
          //   color: technology === 'led' ? 'blue': 'red',
          // },
          icon: this.getMarker({technology, power}),
          // title: 'Marker title ' + (this.markers.length + 1),
          info: 'Marker info ' + (this.markers.length + 1),
        }
      }
  }

  *hideMarker() {
    this.allTokensFiltered = this.getCheckedTokens();
    let i = 0;
    while (i < this.markers.length) {
      const marker = this.markers[i];
      i++;
      marker.options = {visible: this.isShowed(marker.luminary)};
      yield marker;
    }
  }

  toggleSideNav() {
    this.menu.toggle();
  }

  onClickHeaderAction(action) {
    switch(action.id) {
      case CLOSE.id:
        this.location.back();
        break;
      case FILTER.id:
        this.toggleSideNav();
        break;
    }
  }

  onSelectPlace(event) {
    console.log(this.place.value)
    const lat = this.place.value.lat;
    const lng = this.place.value.lng;
    this.center = {
      lat,
      lng
    }
  }

  openInfo(marker: MapMarker, content) {
    this.infoContent = content;
    this.infoWindow.open(marker)
  }
}
