<input autocomplete="off" class="overlay" type="file" #inputfile (change)="selectFile($event)">
<div class="container">
  <div class="scrollable-content">
  <simple-header title="Proyectos" [back]="true" icon="close"></simple-header>
  <form [formGroup]="searchFormGroup">
    <mat-form-field appearance="outline" class="full-width" *ngIf="!humanizeFilter?.length">
      <mat-label>Buscar por nombre</mat-label>
      <input matInput formControlName="projectName" (keyup.enter)="findByName()">
    </mat-form-field>
  </form>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="inline-flex" *ngIf="!loading">
    <filter-list *ngIf="humanizeFilter?.length" [criterias]="humanizeFilter"></filter-list>
    <button *ngIf="humanizeFilter?.length" mat-button (click)="removeFilters()">Eliminar filtros</button>
  </div>
  <app-image-panel class="margin-top-16" text="No existen proyectos" *ngIf="!projects?.length && !loading"></app-image-panel>
  <div *ngFor="let project of projects" class="item">
    <list-project-item
      (addParticipantsClicked)="addParticipants(project)"
      (onSelect)="gotoDetail(project)"
      (doAction)="doAction($event, project)"
      [actions]="actions"
      [name]="project.name"
      [prefix]="project.prefix"
      [startDate]="project.minutesDate"
      [endDate]="project.minutesDate | calcEndDate:project.duration"
      [contractType]="project.contractType"
      [contractNumber]="project.contractNumber"
      [participants]="project.participants"
    ></list-project-item>
    <mat-divider></mat-divider>
  </div>
  <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/list-projects/create']"><mat-icon>add</mat-icon></button>
  </div>
  <mat-paginator
    [length]="pagination?.totalDocs"
    pageSize="20"
    (page)="paginate($event)">
  </mat-paginator>
</div>
