import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { AllowActionDirective } from '@ildes/directives/allow-action.directive';

@Component({
    selector: 'app-reporting-distributive',
    standalone: true,
    imports: [
        CommonModule,
        SimpleHeaderComponent,
        MatIconModule,
        MatListModule,
        AllowActionDirective,
        RouterModule,
    ],
    template: `<div class="container">
    <div class="scrollable-content">
      <simple-header title="Panel de control" [back]="true" icon="close"></simple-header>
      <mat-action-list>
        <button mat-list-item [routerLink]="['/app/reports/orders']" [allowAction]="['maintenances.list']">
          <mat-icon>build</mat-icon> <span class="padding-left-16">Órdenes y mantenimientos</span>
        </button>
        <button mat-list-item [routerLink]="['/app/reports/materials']" [allowAction]="['maintenances.list']">
          <mat-icon>build</mat-icon> <span class="padding-left-16">Materiales</span>
        </button>
        <button mat-list-item [routerLink]="['/app/reports/pqrs']" [allowAction]="['pqrs.list']">
          <mat-icon>build</mat-icon> <span class="padding-left-16">PQRS</span>
        </button>
      </mat-action-list>
    </div>
  </div>
  `,
    styleUrls: ['./reporting-distributive.component.css']
})
export class ReportingDistributiveComponent { }
