<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<pull-to-refresh [scrollableContent]="scrolledContent" [target]="container" (refresh)="refresh()" ></pull-to-refresh>
<div class="container" #container>
  <div class="scrollable-content" #scrolledContent>
    <app-alert-dialog
      (click)="enrollFingerprint()"
      *ngIf="!loading && (!availableBiometry || !fingerprintEnabled)"
      [text]="fingerprintAlertText"
      [linkText]="availableBiometry && !fingerprintEnabled ? 'Activar huella': undefined"
      status="advise"></app-alert-dialog>
    <app-alert-dialog
      *ngIf="!loading && !coworker"
      class="margin-top-8"
      (click)="configureGroup()"
      text="No has seleccionado un compañero de trabajo."
      linkText="Seleccionar compañero"
      status="advise"></app-alert-dialog>
    <!-- <div class="inline-flex padding-top-16 padding-bottom-16">
      <div class="column padding-right-16 align-center border-right">
        <h3>Mantenimientos del día</h3>
        <div>
          {{stats?.todayRepairmentMaintenances}} completados<br>
          {{stats?.maintenancesInProgress}} en curso<br>
        </div>
      </div>
      <div class="column padding-left-16 align-center">
        <h3>Mantenimientos del mes</h3>
        <div>
          {{stats?.monthRepairmentMaintenances}} completados<br>
          {{stats?.pendingMaintenances}} pendientes<br>
        </div>
        <div>
        </div>
      </div>
    </div> -->
    <div class="wrapper-with-badge margin-top-8" *ngIf="numElementsPendingSync > 0">
      <div role="button" matRipple (click)="openPendingSyncMaintenancesDialog()" class="actionable wrapper-text">
        <mat-icon>cloud_off</mat-icon>
        <span class="text">Datos sin sincronizar</span>
      </div>
      <span [matBadge]="numElementsPendingSync" matBadgeOverlap="false" color="primary"></span>
    </div>
      <div class="filter-container">
        <mat-button-toggle-group (change)="setMaintenanceFilter($event)" >
          <mat-button-toggle [checked]="true" value="NEW">Pendientes</mat-button-toggle>
          <mat-button-toggle value="IN_PROGRESS">En curso</mat-button-toggle>
          <mat-button-toggle value="CLOSED">Cerrados</mat-button-toggle>
          <mat-button-toggle value="">Todos</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loadingMaintenances"></mat-progress-bar>
      <app-image-panel class="margin-top-16" *ngIf="!maintenances?.length && !loadingMaintenances"></app-image-panel>
      <div *ngFor="let maintenance of maintenances" class="item">
        <list-maintenance-item
          [parent]="maintenance.parent"
          (onClickAction)="doAction(maintenance, $event)"
          (onLinkClick)="navigate(maintenance, $event)"
          [selector]="selector"
          [selected]="maintenance.selected"
          [state]="maintenance.state"
          [actions]="!loading && (actions | filterActionsMaintenance:maintenance)"
          [orderno]="maintenance.orderno"
          [type]="maintenance.type"
          [luminaryNumber]="maintenance.oldLuminaryNumber || maintenance.luminary?.number"
          [newLuminaryNumber]="maintenance.newLuminaryNumber"
          [newTechnology]="maintenance.newTechnology"
          [newPower]="maintenance.newPower"
          [project]="maintenance.project?.name || 'Sin proyecto'"
          [luminaryOfTemporalUse]="maintenance.luminaryOfTemporalUse"
          [address]="maintenance.address"
          [neighborhood]="maintenance.neighborhood"
          [referencePoint]="maintenance.locationReferenceDescription"
          [painType]="maintenance.painType"
          [painDescription]="maintenance.painDescription"
          [technician]="maintenance.technician"
          [participants]="maintenance.participants"
          [power]="maintenance.oldPower"
          [technology]="maintenance.oldTechnology"
          [estimatedResolutionDate]="maintenance.estimatedResolutionDate"
          [repairmentDate]="maintenance.repairmentDate"
          [pendingSync]="maintenance.pendingSync"
          [materials]="maintenance.materials"
        ></list-maintenance-item>
        <mat-divider></mat-divider>
      </div>
      <mat-paginator
        *ngIf="maintenances?.length"
        [pageIndex]="currentPage"
        [length]="pagination?.totalDocs"
        pageSize="20"
        (page)="paginate($event)">
      </mat-paginator>
  </div>
</div>
<button mat-fab color="primary" class="fab-button" [routerLink]="['/app/list-maintenances/create']"><mat-icon>add</mat-icon></button>
