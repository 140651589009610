import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ContextualActionComponent } from '../contextual-action/contextual-action.component';
import { TagAutomaticColorComponent } from '../tag-automatic-color/tag-automatic-color.component';
import { HumanizeStateComponent } from '../humanize-state/humanize-state.component';
import { FormatDatePipe } from '@ildes/pipes/format-date.pipe';
import { MatButtonModule } from '@angular/material/button';
import { HumanizeDescriptionMaterialPipe } from '@ildes/pipes/humanize-description-material.pipe';
import { ListLuminaryItemComponent } from '../list-luminary-item/list-luminary-item.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  imports: [
    MatCheckboxModule,
    HumanizeDescriptionMaterialPipe,
    CommonModule,
    MatIconModule,
    ContextualActionComponent,
    TagAutomaticColorComponent,
    HumanizeStateComponent,
    FormatDatePipe,
    MatButtonModule,
    ListLuminaryItemComponent
  ],
  selector: 'list-maintenance-item',
  templateUrl: './list-maintenance-item.component.html',
  styleUrls: ['./list-maintenance-item.component.css']
})
export class ListMaintenanceItemComponent implements OnInit {

  @Input() state;
  @Input() orderno;
  @Input() type;
  @Input() luminaryNumber;
  @Input() newLuminaryNumber;
  @Input() newTechnology;
  @Input() newPower;
  @Input() luminaryOfTemporalUse;
  @Input() project;
  @Input() address;
  @Input() neighborhood;
  @Input() referencePoint;
  @Input() power;
  @Input() technology;
  @Input() materials;
  @Input() estimatedResolutionDate;
  @Input() painType;
  @Input() painDescription;
  @Input() selector;
  @Input() actions;
  @Input() selected;
  @Input() technician;
  @Input() repairmentDate;
  @Input() pendingSync;
  @Input() omitDefaultEventBehaviour;
  @Input() participants;
  @Input() parent;
  @Input() pqr;

  @Output() onClickAction =  new EventEmitter();
  @Output() onChangeSelected =  new EventEmitter();
  @Output() onLinkClick =  new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  linkClicked() {
    // if(!this.omitDefaultEventBehaviour) {
    //   this.onClickAction.emit(this.actions[0]);
    // } else {
    //   this.onClickAction.emit();
    // }

    this.onLinkClick.emit();
  }

  onClickActionEvent(action) {
    this.onClickAction.emit(action);
  }

  onChange(event) {
    this.onChangeSelected.emit(event)
  }

  private isPendingSync(pendingSync) {
    return (Object.keys(pendingSync || { })).find((key) => {
      return pendingSync[key];
    });
  }

  get computePendingSync() {
    return this.isPendingSync(this.pendingSync);
  }

  get contactMedia() {
    return this.petitioner?.mobilePhone || this.petitioner?.phone || this.petitioner?.otherContact;
  }

  get petitioner() {
    return this.pqr?.petitioner;
  }

}
