<div class="container">
  <div class="scrollable-content">
    <simple-header (action)="headerAction($event)" [actions]="headerActions" [title]="title" icon="close"></simple-header>
    <form [formGroup]="questionForm">
      <div class="item" *ngFor="let question of questions">
        <div class="inline-flex padding-top-16 padding-bottom-16">
          <div class="column">
            {{question.title}}
          </div>
          <div class="">
            <mat-slide-toggle [disabled]="!!readOnly" [formControlName]="question.id"></mat-slide-toggle>
          </div>
        </div>
        <mat-form-field appearance="outline" class="full-width" *ngIf="question.observations?.show && !questionForm.controls[question.id].value">
          <textarea [formControlName]="'observation_' + question.id" [placeholder]="question.observations?.placeholder" rows="1" maxlength="35" matInput></textarea>
        </mat-form-field>
        <mat-divider></mat-divider>
      </div>
    </form>
    <div class="align-center padding-top-8">
      <button mat-raised-button color="primary" (click)="close(true)" class="button-size-200">{{buttonText}}</button>
    </div>
  </div>
</div>
