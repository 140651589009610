import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'key-value-item',
  templateUrl: './key-value-item.component.html',
  styleUrls: ['./key-value-item.component.css']
})
export class KeyValueItemComponent implements OnInit {

  @Input() key;
  @Input() value;
  constructor() { }

  ngOnInit(): void {
  }

}
