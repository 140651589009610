import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import constants from '@ildes/config/constants';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SelectPhotoDialogComponent } from '@ildes/basic/select-photo-dialog/select-photo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/list-luminaries/list-luminaries.actions';
import { ToastService } from '@ildes/services/toast.service';

@Component({
  selector: 'app-create-luminaires',
  templateUrl: './create-luminaires.component.html',
  styleUrls: ['./create-luminaires.component.css']
})
export class CreateLuminairesComponent implements OnInit {

  @Input() dialogMode = false;
  @Input() luminary;
  @Input() project;
  @Output() saveLuminary = new EventEmitter();
  @ViewChild('stepper') stepper: any;

  photos = [];
  retilap = constants.RETILAP;
  ur = constants.UR
  technologies = constants.TECHNOLOGIES;
  types = constants.TYPE;
  special = constants.SPECIAL;
  anomaly = constants.ANOMALY;
  switchedon = constants.SWITCHEDON;
  electricNetwork = constants.ELECTRIC_NETWORK;
  installationZones = constants.INSTALLATION_ZONES;
  post = constants.POST;
  postClass = constants.POST_CLASS;
  selectedUr = null;
  selectedTechnology;
  selectedType = null;
  selectedSpecial = null;
  selectedAnomaly = null;
  selectedSwitchedon = null;
  selectedElectricNetwork = null;
  selectedPostType = null;
  selectedPost = null;
  selectedPostClass = null;
  selectedRetilap = null;
  selectedNeighborhood;
  selectedInstallationZone = null;
  particular = false;
  loading;
  selectedMaker;
  selectedModel;
  selectedSpaceType;
  spaceTypes = constants.SPACE_TYPES;
  electricalNetworkMaterialTypes = constants.ELECTRICAL_NETWORK_MATERIAL_TYPES;
  selectedElectricalNetworkMaterialType;
  powerNetworkInstallationTypes = constants.POWER_NETWORK_INSTALLATION_TYPES;
  selectedElectricalNetworkInstallationType;
  useTransformerTypes = constants.USE_TRANSFOMER_TYPES;
  selectedUseTransfromerType;
  transformerInstallationTypes = constants.TRANSFORMER_INSTALLATION_TYPES;
  selectedTransformerInstallationType;

  useCanalizationTypes = constants.USE_CANALIZATION_TYPES;
  selectedUseCanalizationType;
  pipeTypes = constants.PIPE_TYPES;
  selectedPipeType;
  pipeZoneTypes = constants.PIPE_ZONE_TYPES;
  selectedPipeZoneType;
  inspectionBox;

  searchLuminaryTypesAdapter;
  searchMakerTypesAdapter;
  searchModelAdapter;
  searchNeighborhoodAdapter;
  searchProjectAdapter;

  unkownTechnology;

  numberState;
  projectState;

  firstFormGroup = new UntypedFormGroup({
    selectedProjectForm: new UntypedFormControl(),
    technologyForm: new UntypedFormControl(),
    number: new UntypedFormControl(''),
    power: new UntypedFormControl('', [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
    location: new UntypedFormControl(''),
    neighborhoodForm: new UntypedFormControl(),
    modelForm: new UntypedFormControl(),
    pathObservations: new UntypedFormControl(),
    makerForm: new UntypedFormControl()
  });
  secondFormGroup = new UntypedFormGroup({
    observations: new UntypedFormControl(''),
    postNumber: new UntypedFormControl(),
    postHeight: new UntypedFormControl(),
  });

  thirdFormGroup = new UntypedFormGroup({
    transformerNumber: new UntypedFormControl(),
    conductorGauge: new UntypedFormControl(),
  });

  constructor(
    private store: Store<AppState>,
    public apiManager: ApiManagerService,
    private dialog: MatDialog,
    private location: Location,
    private toast: ToastService,
  ) { }

  get projectTyped() {
    return this.firstFormGroup.get('selectedProjectForm')?.value?.typed;
  }

  get selectedProject() {
    return this.firstFormGroup.get('selectedProjectForm')?.value?.selected;
  }

  get technology() {
    return this.firstFormGroup.get('technologyForm')?.value?.typed;
  }

  get neighborhood() {
    return this.firstFormGroup.get('neighborhoodForm')?.value?.typed;
  }

  get maker() {
    return this.firstFormGroup.get('makerForm')?.value?.typed;
  }

  get model() {
    return this.firstFormGroup.get('modelForm')?.value?.typed;
  }

  get number() {
    return this.firstFormGroup.get('number')?.value;
  }

  get power() {
    return this.firstFormGroup.get('power')?.value;
  }

  get ubication() {
    return this.firstFormGroup.get('location')?.value
  }

  get observations() {
    return this.secondFormGroup.get('observations')?.value
  }

  get postNumber() {
    return this.secondFormGroup.get('postNumber')?.value
  }

  get address() {
    return this.ubication?.typed;
  }

  get latitude() {
    return parseFloat(this.ubication?.lat);
  }

  get longitude() {
    return parseFloat(this.ubication?.lng);
  }

  get selectedElectricalNetworkInstallationTypeIsSub() {
    return this.selectedElectricalNetworkInstallationType?.id === 'SUB';
  }

  get transformerNumber() {
    return this.thirdFormGroup.get('transformerNumber')?.value
  }
  get postHeight() {
    return this.secondFormGroup.get('postHeight')?.value
  }
  get conductorGauge() {
    return this.thirdFormGroup.get('conductorGauge')?.value
  }

  get pathObservations() {
    return this.firstFormGroup.get('pathObservations')?.value
  }

  ngOnInit(): void {
    const luminary:any = this.luminary || this.location.getState();

    if (Object.keys(luminary).length > 0) {
      this.initializeForm(luminary);
    }
    if (this.project) {
      this.setFormProjectData(this.project);
    }
    this.store.dispatch(
      actions.initState()
    );
    this.searchLuminaryTypesAdapter = (data) => {
      return this.apiManager.getFromCatalog('technologies', data)
    };
    this.searchMakerTypesAdapter = (data) => {
      return this.apiManager.getFromCatalog('makers', data)
    };
    this.searchModelAdapter = (data) => {
      return this.apiManager.getFromCatalog('models', data)
    };
    this.searchNeighborhoodAdapter = (data) => {
      return this.apiManager.getFromCatalog('neighborhood', data)
    };
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };
  }

  setFormProjectData(project) {
    const projectName = project?.name;
    const projectId = project?.id;

    if (projectName) {
      this.projectState = true;
      let { controls } = this.firstFormGroup;
      const project = projectId;
      controls.selectedProjectForm.setValue({
        typed: projectName,
        selected: { id: project }
      });
    }
  }
  initializeForm(luminary) {
    this.numberState = luminary.prefilled && luminary.number;
    this.projectState = luminary.prefilled && luminary.projectId;
    let { controls } = this.firstFormGroup;

    if (luminary.number) {
      controls.number.setValue(luminary.number);
    }
    if(luminary.projectName) {
      const project = luminary.projectId || luminary.project;

      controls.selectedProjectForm.setValue({
        typed: luminary.projectName,
        selected: { id: project }
      });
    }
    if (luminary.technology) {
      controls.technologyForm.setValue({
        typed: luminary.technology,
        selected: luminary.technology
      });
    }
    if (luminary.maker) {
      controls.makerForm.setValue({
        typed: luminary.maker || '',
        selected: luminary.maker || null
      });
    }
    if(luminary.model) {
      controls.modelForm.setValue({
        typed: luminary.model || '',
        selected: luminary.model || null
      });
    }

    if(luminary.geolocation) {
      const [lng, lat] = luminary.geolocation.coordinates;

      controls.location.setValue({
        place: { lat, lng },
        typed: luminary.address,
        lat,
        lng
      })
    }

    controls.neighborhoodForm.setValue({
      typed: luminary.neighborhood || '',
      selected: luminary.neighborhood || null
    });

    if (luminary.power) {
      controls.power.setValue(luminary.power);
    }

    controls = this.secondFormGroup.controls;
    this.selectedRetilap = this.retilap.find((retilap) => retilap.id === luminary.retilap?.id);

    if(luminary.postNumber) {
      controls.postNumber.setValue(luminary.postNumber);
    }

    if (luminary.postHeight) {
      controls.postHeight.setValue(luminary.postHeight);
    }

    if (luminary.photos) {
      this.photos = luminary.photos;
    }
    controls.observations.setValue(luminary.observations || '');

    controls = this.thirdFormGroup.controls;

    if (luminary.conductorGauge) {
      controls.conductorGauge.setValue(luminary.conductorGauge);
    }

    if (luminary.transformerNumber) {
      controls.transformerNumber.setValue(luminary.transformerNumber);
    }

    this.selectedType = this.types.find(({ id }) => luminary.luminaryType?.id === id);
    this.selectedSpecial = this.special.find(({ id }) => luminary.special?.id === id);
    this.selectedAnomaly = this.anomaly.find(({ id }) => luminary.anomaly?.id === id);
    this.selectedSwitchedon = this.switchedon.find(({ id }) => luminary.switchedon?.id === id);
    this.selectedElectricNetwork = this.electricNetwork.find(({ id }) => luminary.electricNetwork?.id === id);
    this.selectedPostType = this.post.find(({ id }) => luminary.postType?.id === id);
    this.selectedPostClass = this.postClass.find(({ id }) => luminary.postClass?.id === id);
    this.particular = luminary.particularUse;
    this.selectedInstallationZone = this.installationZones.find(({ id }) => luminary.installationZone?.id === id);
    this.selectedSpaceType = this.spaceTypes.find(({ id }) => luminary.spaceType?.id === id);
    this.selectedElectricNetwork = this.electricNetwork.find(({ id }) => luminary.electricNetwork?.id === id);
    this.selectedElectricalNetworkMaterialType = this.electricalNetworkMaterialTypes.find(({ id }) => luminary.electricalNetworkMaterialType?.id === id);
    this.selectedElectricalNetworkInstallationType = this.powerNetworkInstallationTypes.find(({ id }) => luminary.electricalNetworkInstallationType?.id === id);
    this.selectedUseTransfromerType = this.useTransformerTypes.find(({ id }) => luminary.useTansformerType?.id === id);
    this.selectedTransformerInstallationType = this.transformerInstallationTypes.find(({ id }) => luminary.transformerInstallationType?.id === id);

    this.inspectionBox = luminary.inspectionBox;
    this.selectedUseCanalizationType = this.useCanalizationTypes.find(({ id }) => luminary.useCanalizationType?.id === id);
    this.selectedPipeType = this.pipeTypes.find(({ id }) => luminary.pipeType?.id === id);
    this.selectedPipeZoneType = this.pipeZoneTypes.find(({ id }) => luminary.pipeZoneType?.id === id);

  }

  onUnknownValue(type, value) {
    if (type === 'technology') {
      this.unkownTechnology = value;
    }
  }

  create() {
    const luminaryState:any = this.location.getState();

    const luminary = {
      photos: this.photos,
      number: this.number,
      ...this.selectedProject && {project: this.selectedProject.id},
      projectName: this.projectTyped,
      address: this.address,
      geolocation: {
        type: 'Point',
        coordinates: [this.longitude, this.latitude]
      },
      retilap: this.selectedRetilap,
      neighborhood: this.neighborhood,
      technology: this.technology,
      power: this.power,
      maker: this.maker,
      model: this.model,
      special: this.selectedSpecial,
      anomaly: this.selectedAnomaly,
      switchedon: this.selectedSwitchedon,
      electricNetwork: this.selectedElectricNetwork,
      postType: this.selectedPostType,
      postClass: this.selectedPostClass,
      particularUse: this.particular,
      luminaryType: this.selectedType,
      observations: this.observations,
      installationZone: this.selectedInstallationZone,
      postNumber: this.postNumber,
      spaceType: this.selectedSpaceType,
      transformerNumber: this.transformerNumber,
      postHeight: this.postHeight,
      electricalNetworkMaterialType: this.selectedElectricalNetworkMaterialType,
      conductorGauge: this.conductorGauge,
      electricalNetworkInstallationType: this.selectedElectricalNetworkInstallationType,
      useTansformerType: this.selectedUseTransfromerType,
      transformerInstallationType: this.selectedTransformerInstallationType,
      useCanalizationType:this.selectedUseCanalizationType,
      inspectionBox: this.inspectionBox,
      pipeType: this.selectedPipeType,
      pipeZoneType: this.selectedPipeZoneType,
      pathObservations: this.pathObservations
    };

    if (this.dialogMode) {
      this.saveLuminary.emit(luminary);

      return;
    }
    this.loading = true;

    this.apiManager.createLuminary(luminary).subscribe(() => {
      this.resetForm();
      if (luminaryState._id) {
        this.store.dispatch(
          actions.remove({
            _id: luminaryState._id
          })
        )
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;

      if (!error.code || error.code > 0) {
        if (luminaryState._id) {
          this.store.dispatch(
            actions.remove({
              _id: luminaryState._id
            })
          )
        }
        return;
      }

      this.resetForm();

      if (luminaryState._id) {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.update({ id: luminaryState._id, changes: luminary })
        )
      } else {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.create({luminary})
        )
      }

    });
  }

  selectPhoto() {
    const dialog = this.dialog.open(SelectPhotoDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog'
    });

    dialog.afterClosed().subscribe((data = { closed: false }) => {
      if (data.closed || !data.photo) {
        return;
      }
      this.photos = [...this.photos, ...[data.photo]];
    });
  }

  removePhoto(index) {
    this.photos.splice(index, 1);
  }

  resetForm() {
    // this.firstFormGroup.reset();
    // this.secondFormGroup.reset();
    const project = {
      name: this.selectedProject?.name || this.projectTyped,
      id: this.selectedProject?.id
    };
    this.stepper.reset();
    this.setFormProjectData(project);
    this.selectedRetilap = null;
    this.selectedType = null;
    this.selectedSpecial = null;
    this.selectedSwitchedon = null;
    this.selectedElectricNetwork = null;
    this.selectedAnomaly = null;
    this.selectedPostClass = null;
    this.selectedPostType = null;
    this.particular = false;
    this.photos = [];
  }
}
