
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { FileHelperService } from '@ildes/services/file-helper.service';
const MAINTENANCE = 'MAINTENANCE';
const EXPANSION = 'EXPANSION';
const MODERNIZATION = 'MODERNIZATION';

@Component({
    selector: 'app-order-detail',
    template: `

    <simple-header title="Detalle de la orden" (action)="close()" icon="close"></simple-header>
    <div class="section">
      <h3>Datos de la orden</h3>
      <p class="padding-top-8">
        <strong>Número de orden: </strong> {{this.data.orderno}}
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Proyecto: </strong> {{this.data.project.name}}
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Estado: </strong> <humanize-state [state]="this.data.state"></humanize-state>
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Descripción: </strong> {{this.data.painDescription || this.data.orderDescription || 'No informado'}}
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Técnico asignado: </strong>
        <span *ngIf="this.data.technician">{{this.data.technician.name}} {{this.data.technician.surname}}</span>
        <span *ngIf="!this.data.technician"><i>Sin asignar</i></span>
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Fecha de apertura: </strong> {{this.data.createdAt | formatDate}}
      </p>
      <mat-divider *ngIf="this.data.estimatedResolutionDate"></mat-divider>
      <p class="padding-top-8" *ngIf="this.data.estimatedResolutionDate">
        <strong>Fecha estimada: </strong>  {{this.data.estimatedResolutionDate | formatDate}}
      </p>
    </div>

    <div class="section margin-top-16" *ngIf="data.state === 'CLOSED'">
      <h3>Datos de ejecución y cierre</h3>
      <p class="padding-top-8">
        <strong>Fecha de ejecución: </strong> {{(this.data.resolutionDate  || this.data.repairmentDate) | formatDate}}
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Método de ascenso: </strong> {{this.data.ascendMethod.description}}
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Observaciones de la ejecución: </strong> <span *ngIf="!this.data.observations">-</span>{{this.data.observations}}
      </p>
      <mat-divider></mat-divider>
      <p class="padding-top-8">
        <strong>Altura: </strong>{{this.data.height}}
      </p>
      <mat-divider></mat-divider>
      <div class="padding-top-8 padding-bottom-8">
        <strong>Material usado: </strong>
        <i *ngIf="!data.usedInventory">No se usó material</i>
        <ul *ngFor="let material of data.usedInventory">
          <li>{{material.name}}. Coste: {{material.cost}}</li>
        </ul>
      </div>
      <mat-divider></mat-divider>
      <div class="padding-top-8 padding-bottom-8">
        <strong>Material retirado: </strong>
        <i *ngIf="!data.retiredInventory">No se retiró material</i>
        <ul *ngFor="let material of data.retiredInventory">
          <li>{{material.name}}</li>
        </ul>
      </div>
      <mat-divider></mat-divider>
      <div class="padding-top-8">
        <strong>Actividades desarrolladas: </strong>
        <i *ngIf="!data.activities">No se realizó ninguna actividad</i>
        <ul *ngFor="let activity of data.activities">
          <li>{{activity.description}} <span *ngIf="activity.extraData">({{activity.extraData}})</span></li>
        </ul>
      </div>
      <div class="padding-top-8 center">
        <div class="padding-top-8">
          <button color="primary" mat-button (click)="downloadOrderPermission()">
            Descargar permiso de trabajo
          </button>
          <button *ngIf="data.orderTaskDocumentPath" color="primary" mat-button (click)="downloadOrderDetail()">
            Descargar orden de trabajo
          </button>
        </div>
      </div>
    </div>
    <div class="section margin-top-16" *ngIf="data.luminary">
      <h3> Datos de luminaria </h3>
      <list-luminary-item
        [number]="data.oldLuminaryNumber || data.luminary.number"
        [newLuminaryNumber]="data.newLuminaryNumber"
        [project]=" "
        [address]="data.luminary.address"
        [ubication]="data.luminary.geolocation"
        [neighborhood]="data.luminary.neighborhood"
        [technology]="data.oldTechnology || data.luminary.technology"
        [power]="data.oldPower || data.luminary.power"
        [newTechnology]="data.newTechnology"
        [newPower]="data.newPower"
        [luminaryOfTemporalUse]="data.luminaryOfTemporalUse"
      ></list-luminary-item>
      <h3 *ngIf="this.data.photos?.initial?.length || this.data.photos?.final?.length">Fotos</h3>
      <gallery-list *ngIf="this.data.photos?.initial?.length" [readOnly]="true" [(photos)]="this.data.photos.initial"></gallery-list>
      <gallery-list *ngIf="this.data.photos?.final?.length" [readOnly]="true" [(photos)]="this.data.photos.final"></gallery-list>
     </div>

    `,
    styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent {
  data;
  type;
  downloading;
  metadataName;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any,
    public dialogRef: MatDialogRef<OrderDetailComponent>,
    private apiManager: ApiManagerService,
    private fileHelper: FileHelperService,
  ) {

    if (data.maintenance) {
      this.type = MAINTENANCE;
      this.data = data.maintenance;
      this.metadataName = 'metadata.maintenance';
    } else if (data.expansion) {
      this.type = EXPANSION;
      this.data = data.expansion;
      this.metadataName = 'metadata.expansion';
    } else if (data.modernization) {
      this.type = MODERNIZATION;
      this.data = data.modernization;
      this.metadataName = 'metadata.modernization';
    }
  }

  close() {
    this.dialogRef.close();
  }

  downloadOrderDetail() {
    this.open(this.data.orderTaskDocumentPath);
  }


  open(fileName) {
    this.downloading = true;
    this.fileHelper.downloadPdf(fileName).subscribe(() => {
      this.downloading = false;
    }, () => {
      this.downloading = false;
    })
  }

  downloadOrderPermission() {
    this.getDocumentation().subscribe((data) => {
      this.open(data);
    });
  }

  getDocumentation() {
    return this.apiManager.getTasks({
      type: 'DOCUMENT_SIGN',
      subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
      [this.metadataName]: this.data.id
    }).pipe(
      mergeMap((taskData: any) => {
        const task = taskData.data[0];
        if (!task.usersWhoPerformTheTask?.length) {
          return [task.metadata.documentName];
        } else {
          return this.apiManager.getEvidence({ taskId: task.id })
            .pipe(
              map(({ data }: any) => data.signedDocumentName)
            )
        }
      })
    );
  }
}
