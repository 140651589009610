import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, HostBinding, ElementRef, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

const COLORS = [
  '#29b6f6',
  '#26c6da',
  '#66bb6a',
  '#ec407a',
  '#ffa726',
];


@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  selector: 'tag-automatic-color',
  templateUrl: './tag-automatic-color.component.html',
  styleUrls: ['./tag-automatic-color.component.css']
})
export class TagAutomaticColorComponent implements OnInit {

  @Input() icon;
  @Input() text;
  @Input() keyColor;

  constructor(
    private elemRef: ElementRef
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    const newVal = changes?.text?.currentValue;

    if (!newVal) {
      return;
    }

    this.elemRef.nativeElement.style.setProperty('--bg-tag-color', this.calculatedBackground(this.keyColor ||this.text ));

  }

  private calculatedBackground(text: string): string {
    const index: number = Array.from(text).reduce((res, char) =>res+char.charCodeAt(0), 0);

    return COLORS[index%COLORS.length];
  }

}
