<div class="container">
  <div class="scrollable-content">
    <simple-header title="Editar datos de poste y luminaria" [back]="true" icon="close"></simple-header>
    <div>
      <dropdown-selector class="column" label="Tipo de canalización" [types]="useCanalizationTypes" [(selected)]="selectedUseCanalizationType"></dropdown-selector>
        <div class="margin-bottom-16">
          <mat-checkbox [(ngModel)]="inspectionBox" [ngModelOptions]="{standalone: true}">Caja de inspección</mat-checkbox>
        </div>
        <dropdown-selector class="column" label="Tipo de ductería" [types]="pipeTypes" [(selected)]="selectedPipeType"></dropdown-selector>
        <dropdown-selector class="column" label="Tipo de zona de ductería" [types]="pipeZoneTypes" [(selected)]="selectedPipeZoneType"></dropdown-selector>
      <div class="align-center">
        <div class="padding-bottom-8">
          <button (click)="save()" [disabled]="loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
        </div>
        <div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>
