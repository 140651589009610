import { BehaviourAdapter } from './../predictive-searcher/behaviour-adapter';
import {  NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, forwardRef } from '@angular/core';
import { PredictiveSearcherComponent } from '../predictive-searcher/predictive-searcher.component';

@Component({
    selector: 'app-project-selector',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PredictiveSearcherComponent
    ],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => ProjectSelectorComponent),
      },
    ],
    template: `
      <predictive-searcher
        (selectedValue)="optionSelected($event)"
        label="Proyecto"
        [formControl]="form"
        [service]="adapter">
      </predictive-searcher>`,
    styleUrls: ['./project-selector.component.css']
})

export class ProjectSelectorComponent extends BehaviourAdapter implements OnInit {

  @Output() selected = new EventEmitter();
  constructor(
    private apiManager: ApiManagerService,
  ) {
    super();
    this.adapter = (data) => {
      return this.apiManager.listProjects(data);
    };
  }

  ngOnInit(): void {
    this.subscribe();
  }

  optionSelected(event){
    this.selected.emit(event.option.value)
  }

}
