import { humanizeDate } from "../../utils/formatters";

function getProp( object, keys, defaultVal ){
  keys = Array.isArray( keys )? keys : keys.split('.');
  object = object[keys[0]];
  if( object && keys.length>1 ){
    return getProp( object, keys.slice(1), defaultVal );
  }
  return object === undefined? defaultVal : object;
}
const REQUEST_TYPES = {
  'SPECIAL': 'D.P. Especiales', // deprecado
  'TAX': 'Impuesto alumbrado público',
  'AP_SERVICES': 'D.P. Servicios A.P.', // deprecado
  'ENERGY_SERVICES': 'D.P. Servicios Energía', // deprecado
  'MAINTENANCE': 'Mantenimiento',
  'EXPANSION': 'Expansión',
  'OTHERS': 'Otros',
};
const ACCOUNTABLE_SECTION_TYPES = {
  'OPERATION': 'Operación',// deprecado
  'JURIDIC': 'Jurídica',// deprecado
  'THIRD': 'Terceros',// deprecado
  'OPERATIVE': 'Operativa',
  'ADMINISTRATIVE': 'Administrativa',
};

const STATES = {
  NEW: 'Abierto',
  COMPLETED: 'Cerrado',
};

const RESPONSE_TYPES = {
  OPERATION: 'Minuta de Operación',
  EXPANSION: 'Minuta de Expansión',
  THIRD: 'Minuta de Remisión a Terceros',
  IAP: 'Minuta de IAP',
  OTHERS: 'Otros',
};

const columns = {
  'projectName': {
    name: 'Proyecto',
    path: 'project.name',
  },
  'pqr': {
    name: 'Número de pqr',
    path: 'orderno',
  },
  'createdAt': {
    name: 'Fecha de creación',
    parse: (pqr) => {
      if (!pqr.createdAt) {
        return '';
      }
      return humanizeDate(pqr.createdAt, true);
    }
  },
  'state': {
    name: 'Estado',
    parse: (pqr) => STATES[pqr.state],
  },
  'requestType': {
    name: 'Tipo de solicitud',
    parse: (pqr) => REQUEST_TYPES[pqr.requestType],
  },
  'accountableSection': {
    name: 'Área encargada',
    parse: (pqr) => ACCOUNTABLE_SECTION_TYPES[pqr.accountableSection],
  },
  'cedula': {
    name: 'Cédula',
    path: 'petitioner.identification'
  },
  'name': {
    name: 'Nombre',
    path: 'petitioner.name'
  },
  'contact': {
    name: 'Contacto',
    parse: (pqr) => {
      return pqr?.petitioner?.phone || pqr?.petitioner?.mobilePhone || pqr?.petitioner?.otherContact;
    }
  },
  'location': {
    name: 'Ubicación',
    parse: (pqr) => {
      const address =pqr.address;
      const referencePointDescription = pqr.referencePointDescription;

      return `${address || ''} ${referencePointDescription || ''}`.trim();
    }
  },
  'responseType': {
    name: 'Tipo de respuesta',
    parse: (pqr) => RESPONSE_TYPES[pqr.responseType],
  },
  'responseDate': {
    name: 'Fecha de respuesta',
    parse: (pqr) => {
      if (!pqr.responseDate) {
        return '';
      }
      return humanizeDate(pqr.responseDate, true);
    }
  },
  'orderno': {
    name: 'Número de orden',
    path: 'maintenance.orderno',
  },
  'caseNumber': {
    name: 'Número de radicado',
  },
  'registrationDate': {
    name: 'Fecha de radicado',
    parse: (pqr) => {
      if (!pqr.createdAt) {
        return '';
      }
      return humanizeDate(pqr.registrationDate, true);
    }
  }
};

export const resumedColumns = {
  ...columns
};

export const detailedColumns = {
  ...columns
}

export const parse = (items, columns) => {
  const columnValues = Object.values(columns);
  const columnNames = Object.keys(columns);



  return items.map((item) => {
    return columnValues.map((columnConfig, index) => {
      let value = '';
      if (columnConfig.parse) {
        value = columnConfig.parse(item);
      }
      else if (columnConfig.path) {
        value = getProp(item, columnConfig.path, '-');
      }
      else if (columnConfig.descriptions) {
        value = columnConfig.descriptions[item[columnNames[index]]];
      } else {
        value = item[columnNames[index]] || '-';
      }

      return {
        value,
        ...columnConfig.config && { config: columnConfig.config }
      }
    })
  })
}


