import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Pipe } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchLuminaryDialogComponent } from '../../views/advanced-search-luminary-dialog/advanced-search-luminary-dialog.component';
import constants from '@ildes/config/constants';
import { CommonModule } from '@angular/common';
import { PredictiveSearcherComponent } from '@ildes/basic/predictive-searcher/predictive-searcher.component';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DropdownSelectorComponent } from '@ildes/basic/dropdown-selector/dropdown-selector.component';
import { MatButtonModule } from '@angular/material/button';
import { DateSelectorComponent } from '@ildes/basic/date-selector/date-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputPlaceComponent } from '@ildes/basic/input-place/input-place.component';
import { KeyValueItemComponent } from '@ildes/basic/key-value-item/key-value-item.component';
import { MatIconModule } from '@angular/material/icon';
import { FormatDatePipe } from '@ildes/pipes/format-date.pipe';
import { SessionStore } from '@ildes/stores/session.store';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import { Router } from '@angular/router';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';

const ESTIMATED_RESOLUTION_DAYS = 2;

@Component({
  standalone: true,
  imports: [
    FormatDatePipe,
    KeyValueItemComponent,
    DropdownSelectorComponent,
    KeyValueItemComponent,
    ReactiveFormsModule,
    CommonModule,
    PredictiveSearcherComponent,
    SimpleHeaderComponent,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    DropdownSelectorComponent,
    MatButtonModule,
    DateSelectorComponent,
    MatCheckboxModule,
    MatIconModule,
    InputPlaceComponent
  ],
  selector: 'app-create-maintenance',
  templateUrl: './create-maintenance.component.html',
  styleUrls: ['./create-maintenance.component.css']
})
export class CreateMaintenanceComponent implements OnInit {

  @ViewChild('stepper') vistualStepper: any;
  @Input() embedMode;
  @Input() project;
  @Input() ignoreLuminaryStep;
  @Input() ignoreLocationStep;
  @Input() painDescription;
  @Output() filledMainenanData = new EventEmitter();
  @Output() onClose = new EventEmitter();

  user;

  luminaries;
  loading;
  loadingLuminaries;
  pagination;

  filter: any;
  searchLuminaryAdapter;
  type;
  painType = constants.PAIN_TYPES[0];
  painTypes = constants.PAIN_TYPES;
  @Input() types = constants.TYPES;
  searchNeighborhoodAdapter;
  searchProjectAdapter;
  newMaintenanceGroupStep1 = new UntypedFormGroup({
    origin: new UntypedFormControl(),
    painTypeDescription: new UntypedFormControl(''),
  });
  newMaintenanceGroupStep2 = new UntypedFormGroup({
    luminary: new UntypedFormControl(''),
  });
  newMaintenanceGroupStep3 = new UntypedFormGroup({
    neighborhoodForm: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    selectedProjectForm: new UntypedFormControl('')
  });

  newMaintenanceGroupStep3PointDescription = new UntypedFormGroup({
    referencePointDescription: new UntypedFormControl('')
  });

  currenDate = new Date();
  estimatedResolutionDate = new Date(new Date().getTime() + ESTIMATED_RESOLUTION_DAYS * 24 * 3600 * 1000);

  constructor(
    private apiManager: ApiManagerService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private router: Router,
    private unsyncExpansionsService: UnsyncExpansionsService,
  ) {

  }

  get isTechnicalRole() {
    const role =  this.user.role.id;

    return constants.ONLY_CORRECTIVE_PERMISSIONS.indexOf(role) >= 0;
  }
  ngOnInit(): void {
    this.user =  SessionStore.getInstance().get().user;

    this.store.dispatch(
      actions.loadMaintenances({
        assigned: this.user.id,
        state: undefined,
        options: { cache: true }
      })
    );

    if (this.isTechnicalRole) {
      //this.types = [constants.CORRECTIVE, constants.EXPANSION]
      this.types = [constants.CORRECTIVE]
    }

    this.searchLuminaryAdapter = (data) => {
      return this.apiManager.listLuminaries({ number: data.text }).pipe(
        map(({ data }: any) => ( {data: this.normalize(data)}))
      )
    };
    this.searchNeighborhoodAdapter = (data) => {
      return this.apiManager.getFromCatalog('neighborhood', data)
    };
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };

    if (this.project) {
      this.newMaintenanceGroupStep3.controls.selectedProjectForm.setValue({
        typed: this.project.name,
        selected: { id: this.project.id }
      });
    }

    this.newMaintenanceGroupStep1.controls['painTypeDescription'].setValue(this.painDescription || '')
  }

  normalize(data) {
    return data.map((object) => ({...object, ...{ name: object.number }}));
  }

  get step1IsValid() {
    return Boolean(this.type);
  }

  get step3IsValid() {
    if (this.selectedLuminary) {
      return true;
    }

    return Boolean((this.address?.typed || this.referencePointDescription  || this.neighborhood?.typed) && (this.selectedProject?.selected || this.selectedProject?.typed));
  }

  get painTypeDescription() {
    return this.newMaintenanceGroupStep1.get('painTypeDescription')?.value
  }

  get address() {
    return this.newMaintenanceGroupStep3.get('address')?.value
  }

  get neighborhood() {
    return this.newMaintenanceGroupStep3.get('neighborhoodForm')?.value
  }

  get referencePointDescription() {
    return this.newMaintenanceGroupStep3PointDescription.get('referencePointDescription')?.value
  }

  get selectedProject() {
    return this.newMaintenanceGroupStep3.get('selectedProjectForm')?.value
  }

  advancedSearch() {
    const dialog = this.dialog.open(AdvancedSearchLuminaryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { filter: this.filter }
    });

    dialog.afterClosed().subscribe((data = { closed: false }) => {

      if (data.closed) {
        return;
      }
      this.filter = data;
      this.list();

    });
  }
  list(pageIndex = 0) {
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const filter: any = this.getFilter();

    this.loadingLuminaries = true;

    this.apiManager.listLuminaries({...page, ...filter}).subscribe((luminaries: any) => {
      this.loadingLuminaries = false;
      this.luminaries = luminaries.data;
      this.pagination = luminaries.pagination;
    }, (data) => {
      this.loadingLuminaries = false;
    });
  }

  private getFilter() {
    const filter: any = {};

    if (this.filter?.project?.selected.id) {
      filter.projectId = this.filter.project.selected.id;
    }

    if (this.filter?.neighborhood) {
      filter.neighborhood = this.filter.neighborhood.typed;
    }

    if (typeof this.filter?.withoutNumber === 'boolean' && this.filter?.withoutNumber) {
      filter.number = null;
    } else if (this.filter?.number) {
      filter.number = this.filter.number;
    }

    if (this.filter?.location) {
      filter.latitude = this.filter.location.lat;
      filter.longitude = this.filter.location.lng;
      filter.maxDistance = 50;
    }

    if (this.filter?.number) {
      filter.number = this.filter.number;
    }

    return filter;
  }

  selectLuminary(luminary) {
    this.newMaintenanceGroupStep2.controls.luminary.setValue({
      typed: luminary.number,
      selected: luminary
    });
    this.filter = null;
    this.luminaries = null;
    this.pagination = null;
  }

  get selectedLuminary() {
    return this.newMaintenanceGroupStep2.controls?.luminary?.value?.selected;
  }

  get luminaryFormControlValue() {
    return this.newMaintenanceGroupStep2.controls?.luminary?.value;
  }

  setEstimatedResolutionDate($event) {
    this.estimatedResolutionDate = $event.selectedDate;
  }

  get latitude() {
    return this.address?.lat;
  }

  get longitude() {
    return this.address?.lng;
  }

  performMaintenancePayload() {
    const technician = this.isTechnicalRole ? this.user.id : undefined;

    return {
      ...technician && { technician },
      type: this.type,
      ...this.type.id === 'CORRECTIVE' && { painType: this.painType },
      painDescription: this.painTypeDescription,
      ...!this.embedMode && {luminary: this.selectedLuminary?.id},
      ...!this.ignoreLocationStep && {
        neighborhood: this.neighborhood?.typed,
        address: this.address?.typed,
        ... this.latitude  &&
        {
          geolocation: {
            type: 'Point',
            coordinates: [this.longitude, this.latitude]
          }
        },
      },
      ...!this.embedMode && { project: this.selectedProject?.selected?.id },
      estimatedResolutionDate: this.estimatedResolutionDate,
      locationReferenceDescription: this.referencePointDescription
    };
  }

  performModernizationPayload() {
    return {
      project: this.selectedProject?.selected?.id || this.selectedLuminary?.project?.id,
      orderDescription: this.painTypeDescription,
      openDate: new Date().toISOString(),
      neighborhood: this.neighborhood?.typed,
      address: this.address?.typed,
      estimatedResolutionDate: this.estimatedResolutionDate,
      locationReferenceDescription: this.referencePointDescription,
      luminary: this.selectedLuminary?.id,
      ... this.latitude  &&
      {
        geolocation: {
          type: 'Point',
          coordinates: [this.longitude, this.latitude]
        }
      },
    }
  }

  performExpansionPayload() {
    const technician = this.isTechnicalRole ? this.user.id : undefined;

    return {
      technician,
      project: this.selectedProject?.selected?.id,
      orderDescription: this.painTypeDescription,
      openDate: new Date().toISOString(),
      neighborhood: this.neighborhood?.typed,
      address: this.address?.typed,
      estimatedResolutionDate: this.estimatedResolutionDate,
      locationReferenceDescription: this.referencePointDescription,
      ... this.latitude  &&
      {
        geolocation: {
          type: 'Point',
          coordinates: [this.longitude, this.latitude]
        }
      },
    }
  }

  create() {
    let data;
    let service;
    switch(this.type.id) {
      case 'MODERNIZATION':
        data =  this.performModernizationPayload();
        service = this.apiManager.createModernization(data);
      break;
      case 'EXPANSION':
        data = this.performExpansionPayload();
        service = this.apiManager.createExpansion(data);
      break;
      default:
        data = this.performMaintenancePayload();
        service = this.apiManager.createMaintenance(data);
    }
    this.filledMainenanData.emit(data);

    if (this.embedMode) {
      return;
    }
    this.loading = true;
    service.subscribe((order) => {
      this.loading = false;
      if (this.isTechnicalRole) {
        if (this.type.id === constants.CORRECTIVE.id) {
          const technician = {...this.user};
          const luminary = {id: this.selectedLuminary?.id, number: this.selectedLuminary?.number};
          const selectedProject = this.selectedProject?.selected || this.selectedLuminary?.project;
          const project = {
            name: selectedProject?.name || this.selectedProject?.typed,
            id: selectedProject?.id
          };
          this.store.dispatch(
            actions.createMaintenance({ maintenance: {...data, ...{ id: order.id, technician, luminary, project }}})
          );
          this.router.navigate(['/app/repair-maintenance'], { replaceUrl: true, queryParams: { id: order.id } });
        } else if (this.type.id === constants.EXPANSION.id) {
          this.router.navigate(['/app/create-expansion'], { replaceUrl: true, queryParams: { id: order.data.id } });
        }
      }
      this.type = null;
      this.painType = constants.PAIN_TYPES[0];
      this.newMaintenanceGroupStep3.reset();
      this.newMaintenanceGroupStep2.reset();
      this.vistualStepper.reset();
    }, (error) => {
      this.loading = false;
      const isMaintenance = this.type.id === constants.CORRECTIVE.id;
      const isExpansion = this.type.id === constants.EXPANSION.id;
      if ((!error.code || error.code > 0) || (!isMaintenance && !isExpansion) || !this.isTechnicalRole) {
        return;
      }

      const selectedProject = this.selectedProject?.selected || this.selectedLuminary?.project;
      const project = {
        name: selectedProject?.name || this.selectedProject?.typed,
        id: selectedProject?.id
      };

      if (isMaintenance) {
        const luminary = {
          number: this.selectedLuminary?.number ||  this.luminaryFormControlValue.typed,
          id: this.selectedLuminary?.id
        };
        data.technician = {...this.user};
        data.project = project;
        data.luminary = this.selectedLuminary?.number ? luminary: undefined;
        this.store.dispatch(
          actions.createMaintenance({ maintenance: data })
        );
        this.store.pipe(first()).subscribe((data) => {
          this.router.navigate(['/app/repair-maintenance'], { replaceUrl: true, queryParams: { id: data.assignedMaintenances[0].id } });
        });
        return;
      } else if (isExpansion) {
        data.selectedProject = project;
        data.photos = {};
        const id = this.unsyncExpansionsService.addItem(data);

        this.router.navigate(['/app/create-expansion'], { replaceUrl: true, queryParams: { id } });
        return;
      }
    })
  }

  get disableLuminaryStep() {
    return this.ignoreLuminaryStep || this.type?.id === 'EXPANSION';
  }

  close() {
    this.onClose.emit();
  }
}
