import { Injectable } from '@angular/core';
import { ApiManagerService } from './api-manager.service';
import { PhotosUtils } from '@ildes/utils/photos'
import { from, of, zip } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import questionsMaintenance from '../views/repair-maintenance/questions-maintenance';
import { UnsyncDataService } from './unsync-data.service';
import { StorageService } from './storage-service.service';
import { SessionStore } from '@ildes/stores/session.store';

const STORAGE_NAME = 'unsync-expansions';

@Injectable({
  providedIn: 'root'
})
export class UnsyncExpansionsService extends UnsyncDataService {
  private photosHandler: PhotosUtils;
  constructor(
    private apiManager: ApiManagerService,
    public storageService: StorageService,
  ) {
    super(storageService, STORAGE_NAME);

    this.photosHandler = new PhotosUtils();
  }

  sync(expansionData, update = false) {
    const inputData = {...expansionData};
    let performInitialPhotos = of(null);
    let performFinalPhotos = of(null);

    if (expansionData.photos.initial) {
      performInitialPhotos = from(this.photosHandler.toBase64(expansionData.photos.initial));
    }
    if (expansionData.photos.final) {
      performFinalPhotos = from(this.photosHandler.toBase64(inputData.luminary.photos));
    }

    return zip(
      performInitialPhotos,
      performFinalPhotos
    ).pipe((
      concatMap(([initialPhotos, luminaryPhotos]) => {
        const photos:any = {};

        if (initialPhotos) {
          photos.initial = initialPhotos
        }
        inputData.photos = photos;
        inputData.luminary = {...inputData.luminary};
        inputData.luminary.photos = luminaryPhotos;
        delete inputData.answersDailyQuestionary;
        const id = inputData.id;
        delete inputData.id;
        inputData.questionary = questionsMaintenance.map((question) => {
          const element = inputData.answersSecurityQuestionary.find(({ id }) => id === question.id);

          return {
            id: question.id,
            title: question.title,
            value: !!element?.value,
            observations: element?.observations
          }
        })
        const service = update ? this.apiManager.updateExpansion(id, inputData): this.apiManager.createExpansion(inputData);

        return service.pipe(
          tap(
            {
              next: () => {
                if (expansionData.id) {
                  //this.removeItem(expansionData);
                  this.update({...expansionData, unsync: false });
                }
              },
              error: (error) => {
                if (!error.code || error.code > 0) {
                  return;
                }
                this.addItem({
                  ...expansionData,
                  unsync: true
                 });
              },
          }
        ));
      })
    ));
  }

  getData() {

  }

  public fetchExpansions(params, cache) {
    const sessionStore = SessionStore.getInstance();
    const userId = sessionStore.get().user.id;
    return this.apiManager.getExpansions({assigned: userId, ...params}, { error: true, timeout: true, cache})
      .pipe(
        tap((data) => !cache && this.updateUnsyncData(params.state, data)),
        map((data) => params.state !== 'CLOSED' ?  this.filterData(data): data),
        map((data) => params.state === 'CLOSED' ? this.addUnsyncData(data) : data),
      )
  }

}
