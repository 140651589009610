import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import constants from '@ildes/config/constants';
import { Location } from '@angular/common';
import { ToastService } from '@ildes/services/toast.service';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/list-luminaries/list-luminaries.actions';

@Component({
  selector: 'app-edit-transformer-data',
  templateUrl: './edit-transformer-data.component.html',
  styleUrls: ['./edit-transformer-data.component.css']
})
export class EditTransformerDataComponent implements OnInit {

  loading;
  luminary;
  selectedElectricNetwork = null;
  electricNetwork = constants.ELECTRIC_NETWORK;
  electricalNetworkMaterialTypes = constants.ELECTRICAL_NETWORK_MATERIAL_TYPES;
  selectedElectricalNetworkMaterialType;
  powerNetworkInstallationTypes = constants.POWER_NETWORK_INSTALLATION_TYPES;
  selectedElectricalNetworkInstallationType;
  useTransformerTypes = constants.USE_TRANSFOMER_TYPES;
  selectedUseTransfromerType;
  transformerInstallationTypes = constants.TRANSFORMER_INSTALLATION_TYPES;
  selectedTransformerInstallationType;

  formGroup = new UntypedFormGroup({
    transformerNumber: new UntypedFormControl(),
    conductorGauge: new UntypedFormControl(),
  });

  constructor(
    private store: Store<AppState>,
    private apiManager: ApiManagerService,
    private location: Location,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    const state:any = this.location.getState();

    this.luminary = state.luminary;
    if (!this.luminary) {
      this.location.back();

      return;
    }
    this.formGroup.controls.transformerNumber.setValue(this.luminary.transformerNumber || '');
    this.formGroup.controls.conductorGauge.setValue(this.luminary.conductorGauge || '');

    this.selectedElectricNetwork = this.electricNetwork.find(({id}) => this.luminary.electricNetwork?.id === id);

    this.selectedElectricalNetworkMaterialType = this.electricalNetworkMaterialTypes.find(({id}) => this.luminary.electricalNetworkMaterialType?.id === id);
    this.selectedElectricalNetworkInstallationType = this.powerNetworkInstallationTypes.find(({id}) => this.luminary.electricalNetworkInstallationType?.id === id);

    this.selectedUseTransfromerType = this.useTransformerTypes.find(({id}) => this.luminary.useTansformerType?.id === id);
    this.selectedTransformerInstallationType = this.transformerInstallationTypes.find(({id}) => this.luminary.transformerInstallationType?.id === id);
  }

  get selectedElectricalNetworkInstallationTypeIsSub() {
    return this.selectedElectricalNetworkInstallationType?.id === 'SUB';
  }

  get transformerNumber() {
    return this.formGroup.get('transformerNumber')?.value
  }

  get conductorGauge() {
    return this.formGroup.get('conductorGauge')?.value
  }

  save() {
    this.loading = true;

    const luminary = {
      transformerNumber: this.transformerNumber,
      electricalNetworkMaterialType: this.selectedElectricalNetworkMaterialType,
      conductorGauge: this.conductorGauge,
      electricalNetworkInstallationType: this.selectedElectricalNetworkInstallationType,
      useTansformerType: this.selectedUseTransfromerType,
      transformerInstallationType: this.selectedTransformerInstallationType,
      project: this.luminary.project?.id
    };

    this.apiManager.updateLuminary(this.luminary.id, {...this.luminary, ...luminary}).subscribe(() => {
      this.loading = false;

      this.store.dispatch(
        actions.remove({ _id: this.luminary._id})
      )
      this.location.back();
    }, (error) => {
      this.loading = false;
      if (!error.code || error.code > 0) {
        return;
      }

      const luminaryMerged = {
        ...this.luminary,
       ...luminary
      };

      if (this.luminary._id) {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.update({ id: this.luminary._id, changes: luminaryMerged })
        )
      } else {
        this.toast.show('Luminaria añadida a luminarias pendientes de sincronizar', 'ok');

        this.store.dispatch(
          actions.create({ luminary: luminaryMerged })
        )
      }
      this.location.back();
    });
  }

}
