import { ApiManagerService } from '@ildes/services/api-manager.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { tap } from 'rxjs/operators';

const CONTENT_TYPES = {
  pdf: 'application/pdf',
  png: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
};


@Injectable({
  providedIn: 'root'
})
export class FileHelperService {

  constructor(
    private apiManager: ApiManagerService
  ) {

  }


  download(fileName) {

    const parts = fileName.split('.');
    const extension = parts[parts.length - 1];
    const contentType = CONTENT_TYPES[extension]

    return this.apiManager.getFile(fileName, {}, { contentType , error: true })
      .pipe(
        tap((data) => this.open(fileName, data))
      );
  }

  downloadPdf(fileName) {
    return this.apiManager.getFile(fileName, {}, { contentType: 'application/pdf', error: true })
      .pipe(
        tap((data) => this.open(fileName, data))
      );
  }

  async open(fileName, data) {
    if (Capacitor.getPlatform() !== 'web' ) {
      const database64 = await this.convertBlobToBase64(data) as string;

      const savedFile = await Filesystem.writeFile({
        path: fileName.split('/').pop(),
        data: database64,
        directory: Directory.Cache,
      });

      const path = savedFile.uri;

      FileOpener.open({ filePath: path, contentType: data.type });

    } else {
      const url = window.URL.createObjectURL(data);

      Browser.open({ url });
    }
  }

  private convertBlobToBase64 = (blob :Blob)=>new Promise ((resolve,reject) =>{
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () =>{
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

}
