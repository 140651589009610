import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiManagerService } from '../../services/api-manager.service';
import { SelectUsersDialogComponent } from '../../basic/select-users-dialog/select-users-dialog.component';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
const CONTRACT_TYPES = [
  {
    id:'SOC',
    description:'Contrato de sociedad'
  },
  {
    id:'CONC',
    description:'Contrato de concesión'
  },
  {
    id:'WORK',
    description:'Contrato de obra'
  }
];
const PARTS = [
  {
    id:'NATURAL',
    description:'Natural'
  },
  {
    id:'JURIDIC',
    description:'Jurídico'
  }
];
@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {
  contractTypes = CONTRACT_TYPES;
  contractType;
  private id;
  private project;
  public loadingData;
  public projectForm = new UntypedFormGroup({});
  public minutesDate;
  public subscriptionDate;
  public loading;
  public searchDepartmentAdapter;
  public searchCityAdapter;
  private currentDepartment;
  public accountableOperativeSection;
  public accountableAdministrativeSection;
  public accountableThirdAdministrativeSection = {
    name: '',
    email: '',
  }
  contractParts = PARTS;
  dinamicParts = [
    {
      name: '',
      type: '',
      percent: 0,
    }
  ];
  constructor(
    private dialog: MatDialog,
    private apiManager: ApiManagerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.searchDepartmentAdapter = (data) => {
      return this.apiManager.getFromCatalog('department', data);
    };
    this.searchCityAdapter = (data) => {
      const classification = this.projectForm.controls.department.value.selected.name;

      return this.apiManager.getFromCatalog('city', { text: data.text, classification});
    };

    this.route.queryParams.subscribe(params => {

      this.id = params.id;
      this.loadingData = true;

      this.apiManager.getProjectDetail(this.id).subscribe((project) => {
        this.project = project;
        this.setFormData();
        this.loadingData = false;
      },
        () => {this.loadingData = false;}
      );
    });
  }

  private setFormData() {
    this.accountableOperativeSection = this.project.accountableOperativeSection;
    this.accountableAdministrativeSection = this.project.accountableAdministrativeSection;
    if (this.project.accountableThirdAdministrativeSection?.length) {
      this.accountableThirdAdministrativeSection = {...this.project.accountableThirdAdministrativeSection[0]};
    }

    this.minutesDate = this.project.minutesDate;
    this.subscriptionDate = this.project.subscriptionDate;
    let initEndDate = moment(this.minutesDate || this.subscriptionDate);
    initEndDate = initEndDate.add(this.project.duration + 1, 'days');
    this.dinamicParts = [...this.project.parts, this.dinamicParts];
    const years = initEndDate.diff(this.minutesDate || this.subscriptionDate, 'years');
    const months = initEndDate.add(-years, 'years').diff(this.minutesDate || this.subscriptionDate, 'months');
    this.currentDepartment = this.project.address?.department;
    this.contractType = this.contractTypes.find(({id}) => this.project.contractType?.id === id);
    this.projectForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.project.name, [Validators.required]),
      prefix: new UntypedFormControl(this.project.prefix, [Validators.required]),
      object: new UntypedFormControl(this.project.object, [Validators.required]),
      // contractType: new UntypedFormControl(this.project.contractType, [Validators.required]),
      contractNumber: new UntypedFormControl(this.project.contractNumber, [Validators.required]),
      value: new UntypedFormControl(this.project.value, [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
      months: new UntypedFormControl(months, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      years: new UntypedFormControl(years, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      address: new UntypedFormControl(this.project.address?.address || '', [Validators.required]),
      department: new UntypedFormControl(this.project.address ? { typed: this.project.address?.department, selected: { name: this.project.address?.department } } : '', [Validators.required]),
      city: new UntypedFormControl(this.project.address ? { typed: this.project.address?.city, selected: { name: this.project.address?.city } } : '', [Validators.required]),
    }, this.durationShouldBeGreaterThanOne);
  }

  checkPrefix(event) {
    if (this.project.prefix) {
      return;
    }
    const prefix = event.target.value?.toUpperCase();
    if (prefix?.length !== 4) {
      this.invalidateFormPrefix();

      return;
    }
    this.apiManager.checkPrefix({ prefix }).subscribe((data: any) => {
      if (data.data.exists) {
        this.invalidateFormPrefix();
      } else {
        this.validateFormPrefix();
      }
    }, () =>{

    })
  }

  invalidateFormPrefix() {
    this.projectForm.controls['prefix'].setErrors({'incorrect': true});
  }
  validateFormPrefix() {
    this.projectForm.controls['prefix'].setErrors(null);
  }

  changeDepartment($event) {
    if ( this.currentDepartment && this.currentDepartment !== $event.option.value?.name) {
      this.currentDepartment = $event?.name;
      console.log('reset')
      this.projectForm.controls.city.reset();
    }
  }

  get formattedEndDate() {
    return this.endDate?.format('DD/MM/YYYY');
  }

  private durationShouldBeGreaterThanOne(fGroup: UntypedFormGroup) {
    if (!fGroup.get('months').touched && !fGroup.get('years').touched) {
      return null;
    }
    return fGroup.get('months').value > 0 || fGroup.get('years').value > 0 ? null : { 'validDuration': true };
  }

  public toggleMinutesDate() {
    if (this.minutesDate) {
      this.minutesDate = false;

      return;
    }
    this.minutesDate = this.subscriptionDate;
  }


  get endDate() {
    const currentDate = moment(this.minutesDate || this.subscriptionDate);
    const years = this.projectForm.controls.years.value;
    const months = this.projectForm.controls.months.value;

    if(!currentDate.isValid() || (years === 0 && months === 0)) {
      return null;
    }
    return currentDate
      .add(years, 'year')
      .add(months, 'month')
      .subtract(1, 'day');
  }

  setSubscriptionDate(event) {
    this.subscriptionDate = event.selectedDate;
  }

  setMinutesDate(event) {
    this.minutesDate = event.selectedDate;
  }

  get days() {
    return this.endDate.diff(this.minutesDate || this.subscriptionDate, 'days');
  }

  get addressData() {
    return {
      address: this.projectForm.controls.address.value,
      department: this.projectForm.controls.department.value.selected.name,
      city: this.projectForm.controls.city.value.selected.name
    }
  }

  save() {
    const {
      name,
      prefix,
      object,
      // contractType,
      contractNumber,
      value
    } = this.projectForm.controls;

    const length = this.dinamicParts.length;
    const contractParts = this.dinamicParts.slice(0, length - 1);
    const data = {
      name: name.value,
      prefix: prefix.value,
      object: object.value,
      contractType: this.contractType,
      contractNumber: contractNumber.value,
      parts: contractParts,
      value: value.value,
      minutesDate: this.minutesDate || this.subscriptionDate,
      subscriptionDate: this.subscriptionDate,
      duration: this.days,
      address: this.addressData,
      accountableAdministrativeSection: this.accountableAdministrativeSection.map(( {id} ) => id),
      accountableOperativeSection: this.accountableOperativeSection.map(( {id} ) => id),
      ...this.accountableThirdAdministrativeSection?.name && { accountableThirdAdministrativeSection: [this.accountableThirdAdministrativeSection] }
    };
    this.loading = true;
    this.apiManager.updateProject(this.project.id, data).subscribe(() => {
      this.loading = false;
    }, (data) => {
      this.loading = false;
    });
  }

  removePart(index) {
    this.dinamicParts = this.dinamicParts.filter((_, cIndex) => index!==cIndex);
  }

  get erorInParts () {
    const length = this.dinamicParts.length;

    return  length > 1 && this.dinamicParts.slice(0, length - 1).reduce((sum, part) => part.percent + sum, 0) !== 100;
  }

  addPart() {
    this.dinamicParts.push({
      name: '',
      percent: 0,
      type: '',
    })
  }

  onAddParticipantsOperativeSection() {
    this.onAddParticipants(2, this.accountableOperativeSection || [])
      .afterClosed()
      .subscribe( (data) => {
        if (data.close) {
          return;
        }

        this.accountableOperativeSection = data.participants;
    });
  }
  onAddParticipantsAdministrativeSection() {
    this.onAddParticipants(1, this.accountableAdministrativeSection || [])
      .afterClosed()
      .subscribe( (data) => {
        if (data.close) {
          return;
        }

        this.accountableAdministrativeSection = data.participants;
    });
  }

  onAddParticipants(maxSelectedUsers, selected = []) {
    return this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { maxSelectedUsers, selected}
    });
  }

  get disabledAddPart() {
    const last = this.dinamicParts[this.dinamicParts.length -1];

    return !last.name || !last.percent || !last.type;
  }

  get errorInAccountableSection() {
    if (this.accountableThirdAdministrativeSection?.name && !this.accountableThirdAdministrativeSection?.email) {
      return 'Debe completar el email'
    }
    if (this.accountableThirdAdministrativeSection?.email && !this.accountableThirdAdministrativeSection?.name) {
      return 'Debe completar el nombre'
    }

    return null;
  }

}
