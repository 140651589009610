export default (confirmedMaterials: any = [], maintenances: any = [], expansions: any = [], modernizations: any = []): any => {
    const maintenancesMaterials = (maintenances || [])
        .filter(({ pendingSync, localUsedMaterials }) => pendingSync && localUsedMaterials)
        .flatMap(({ localUsedMaterials }) => localUsedMaterials)
        .filter((material) => !!material);

    const expansionsMaterials = expansions.flatMap(({usedMaterials}) => usedMaterials).filter((material) => !!material);
    const modernizationsMaterials = modernizations.flatMap(({usedMaterials}) => usedMaterials).filter((material) => !!material);
    const computedUsedMaterials = [...expansionsMaterials, ...maintenancesMaterials, ...modernizationsMaterials];
    const unifiedUsedMaterials = computedUsedMaterials
        .reduce((acum, { reference, amount }) => {
            const foundedMaterial = acum.find(({ reference: { id } }) => id === reference.id);

            if (foundedMaterial) {
                foundedMaterial.amount += amount;
                foundedMaterial.reference = reference;

                return acum;
            }
            return [
                ...acum, {
                    amount,
                    reference
                }
            ];
        }, [])
    const currentConfirmedMaterials = confirmedMaterials.map((confirmMaterial) => {
        const material = unifiedUsedMaterials.find(
            (usedMaterial) => confirmMaterial.reference.id === usedMaterial.reference.id
        );

        return {
            reference: confirmMaterial.reference,
            amount: confirmMaterial.amount - (material?.amount || 0)
        };
    }).filter((material) => material.amount > 0);

    return currentConfirmedMaterials;
}
