import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class SimpleHeaderComponent implements OnInit {

  @Input() title;
  @Input() icon;
  @Input() back;
  @Input() actions;

  @Output() action =  new EventEmitter();

  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
  }

  onAction(action: any) {
    if (this.back && !action) {
      this.location.back();

      return;
    }
    this.action.emit(action);
  }

}
