<div class="container">
  <div class="scrollable-content">
    <simple-header title="Vista resumen de {{selectedTechnician?.name}}" [back]="true" icon="close"></simple-header>
    <mat-accordion displayMode="flat" [multi]="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3>Mantenimientos asignados</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingMaintenances"></mat-progress-bar>
        <div *ngIf="!maintenances?.length && !loadingMaintenances">
          <app-image-panel
            class="margin-top-16"
            text="Sin mantenimientos asignados."
          ></app-image-panel>
        </div>
        <div class="" *ngIf="maintenances?.length">
          <div *ngFor="let maintenance of maintenances" class="">
            <list-maintenance-item
              [parent]="maintenance.parent"
              [state]="maintenance.state"
              [type]="maintenance.type"
              [luminaryNumber]="maintenance.luminary?.number"
              [project]="maintenance.project?.name || 'Sin proyecto'"
              [address]="maintenance.address"
              [neighborhood]="maintenance.neighborhood"
              [technician]="maintenance.technician"
              [referencePoint]="maintenance.locationReferenceDescription"
            ></list-maintenance-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="flex-row">
          <div class="right">
            <button (click)="assignMaintenances()" mat-button color="primary" class="margin-top-16">Asignar mantenimientos</button>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [allowAction]="['materials.assignToTechnician']">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3>Material preasignado</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingTechnicianDetail || loadingStores"></mat-progress-bar>
        <div *ngIf="!stores?.length" class="padding-top-8 padding-bottom-8">
          <app-alert-dialog
            (click)="gotoCreateStores()"
            text="No existen almacenes y por tanto no hay material que asignar."
            linkText="Ir a crear almacenes"
            status="advise"></app-alert-dialog>
        </div>
        <div *ngIf="stores?.length">
          <div class="display-flex-input">
            <div>
              <mat-form-field class="fill" appearance="outline">
                <mat-label>Almacén</mat-label>
                <mat-select (selectionChange)="onStoreChange($event)" [(value)]="selectedStore">
                  <mat-option *ngFor="let store of stores" [value]="store">
                    {{store.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="fill input-material-name">
              <form [formGroup]="materialSeacherForm">
                <div class="fill inline-flex">
                  <predictive-searcher
                    [disabled]="!this.store"
                    formControlName="materialName"
                    label="Añadir material"
                    [service]="searchMaterialAdapter"
                    (selectedValue)="onSelectedValue($event)"
                    class="fill column"
                    ></predictive-searcher>
                </div>
              </form>
            </div>
          </div>
          <div class="inline-flex flex-wide">
            <div class="flex-wide">
              <div class="list">
                <h4 class="padding-top-16" *ngIf="normalizedMaterialPreassigned?.length">Listado de material</h4>
                <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingAssignMaterials"></mat-progress-bar>
                <div *ngIf="!loadingAssignMaterials">
                  <app-image-panel class="margin-top-16" text="No hay material preasignado" *ngIf="!normalizedMaterialPreassigned?.length"></app-image-panel>
                  <div *ngFor="let item of normalizedMaterialPreassigned">
                    <div>
                      <div class="material">
                        <div class="fill">
                          <div class="material-name">
                            {{item.reference.name}}
                          </div>
                        </div>
                        <input-material-value
                          class="vertical-center"
                          [max]="item.max"
                          (remove)="remove(item)"
                          [material]="item"
                          [showRemoveOption]="true"
                          [(value)]="item.amount"></input-material-value>
                      </div>
                      <div class="material-badge error" *ngIf="item.max < item.amount">
                        <div>
                          <i class="padding-left-8">No hay stock suficiente en {{item.store.name}}</i>
                        </div>
                        <button color="primary" mat-button (click)="selectStore(item)">Ver disponibilidad</button>
                      </div>
                      <div class="material-badge" *ngIf="item.max >= item.amount">
                        <i>{{item.max}} disponibles en {{item.store.name || 'stock'}}</i>
                      </div>
                      <div class="material-badge" *ngIf="!item.store" (click)="selectStore(item)">
                        <button color="primary" mat-button>Seleccionar almacén</button>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                </div>
                <div class="flex-row" *ngIf="normalizedMaterialPreassigned?.length">
                  <div class="right">
                    <button [disabled]="!normalizedMaterialPreassigned?.length" mat-button (click)="savePreassignedMaterial()">Guardar</button>
                    <button color="primary" [disabled]="!normalizedMaterialPreassigned?.length || hasMaterialOutOfStock" mat-button (click)="assignMaterial()">Asignar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3>Material asignado</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingTechnicianDetail"></mat-progress-bar>
        <app-image-panel class="margin-top-16" text="No tiene material asignado" *ngIf="!selectedTechnician?.assignedMaterials?.length"></app-image-panel>
        <div *ngFor="let material of selectedTechnician?.assignedMaterials" class="item">
          <list-material-item
            [unitDescription]="material.store"
            [name]="material.reference.name"
            [unit]="material.reference.unit"
            [amount]="material.amount"
            class="padding-top-8">
          </list-material-item>
          <mat-divider></mat-divider>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3>Material en posesión del técnico</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingTechnicianDetail"></mat-progress-bar>
        <app-image-panel class="margin-top-16" text="No tiene material en posesión" *ngIf="!selectedTechnician?.confirmedMaterials?.length"></app-image-panel>
        <div *ngFor="let material of selectedTechnician?.confirmedMaterials" class="item">
          <list-material-item
            [name]="material.reference.name"
            [unit]="material.reference.unit"
            [amount]="material.amount"
            class="padding-top-8">
          </list-material-item>
          <mat-divider></mat-divider>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3>Material devuelto</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingTechnicianDetail"></mat-progress-bar>
        <app-image-panel class="margin-top-16" text="No hay material devuelto" *ngIf="!selectedTechnician?.returnedMaterials?.length"></app-image-panel>
        <div *ngFor="let store of materialsFromStore" class="item">
          <div *ngFor="let material of store.items" class="item">
            <list-material-item
              [unitDescription]="material.store"
              [name]="material.reference.name"
              [unit]="material.reference.unit"
              [amount]="material.amount"
              class="padding-top-8">
            </list-material-item>
            <mat-divider></mat-divider>
          </div>
          <div class="flex-row margin-top-16" *ngIf="selectedTechnician?.returnedMaterials?.length">
            <button color="primary" class="right" (click)="returnToInventory(store)" [disabled]="selectedTechnician?.returnedMaterials?.length === 0" mat-button>Devolver a {{store.name}}</button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
