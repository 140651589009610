import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { Component, Inject, OnInit } from '@angular/core';
import { ImagePanelComponent } from '@ildes/basic/image-panel/image-panel.component';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-select-store-with-stock-dialog',
  standalone: true,
  imports: [
    SimpleHeaderComponent,
    ImagePanelComponent,
    MatDividerModule,
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  template: `
    <simple-header [title]="'Buscar ' + itemName" (action)="close()" icon="close"></simple-header>
    <div class="container">
      <div class="scrollable-content">
        <div *ngIf="!loading">
          <app-image-panel class="margin-top-16" text="No hay material preasignado" *ngIf="!stores?.length"></app-image-panel>
          <div *ngFor="let store of stores">
            <div class="list-item padding-top-8 padding-bottom-8" (click)="select(store.store)">
              <div class="text">
                {{store.amount}} disponibles en {{store.store.name}}
              </div>
              <button mat-icon-button>
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </div>
    `,
  styleUrls: ['./select-store-with-stock-dialog.component.css'],
})
export class SelectStoreWithStockDialogComponent implements OnInit {
  loading;
  stores;
  constructor(
    private apiManager: ApiManagerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectStoreWithStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.apiManager.getStoresWithStock({item: this.data.item.reference.id})
      .subscribe((data) => {
        this.stores = data;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  select(selected) {
    this.dialogRef.close({ selected });
  }

  close() {
    this.dialogRef.close({ closed: true });
  }

  get itemName() {
    return this.data.item?.reference?.name;
  }
}
