import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
  ]
})
export class DropdownSelectorComponent implements OnInit {

  @Input() noEmptyValue;
  @Input() types;
  @Input() label;
  @Input() selected;
  @Input() disabled = false;
  @Output() selectedChange = new EventEmitter<number>();

  get _selected() {
    return this.selected;
  }

  set _selected(newValue) {
    this.selected = newValue;
    this.selectedChange.emit(this.selected);
  }
  constructor() { }

  ngOnInit(): void {
    this._selected = this.selected;
  }

}
