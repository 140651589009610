<div class="container">
  <div class="scrollable-content">
    <simple-header title="Asociaciones de material por tecnología/potencia" [back]="true" icon="close"></simple-header>
    <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div *ngFor="let currentAsocMaterial of asocMaterials" class="item padding-top-8">
      <list-asoc-material-item
        [actions]="actions"
        [technology]="currentAsocMaterial.technology"
        [power]="currentAsocMaterial.power"
        [materials]="currentAsocMaterial.materials"
        (onClickAction)="onClickActionEvent(currentAsocMaterial, $event)"
      ></list-asoc-material-item>
      <mat-divider></mat-divider>
    </div>
    <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/list-asoc-material/create']"><mat-icon>add</mat-icon></button>
  </div>
  <mat-paginator
    [pageIndex]="currentPage"
    [length]="pagination?.totalDocs"
    pageSize="20"
    (page)="paginate($event)">
  </mat-paginator>
</div>
