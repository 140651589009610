import { humanizeDate } from "../../utils/formatters";

function getProp( object, keys, defaultVal ){
  keys = Array.isArray( keys )? keys : keys.split('.');
  object = object[keys[0]];
  if( object && keys.length>1 ){
    return getProp( object, keys.slice(1), defaultVal );
  }
  return object === undefined? defaultVal : object;
}

export const columns = {
  'orderno': {
    name: 'Orden'
  },
  'dpto': {
    name: 'Departamento',
    path: 'project.address.department',
  },
  'mpio': {
    name: 'Municipio',
    path: 'project.address.city',
  },
  'type': {
    name: 'Tipo de orden',
    path: 'type.description'
  },
  'technician': {
    name: 'Técnico',
    parse: (item) => {
      return item.technician ? `${item.technician.name} ${item.technician.surname}`: '-'
    }
  },
  'material': {
    name: 'Material'
  },
  'technology': {
    name: 'Tecnología',
    path: 'luminary.technology'
  },
  'power': {
    name: 'Potencia',
    path: 'luminary.power'
  },
  'amount': {
    name: 'Cantidad'
  },
  'totalCost': {
    name: 'Coste total ($CO)'
  }
};

export const parse = (items, columns) => {
  const columnValues = Object.values(columns);
  const columnNames = Object.keys(columns);



  return items.map((item) => {
    return columnValues.map((columnConfig, index) => {
      let value = '';
      if (columnConfig.parse) {
        value = columnConfig.parse(item);
      }
      else if (columnConfig.path) {
        value = getProp(item, columnConfig.path, '-');
      }
      else if (columnConfig.descriptions) {
        value = columnConfig.descriptions[item[columnNames[index]]];
      } else {
        value = item[columnNames[index]] || '-';
      }

      return {
        value,
        ...columnConfig.config && { config: columnConfig.config }
      }
    })
  })
}


