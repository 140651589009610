import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SyncDataService } from '@ildes/services/sync-data.service';
import { tap, map, toArray, concatAll, catchError, combineAll } from 'rxjs/operators';
import { Observable, concat, defer, of, throwError } from 'rxjs';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import * as actionsLuminaries from '@ildes/views/list-luminaries/list-luminaries.actions';
import { SessionStore } from '@ildes/stores/session.store';
import questionsHighPermission from '../repair-maintenance/questions-high-permission';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';

const sameDay = (day1,day2) => {
  return (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  );
}

function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) =>  Observable<T> {
  return function inner(source: Observable<T>): Observable<T> {
      return defer(() => {
        onSubscribe();
        return source;
      });
  };
}

const DISCARD_CHANGES = {
  id: 'discard',
  description: 'Descartar',
  icon: 'trash'
};
@Component({
  selector: 'app-list-maintenances-pending-sync-dialog',
  templateUrl: './list-maintenances-pending-sync-dialog.component.html',
  styleUrls: ['./list-maintenances-pending-sync-dialog.component.css']
})
export class ListMaintenancesPendingSyncDialogComponent implements OnInit {
  maintenances;
  luminaries;
  loading = {};
  pendingRequestQuestionaries;
  questionaries;
  error = false;
  actionsLuminary = [
    DISCARD_CHANGES
  ]
  constructor(
    private store: Store<AppState>,
    public syncData: SyncDataService,
    public dialogRef: MatDialogRef<ListMaintenancesPendingSyncDialogComponent>,
    private apiManager: ApiManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.maintenances = data.maintenances || [];
    this.luminaries = data.luminaries || [];
    this.questionaries = data.questionaries || [];
  }
  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ close: true });
  }

  selectMaintenance(selectedMaintenance) {
    this.dialogRef.close({ selectedMaintenance });
  }
  selectLuminary(selectedLuminary) {
    this.dialogRef.close({ selectedLuminary });
  }

  sync() {
    this.error = false;

    this.pendingRequestQuestionaries = true;

    const requetQuestionaries = [...this.questionaries].map((questionary)=> {
      return this.apiManager.updateDailyQuestionary(questionary.projectId, {
        questions: questionsHighPermission,
        answers: questionary.answers,
        date: questionary.date,
      }).pipe(
        tap(() => {
          this.questionaries = this.questionaries.filter(({date, projectId}) => !(sameDay(date, questionary.date) && projectId === questionary.projectId))
          this.store.dispatch(
            actionsQuestionaries.update({
              changes: { pendingSync: false },
              date: questionary.date,
              projectId: questionary.projectId,
            })
          );
        }),
        map((data) => of(data)),
        combineAll(),
        tap(() => this.pendingRequestQuestionaries = false),
        map(() => of([])),
        catchError((error) => {
          this.pendingRequestQuestionaries = false;

          return throwError(error)
        })
      )
    });

    const requestsLuminaries = [...this.luminaries].map((luminary) => {
      return this.syncData.syncLuminary(luminary).pipe(
        doOnSubscribe(() => {
          this.loading[luminary.number] = true;
        }),
        tap(() => ({
          next: this.loading[luminary.number] = false
        })),
        map(() => {
          return luminary;
        })
      )
    });

    const requests = [...this.maintenances].map((maintenance) => {
      return this.syncData.syncMaintenance(maintenance, {}, false).pipe(
        doOnSubscribe(() => {
          this.loading[maintenance.id] = true;
        }),
        tap(() => ({
          next: this.loading[maintenance.id] = false
        })),
        map(() => {
          return maintenance;
        })
      )
    });
    const userId = SessionStore.getInstance().get().user.id;

    const concatRequestes = concat(...[...requetQuestionaries,...requestsLuminaries, ...requests])

    concatRequestes.subscribe((item) => {
      if (!item.number && !item.id) {
        return;
      }
      this.luminaries = this.luminaries.filter(({number}) => number !== item.number)
      this.maintenances = this.maintenances.filter(({id}) => id !== item.id)
      if (this.maintenances?.length) {
        return;
      }
      this.apiManager.getUserDetail(userId).subscribe((user) => {
        SessionStore.updateUserInfo(user, true);
      })
    }, (error) => {
      this.loading = {};
      this.apiManager.getUserDetail(userId).subscribe((user) => {
        SessionStore.updateUserInfo(user, true);
      });
      if (error && error.code <= 0) {
        return;
      }
      this.error = true;
    });
  }

  get pendingRequest() {
    return Object.values(this.loading).some((value) => value );
  }

  onActionClick(luminary, event) {
    this.luminaries = this.luminaries.filter(({_id}) => _id !== luminary._id)
    switch(event.id) {
      case DISCARD_CHANGES.id:
        this.store.dispatch(
          actionsLuminaries.remove({
            _id: luminary._id
          })
        );
        break;
      default:
        break;
    }
  }
}
