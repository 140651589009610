import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { FormatDatePipe } from '@ildes/pipes/format-date.pipe';
import { ContextualActionComponent } from '../contextual-action/contextual-action.component';
import { HumanizeStateComponent } from '../humanize-state/humanize-state.component';

const REQUEST_TYPES = {
  'SPECIAL': 'D.P. Especiales', // deprecado
  'TAX': 'Impuesto alumbrado público',
  'AP_SERVICES': 'D.P. Servicios A.P.', // deprecado
  'ENERGY_SERVICES': 'D.P. Servicios Energía', // deprecado
  'MAINTENANCE': 'Mantenimiento',
  'EXPANSION': 'Expansión',
  'OTHERS': 'Otros',
};
const ACCOUNTABLE_SECTION_TYPES = {
  'OPERATION': 'Operación',// deprecado
  'JURIDIC': 'Jurídica',// deprecado
  'THIRD': 'Terceros',// deprecado
  'OPERATIVE': 'Operativa',
  'ADMINISTRATIVE': 'Administrativa',
};
@Component({
    selector: 'list-pqr-item',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormatDatePipe,
        MatRippleModule,
        ContextualActionComponent,
        HumanizeStateComponent
    ],
    template: `
      <div class="flex-1" mat-ripple (click)="emitClickItem()">
        <div>
          <div>
            <div class="header padding-bottom-8">
              <div class="column-full-width luminary-number" >
                <span class="name">{{orderno}}</span>
              </div>
              <div>
                <span class="project">{{project}}</span>
              </div>
            </div>
            <div>
              <humanize-state [state]="state"></humanize-state>
            </div>
          </div>
          <div class="container">
            <div class="data">
              <div class="margin-right-16 basic-data">
                <div class="icon-text">
                  <span> {{typeDescription}} </span>
                </div>
                <div class="subtitle-description">
                  {{description}}
                </div>
                <div class="icon-text">
                  <mat-icon>calendar_today</mat-icon> <span> Fecha de radicado: {{registrationDate | formatDate}} </span>
                </div>
                <div class="icon-text" *ngIf="stimatedResponseDate">
                  <mat-icon>calendar_today</mat-icon>
                  <span> Fecha límite de respuesta: {{stimatedResponseDate | formatDate}}</span>
                </div>
                <div class="icon-text" *ngIf="responseDate">
                  <mat-icon>calendar_today</mat-icon>
                  <span> Fecha de respuesta: {{responseDate | formatDate}}</span>
                </div>
                <div class="icon-text">
                  <mat-icon>groups</mat-icon> <span> {{accountableSectionDescription}} </span>
                </div>
                <div *ngIf="address">
                  <div class="subtitle ubication icon-text">
                    <mat-icon>room</mat-icon> <span> {{address}} </span>
                  </div>
                </div>
                <div class="subtitle icon-text" *ngIf="neighborhood">
                  <span>Punto de referencia: {{neighborhood}} </span><span *ngIf="referencePoint">|  {{referencePoint}}</span>
                </div>
                <div class="subtitle icon-text" *ngIf="!neighborhood && referencePoint">
                  <span>Punto de referencia: {{referencePoint}}</span>
                </div>
                <div class="icon-text">
                  <mat-icon>face</mat-icon> <span>Contacto: {{this.petitioner.name}} </span>
                </div>
                <div class="subtitle icon-text" *ngIf="this.petitioner.address">
                  <span>Dirección de contacto: {{this.petitioner.address}}</span>
                </div>
                <div class="subtitle icon-text">
                  <span>Medio de contacto: {{contactMedia}}</span>
                </div>
              </div>
            </div>
            <div class="container-right">
              <div class="icon-text">
                <mat-icon>support_agent</mat-icon> <span class="capitalize"> {{receptor}} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions" *ngIf="actions">
        <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="onActionClick.emit($event)"></contextual-action>
      </div>
    `,
    styleUrls: ['./list-pqr-item.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPqrItemComponent {
  @Input() orderno;
  @Input() state;
  @Input() type;
  @Input() address;
  @Input() neighborhood;
  @Input() referencePoint;
  @Input() maintenance;
  @Input() project;
  @Input() registrationDate;
  @Input() responseDate;
  @Input() description;
  @Input() accountableSection;
  @Input() responseDays;
  @Input() receptor;
  @Input() actions;
  @Input() petitioner;
  @Output() onClickItem = new EventEmitter();
  @Output() onActionClick = new EventEmitter();

  get stimatedResponseDate() {
    return new Date(new Date(this.registrationDate).getTime() + parseInt(this.responseDays)*24*3600*1000);
  }
  get typeDescription() {
    return REQUEST_TYPES[this.type]
  }
  get accountableSectionDescription() {
    return ACCOUNTABLE_SECTION_TYPES[this.accountableSection]
  }

  get contactMedia() {
    return this.petitioner.mobilePhone || this.petitioner.phone || this.petitioner.otherContact;
  }

  emitClickItem() {
    this.onClickItem.emit();
  }
}

