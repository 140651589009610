import { BehaviourAdapter } from '../predictive-searcher/behaviour-adapter';
import {  NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { PredictiveSearcherComponent } from '../predictive-searcher/predictive-searcher.component';

@Component({
    selector: 'app-material-selector',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PredictiveSearcherComponent
    ],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => MaterialSelectorComponent),
      },
    ],
    template: `
      <predictive-searcher
        [label]="this.label"
        [formControl]="form"
        [service]="adapter">
      </predictive-searcher>`,
    styleUrls: ['./material-selector.component.css']
})

export class MaterialSelectorComponent extends BehaviourAdapter implements OnInit {
  @Input() label;

  constructor(
    private apiManager: ApiManagerService,
  ) {
    super();
    this.adapter = (data) => {
      return this.apiManager.listMaterials();
    };
  }

  ngOnInit(): void {
    this.subscribe();
  }

}
