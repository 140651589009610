import { DateSelectorComponent } from '@ildes/basic/date-selector/date-selector.component';
import { MatDividerModule } from '@angular/material/divider';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@Component({
    selector: 'app-close-pqr',
    standalone: true,
    imports: [
        CommonModule,
        SimpleHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        DateSelectorComponent,
        MatProgressBarModule,
    ],
    template: `
    <div class="container">
      <div class="scrollable-content">
        <simple-header title="Cerrar PQR" [back]="true" icon="close"></simple-header>
        <p>
          Complete los siguientes campos antes de cerrar el pqr
        </p>
        <mat-form-field appearance="outline" *ngIf="isAdministrativeSection">
          <mat-label>Número de radicado</mat-label>
          <input matInput [formControl]="number">
        </mat-form-field>
        <div class="section margin-top-8">
            <h3>Fecha de radicado</h3>
            <div>
                <date-selector [date]="responseDate" minYear=2000 maxYear=2080 (onChangeDate)="setResponseDate($event)" ></date-selector>
            </div>
        </div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Observaciones</mat-label>
          <textarea required rows="2" matInput [formControl]="pqrsResolutionObservations"></textarea>
          <mat-error>
            Es obligatorio indicar las observaciones
          </mat-error>
        </mat-form-field>
        <div *ngFor="let file of files">
          <div class="margin-8">
            <mat-icon class="margin-right-8" >picture_as_pdf</mat-icon>
            <span>{{file.name}}</span>
          </div>
          <mat-divider></mat-divider>
        </div>
        <button (click)="fileInput.click()" mat-flat-button *ngIf="!closed">
         <mat-icon>attachment</mat-icon> Adjuntar archivos
        </button>
        <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" multiple>
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <div *ngIf="!closed" class="align-center padding-top-8">
          <button [disabled]="loading" mat-raised-button color="primary" (click)="closePqr()" class="button-size-200">Cerrar PQR</button>
        </div>

      </div>
    </div>`,
    styleUrls: ['./close-pqr.component.css'],
})
export class ClosePqrComponent implements OnInit{
  files;
  pqr;
  number = new FormControl();
  pqrsResolutionObservations = new FormControl();
  responseDate = new Date();
  loading;
  constructor(
    private apiManager: ApiManagerService,
    private location: Location,
  ) {

  }
  ngOnInit(): void {
    this.pqr = history.state.pqr;
  }

  get isAdministrativeSection() {
    return this.pqr?.accountableSection == 'ADMINISTRATIVE';
  }

  get closed() {
    return this.pqr?.state === 'COMPLETED'
  }

  onFileSelected(event) {
    const { files } = event.target;

    if (!files?.length) {
      return;
    }

    this.files = [...this.files || [], ...Object.values(files)];
    event.target.value = "";
  }

  setResponseDate(responseDate) {
    this.responseDate = responseDate?.selectedDate;
  }

  closePqr() {
    const formData = new FormData();

    formData.append('state', 'COMPLETED');
    formData.append('observations', this.pqrsResolutionObservations.value);
    for (let i = 0; i < this.files?.length; i++) {
      formData.append(`files[${i}]`, this.files[i], this.files[i].name);
    }

    if (this.isAdministrativeSection)  {
      formData.append('number', this.number.value);
    }

    formData.append('responseDate', this.responseDate.toISOString());
    this.loading = true;
    this.apiManager.closePQR(this.pqr.id, formData).subscribe(()=> {
      this.loading = false;
      this.location.back();
    }, () => {
      this.loading = false;
    })
  }

  get fileList() {
    const filesIt = Object.values(this.files);

    return filesIt.map(({ name }: any) => name);
  }
}
