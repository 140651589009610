const MAIN_ROUTES = {
    TECHNICIAN: '/app/dashboard/maintenances',
    LEADER: '/app/list-orders/maintenances',
    ADMIN: '/app/control-panel',
    ADMINISTRATIVE_ASSISTANT: '/app/list-orders/maintenances',
    BUYANDLOGISTIC: '/app/control-panel',
    DRIVER: '/app/dashboard/maintenances',
    NOTHING_ELSE: '/app/dashboard',
}
export {
    MAIN_ROUTES
}
