import { Injectable } from '@angular/core';
import { StorageService } from '@ildes/services/storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class UnsyncDataService {
  public storageName;
  constructor(
    public storageService: StorageService,
    storageName,
  ) {
    this.storageName = storageName;
  }

  getItems(raw = false) {
    const items = this.storageService.getItem(this.storageName);

    if (raw) {
      return items;
    }
    return items?.map(this.normalize);
  }

  normalize(item) {
    return item;
  }

  addItem(item) {
    let list = this.storageService.getItem(this.storageName) || [];

    if (item.id && list.find(({id}) => item.id === id)) {
      this.update(item);

      return item.id;
    }
    const id = `${item.id || parseInt(''+Math.random()*100000)}`;

    list = [...list, {...item, id, ...item.id && { update: true }}];
    this.storageService.setItem(this.storageName, list);
    this.storageService.setItem(`${this.storageName}-blacklist`, list.map(({id}) => id));

    return id;
  }

  clearItem(item) {
    this.removeFromBlackList(item);

    return this.removeItem(item);
  }

  removeItem(item) {
    let list = this.storageService.getItem(this.storageName) || [];

    list = list?.filter( (itemList) => {
      return item.id !== itemList.id;
    });
    this.storageService.setItem(this.storageName, list);

    return list;
  }

  removeFromBlackList(item) {
    let blackList = this.storageService.getItem(`${this.storageName}-blacklist`)

    blackList = blackList?.filter( (itemList) => {
      return item.id !== itemList;
    });
    this.storageService.setItem(`${this.storageName}-blacklist`, blackList);
  }

  update(item) {
    let list = this.storageService.getItem(this.storageName) || [];

    list = list?.map( (itemList) => {
      if (item.id === itemList.id) {
        return {...itemList, ...item};
      }
      return itemList;
    })
    this.storageService.setItem(this.storageName, list);
  }

  findItem(id) {
    let list = this.storageService.getItem(this.storageName) || [];

    return list?.find( (item) => `${item.id}` === `${id}`);
  }

  private isInBlackList(id) {
    const blacklist = this.storageService.getItem(`${this.storageName}-blacklist`) || [];

    return blacklist.indexOf(id) >= 0;
  }

  // se elimina de cualquier filtro, hay que ignorar por filtro y no de todos.
  // ejemplo: {"metadata":{"timestamp":1723483114082},"value":["66ba2b20bc6766af0a7ce87a"]} en el blacklist de unsync-modernizations
  public updateUnsyncData(state, data) {

    if (state === 'NEW') {
      this.cleanBlackList();
    } else {
      this.cleanData();
    }
  }

  public cleanBlackList() {
    let blacklist = this.storageService.getItem(`${this.storageName}-blacklist`) || [];

    blacklist = blacklist.filter(( itemId ) => {
      const unsyncItem = this.findItem(itemId);

      return unsyncItem && unsyncItem.state !== 'CLOSED';
    });

    this.storageService.setItem(`${this.storageName}-blacklist`, blacklist);
  }

  cleanData() {
    let list = this.storageService.getItem(this.storageName) || [];

    list = list?.filter( (itemList) => {
      return itemList.unsyc || itemList.state !== 'CLOSED';
    });
    this.storageService.setItem(this.storageName, list);

    return list;
  }

  public filterData(data) {

    const filterData = data.data.filter(({id}) => !this.isInBlackList(id));
    // let list = this.storageService.getItem(this.storageName) || [];
    //const filterData = data.data.map((item) => list.find(({id}) => item.id === id) || item );
    return {
      data: filterData,
      pagination: data.pagination
    }
  }

  public addUnsyncData(data) {
    let list = this.storageService.getItem(this.storageName) || [];

    list = list
      .filter(( { state, unsync } ) => state === 'CLOSED' && !unsync)
      .map(( item ) => ({...item, dirty: true}));

    return {
      data: [...list, ...data.data],
      pagination: data.pagination
    }
  }

}
