import { Injectable } from '@angular/core';
import { ApiManagerService } from './api-manager.service';
import { StorageService } from './storage-service.service';
import { UnsyncDataService } from './unsync-data.service';
import { from, of, zip } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import questionsMaintenance from '../views/repair-maintenance/questions-maintenance';
import { PhotosUtils } from '@ildes/utils/photos';
import { SessionStore } from '@ildes/stores/session.store';

const STORAGE_NAME = 'unsync-modernizations';

@Injectable({
  providedIn: 'root'
})
export class UnsyncModernizationsService extends UnsyncDataService {
  private photosHandler: PhotosUtils;
  constructor(
    private apiManager: ApiManagerService,
    public storageService: StorageService,
  ) {
    super(storageService, STORAGE_NAME);

    this.photosHandler = new PhotosUtils();
  }

  sync(modernizationData, update = false) {
    const inputData = {...modernizationData};
    let performInitialPhotos = of(null);
    let performFinalPhotos = of(null);

    if (modernizationData.photos.initial) {
      performInitialPhotos = from(this.photosHandler.toBase64(modernizationData.photos.initial));
    }
    if (modernizationData.photos.final) {
      performFinalPhotos = from(this.photosHandler.toBase64(modernizationData.photos.final));
    }

    return zip(
      performInitialPhotos,
      performFinalPhotos
    ).pipe((
      concatMap(([initialPhotos, finalPhotos]) => {
        const photos:any = {};

        if (initialPhotos) {
          photos.initial = initialPhotos
        }
        if (finalPhotos) {
          photos.final = finalPhotos
        }
        inputData.photos = photos;
        if (inputData.luminary.id) {
          inputData.luminary = { number: inputData.luminary.number, id: inputData.luminary.id};
        } else {
          inputData.luminary.photos = finalPhotos;
        }
        inputData.newLuminaryNumber = inputData.newLuminaryNumber;
        inputData.project = { id: inputData.project.id };
        delete inputData.answersDailyQuestionary;
        const id = inputData.id;
        delete inputData.id;
        delete inputData.selectedProject;
        inputData.questionary = questionsMaintenance.map((question) => {
          const element = inputData.answersSecurityQuestionary.find(({ id }) => id === question.id);

          return {
            id: question.id,
            title: question.title,
            value: !!element?.value,
            observations: element?.observations
          }
        })
        const service = update ? this.apiManager.updateModernization(id, inputData): this.apiManager.createModernization(inputData);
        return service.pipe(
          tap(
            {
              next: () => {
                if (modernizationData.id) {
                  //this.removeItem(modernizationData);
                  this.update({...modernizationData, unsync: false });
                }
              },
              error: (error) => {
                if (!error.code || error.code > 0) {
                  return;
                }
                this.addItem({
                  ...modernizationData,
                  unsync: true
              });
              },
            }
        ));
      })
    ));
  }

  public fetchModernizations(params, cache) {
    const sessionStore = SessionStore.getInstance();
    const userId = sessionStore.get().user.id;
    return this.apiManager.getModernizations({assigned: userId, ...params}, { error: true, timeout: true, cache})
      .pipe(
        tap((data) => !cache && this.updateUnsyncData(params.state, data)),
        map((data) => params.state !== 'CLOSED' ?  this.filterData(data): data),
        map((data) => params.state === 'CLOSED' ? this.addUnsyncData(data) : data),
      )
  }
}
