<div class="container">
  <div class="scrollable-content">
    <ng-content></ng-content>
    <form [formGroup]="searchFormGroup" *ngIf="!humanizeFilter?.length">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Buscar por nombre</mat-label>
        <input matInput (keyup.enter)="findByName($event)" formControlName="complexName">
      </mat-form-field>
    </form>
    <div class="inline-flex">
      <filter-list *ngIf="humanizeFilter?.length" [criterias]="humanizeFilter"></filter-list>
      <button *ngIf="humanizeFilter?.length" mat-button (click)="removeFilters()">Eliminar filtros</button>
    </div>
    <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div [ngClass]="{'set-padding': this.paddingBottom}" class="scrollable-content" (scroll)="onScroll($event)">
      <div *ngFor="let user of sortedUsers" class="item">
        <list-user-item
          (onLinkClick)="gotoUser(user)"
          [name]="user.name"
          [surname]="user.surname"
          [role]="user.role?.description"
          [email]="user.email"
          [phone]="user.phone"
          [photo]="user.photo"
          class="padding-top-8">
          <ng-template #actions>
            <mat-checkbox *ngIf="selector" class="margin-8" (change)="onChange($event, user)" [checked]="user.selected" [disabled]="user.disabled || (maxUserSelectedReached && !user.selected)"></mat-checkbox>
            <contextual-action [actions]="actionEvents" (actionEvent)="onDoAction($event, user)"></contextual-action>
            <!-- <button mat-icon-button *ngFor="let action of actionEvents" (click)="onDoAction(action, user)">
              <mat-icon>{{action.icon}}</mat-icon>
            </button> -->
          </ng-template>
        </list-user-item>
        <mat-divider></mat-divider>
      </div>
    </div>
    <ng-content select="[fab]"></ng-content>
  </div>
  <mat-paginator
    *ngIf="scrollMode!='infinite'"
    [length]="pagination?.totalDocs"
    pageSize="20"
    (page)="paginate($event)">
  </mat-paginator>
</div>
