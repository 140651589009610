import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TagAutomaticColorComponent } from '../tag-automatic-color/tag-automatic-color.component';
import { ContextualActionComponent } from '../contextual-action/contextual-action.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TagAutomaticColorComponent,
    ContextualActionComponent,
    MatRippleModule
  ],
  selector: 'list-luminary-item',
  templateUrl: './list-luminary-item.component.html',
  styleUrls: ['./list-luminary-item.component.css']
})
export class ListLuminaryItemComponent implements OnInit {

  @Input() number;
  @Input() newLuminaryNumber;
  @Input() project;
  @Input() address;
  @Input() ubication;
  @Input() neighborhood;
  @Input() technology;
  @Input() actions;
  @Input() power;
  @Input() newTechnology;
  @Input() newPower;
  @Input() luminaryOfTemporalUse;
  @Input() pathObservations;
  @Output() onLinkClick =  new EventEmitter();
  @Output() onActionClick =  new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  linkClicked() {
    this.onLinkClick.emit()
  }

  onClickActionEvent(event) {
    this.onActionClick.emit(event);
  }
}
