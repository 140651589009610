import { ApiManagerService } from '@ildes/services/api-manager.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SimpleHeaderComponent } from '@ildes/basic/simple-header/simple-header.component';
import { DateSelectorComponent } from '@ildes/basic/date-selector/date-selector.component';
import { HumanizeStateComponent } from '@ildes/basic/humanize-state/humanize-state.component';
import { FormatDatePipe } from '@ildes/pipes/format-date.pipe';
import { PathNamePipe } from '@ildes/pipes/path-name.pipe';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FileHelperService } from '@ildes/services/file-helper.service';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const REQUEST_TYPES = {
  'SPECIAL': 'D.P. Especiales', // deprecado
  'TAX': 'Impuesto alumbrado público',
  'AP_SERVICES': 'D.P. Servicios A.P.', // deprecado
  'ENERGY_SERVICES': 'D.P. Servicios Energía', // deprecado
  'MAINTENANCE': 'Mantenimiento',
  'EXPANSION': 'Expansión',
  'OTHERS': 'Otros',
};
const ACCOUNTABLE_SECTION_TYPES = {
  'OPERATION': 'Operación',// deprecado
  'JURIDIC': 'Jurídica',// deprecado
  'THIRD': 'Terceros',// deprecado
  'OPERATIVE': 'Operativa',
  'ADMINISTRATIVE': 'Administrativa',
};

const STATES = {
  NEW: 'Abierto',
  COMPLETED: 'Cerrado',
};

const RESPONSE_TYPES = {
  OPERATION: 'Minuta de Operación',
  EXPANSION: 'Minuta de Expansión',
  THIRD: 'Minuta de Remisión a Terceros',
  IAP: 'Minuta de IAP',
  OTHERS: 'Otros',
};

const ORIGIN_TYPES =  {
  PHONE: 'Telefónico',
  'FACE_TO_FACE': 'Vervbal',
  'WRITED': 'Escrito'
}

const SECTOR_TYPES = {
  URBAN: 'Urbano',
  RURAL: 'Rural',
}

@Component({
    selector: 'app-detail-pqr',
    standalone: true,
    imports: [
        HumanizeStateComponent,
        CommonModule,
        SimpleHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        DateSelectorComponent,
        FormatDatePipe,
        MatRippleModule,
        RouterModule,
        PathNamePipe,
        MatProgressBarModule
    ],
    template: `
    <div class="container">
      <div class="scrollable-content">
        <simple-header class="padding-8 background" title="Detalle de PQR" [back]="true" icon="close"></simple-header>
        <div class="section" *ngIf="pqr">
          <h3>Datos de la Petición</h3>
          <div class="padding-top-8 padding-bottom-8 action-element-container">
            <div class="action-text-title">
              <strong>Número de orden: </strong> {{this.pqr.orderno}}
            </div>
            <div  matRipple role="button" class="actionable action-button" (click)="openAttentionFile()">Ver documento de atención PQR</div>
          </div>
          <mat-divider></mat-divider>
          <mat-progress-bar mode="indeterminate" *ngIf="loadingAttentionFile"></mat-progress-bar>
          <div class="padding-top-8 padding-bottom-8 action-element-container">
            <div class="action-text-title">
              <strong>Estado: </strong> <humanize-state [state]="this.pqr.state"></humanize-state>
            </div>
            <div *ngIf="this.pqr.state !== 'COMPLETED' && this.pqr.accountableSection !== 'OPERATIVE'" matRipple role="button" class="actionable action-button" [routerLink]="['/app/close-pqr']" [state]="{ pqr }">Cerrar</div>
          </div>
          <mat-divider></mat-divider>
          <p class="padding-top-8" *ngIf="this.pqr.state === 'COMPLETED'">
            <strong>Fecha de respuesta: </strong>{{this.pqr.responseDate | formatDate}}
          </p>
          <mat-divider *ngIf="this.pqr.state === 'COMPLETED'"></mat-divider>
          <p class="padding-top-8" *ngIf="this.pqr.state === 'COMPLETED' && this.pqr.accountableSection !== 'OPERATIVE'">
            <strong>Número de radicado: </strong>{{this.pqr.caseNumber || 'No informado'}}
          </p>
          <mat-divider  *ngIf="this.pqr.state === 'COMPLETED' && this.pqr.accountableSection !== 'OPERATIVE'"></mat-divider>
          <p class="padding-top-8" *ngIf="this.pqr.state === 'COMPLETED' && this.pqr.accountableSection !== 'OPERATIVE'">
            <strong>Observaciones de la resolución: </strong>{{this.pqr.resolutionObservations || 'No informado'}}
          </p>
          <mat-divider *ngIf="this.pqr.state === 'COMPLETED' && this.pqr.accountableSection !== 'OPERATIVE'"></mat-divider>
          <section *ngIf="this.pqr.attachments?.length">
            <strong>Adjuntos de la resolución</strong>
            <div class="attachment-list margin-top-8" *ngIf="!downloading">
              <div *ngFor="let fileName of this.pqr.attachments"  class="actionale attachment" matRipple (click)="open(fileName)">
                <div>
                  <mat-icon>picture_as_pdf</mat-icon>
                </div>
                <div class="attachment-name padding-left-8">{{fileName | pathName}}</div>
              </div>
            </div>
            <mat-divider></mat-divider>
          </section>
          <p class="padding-top-8">
            <strong>Área encargada: </strong> {{accountableSectionTypes[this.pqr.accountableSection]}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Funcionario que recibió la petición: </strong> {{this.pqr.receptor.name}} {{this.pqr.receptor.surname}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Tipo de solicitud: </strong> {{requestTypes[this.pqr.requestType]}}
          </p>
          <div class="padding-top-8 padding-bottom-8 action-element-container" *ngIf="maintenance">
            <div class="action-text-title">
              <strong>Mantenimiento: </strong>{{this.maintenance.orderno}}
            </div>
            <div  matRipple role="button" class="actionable action-button" (click)="openMaintenance()">Ver detalle</div>
          </div>
          <mat-progress-bar mode="indeterminate" *ngIf="loadingMaintenance"></mat-progress-bar>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Días máximos de respuesta: </strong> {{this.pqr.responseDays}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Fecha de radicado: </strong> {{this.pqr.registrationDate | formatDate}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Tipo de respuesta: </strong> {{responseType[this.pqr.responseType]}}
          </p>
          <mat-divider></mat-divider>
        </div>
        <div class="section margin-top-8" *ngIf="pqr">
          <h3>Datos de peticionario</h3>
          <p class="padding-top-8">
            <strong>Cédula: </strong> {{this.pqr.petitioner.identification || 'No informado'}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Dirección: </strong> {{this.pqr.petitioner.address || 'No informado'}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Teléfono fijo: </strong> {{this.pqr.petitioner.phone || 'No informado'}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Celular: </strong> {{this.pqr.petitioner.mobilePhone || 'No informado'}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Email: </strong> {{this.pqr.petitioner.email || 'No informado'}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Otro medio de contacto: </strong> {{this.pqr.petitioner.otherContact || 'No informado'}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Medio en el que presentó la solicitud: </strong> {{originTypes[this.pqr.origin]}}
          </p>
          <mat-divider></mat-divider>
          <p class="padding-top-8">
            <strong>Presentó anexos: </strong> {{this.pqr.hasAttachments ? 'Sí': 'No'}}
          </p>
          <mat-divider></mat-divider>
        </div>
        <div class="section margin-top-8" *ngIf="pqr">
          <h3>Información de la actividad</h3>
          <p class="padding-top-8">
            <strong>Observaciones: </strong> {{this.pqr.description}}
          </p>
          <mat-divider></mat-divider>
          <div class="margin-top-16">
            <h4 *ngIf="this.pqr.address">Ubicación y punto de referencia</h4>
            <div class="margin-left-8" *ngIf="this.pqr.requestType ==='MAINTENANCE' || this.pqr.requestType ==='EXPANSION'">
              <p class="padding-top-8">
                <strong>Sector: </strong> {{sectorTypes[this.pqr.sector]}}
              </p>
              <mat-divider></mat-divider>
              <p class="padding-top-8">
                <strong>Dirección: </strong> {{this.pqr.address}}
              </p>
              <mat-divider></mat-divider>
              <p class="padding-top-8">
                <strong>Barrio: </strong> {{this.pqr.neighborhood}}
              </p>
              <mat-divider></mat-divider>
              <p class="padding-top-8">
                <strong>Punto de referencia: </strong> {{this.pqr.referencePointDescription}}
              </p>
            </div>
          </div>
        </div>
    </div>
    </div>`,
    styleUrls: ['./detail-pqr.component.css'],
})
export class DetailPqrComponent implements OnInit {
  downloading;
  pqr;
  accountableSectionTypes = ACCOUNTABLE_SECTION_TYPES;
  requestTypes = REQUEST_TYPES;
  states = STATES;
  responseType = RESPONSE_TYPES;
  originTypes = ORIGIN_TYPES;
  sectorTypes = SECTOR_TYPES;
  loading;
  maintenance;
  loadingMaintenance;
  loadingAttentionFile;
  constructor(
    private dialog: MatDialog,
    private apiManager: ApiManagerService,
    private fileHelper: FileHelperService
  ) {

  }
  ngOnInit(): void {
    const id = history.state.pqr?.id;

    this.apiManager.getPqrDetail(id).subscribe((data: any) => {
      this.loading = false;
      this.pqr = data.data;
      this.maintenance = this.pqr.maintenance;
    }, () => {
      this.loading = false;
    })
  }

  get isAdministrativeSection() {
    return this.pqr?.accountableSection == 'ADMINISTRATIVE';
  }

  get closed() {
    return this.pqr?.state === 'COMPLETED'
  }

  open(file) {
    this.downloading = true;
    this.fileHelper.download(file).subscribe(async (data: any) => {
      this.downloading = false;
    }, () => {
      this.downloading = false;
    })
  }

  openMaintenance() {
    this.loadingMaintenance = true;
    this.apiManager.getDetailMaintenance(this.maintenance.id).subscribe((data:any) => {
      this.loadingMaintenance = false;
      this.dialog.open(OrderDetailComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data: {
          maintenance: data
        }
      });
    }, () => {
      this.loadingMaintenance = false;
    });
  }

  openAttentionFile() {
    this.loadingAttentionFile = true;
    this.fileHelper.downloadPdf(this.pqr.documentPath).subscribe(() => {
      this.loadingAttentionFile = false;
    }, () => {
      this.loadingAttentionFile = false;
    })
  }
}
