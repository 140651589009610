<div class="container">
  <div class="scrollable-content">
    <simple-header title="Editar datos de poste y luminaria" [back]="true" icon="close"></simple-header>
    <form [formGroup]="formGroup" class="padding-top-8 padding-bottom-8">
      <div class="inline-flex">
        <mat-form-field appearance="outline" class="column margin-right-8">
          <mat-label>Calibre de red eléctrica</mat-label>
          <input matInput formControlName="conductorGauge">
        </mat-form-field>
        <dropdown-selector label="Red eléctrica" [types]="electricNetwork" [(selected)]="selectedElectricNetwork"></dropdown-selector>
      </div>

      <div class="inline-flex">
        <mat-form-field appearance="outline" class="column margin-right-8">
          <mat-label>Número de transformador</mat-label>
          <input matInput maxlength="20" formControlName="transformerNumber">
        </mat-form-field>
        <dropdown-selector class="column" label="Material red electrica" [types]="electricalNetworkMaterialTypes" [(selected)]="selectedElectricalNetworkMaterialType"></dropdown-selector>
      </div>

      <dropdown-selector class="column" label="Instalación de red eléctrica" [types]="powerNetworkInstallationTypes" [(selected)]="selectedElectricalNetworkInstallationType"></dropdown-selector>
      <dropdown-selector class="column" label="Tipo de transformador" [types]="useTransformerTypes" [(selected)]="selectedUseTransfromerType"></dropdown-selector>
      <dropdown-selector class="column" label="Instalación transformador" [types]="transformerInstallationTypes" [(selected)]="selectedTransformerInstallationType"></dropdown-selector>
      <div class="align-center">
        <div class="padding-bottom-8">
          <button (click)="save()" [disabled]="formGroup.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
        </div>
        <div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </form>
  </div>
</div>
