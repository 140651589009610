import { Component, OnInit, Inject, ViewChild, ElementRef  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SessionStore } from '@ildes/stores/session.store';
import { Store } from '@ngrx/store';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import { ADD_MATERIAL_ACTIVITY, EXTRACT_MATERIAL_ACTIVITY, CONNECTIONS_ADJUST, CLEAN_ELECTRONIC, CLEAN_OPTIC } from '@ildes/config/constants';

const CLOSE = { id:'close', icon:'close' };

@Component({
  selector: 'app-use-material-dialog',
  templateUrl: './use-material-dialog.component.html',
  styleUrls: ['./use-material-dialog.component.css']
})
export class UseMaterialDialogComponent implements OnInit {
  initActivities;
  activities;
  maintenance;
  headerActions = [CLOSE];
  availableMaterials = [];
  usedMaterials = [];
  validSteps = [];
  extractedMaterials = [];
  description = '';
  presetMaterials = false;
  loading = false;
  pendingSyncUsedMaterials = false;
  materialSeacherForm = new UntypedFormGroup({
    materialName: new UntypedFormControl(),
  });
  searchLuminaryTypesAdapter;
  luminaryOfTemporalUse = false;
  newLuminaryNumberForm;
  newLuminaryNumber;
  technology;
  power;
  powerForm;
  technologyForm;
  searchMaterialAdapter;
  @ViewChild('stepper') stepper: any;
  formDescription = new UntypedFormGroup({
    description: new UntypedFormControl('', [Validators.required])
  });
  constructor(
    private store: Store,
    private apiManager: ApiManagerService,
    public dialogRef: MatDialogRef<UseMaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.newLuminaryNumber = data.newLuminaryNumber;
    this.newLuminaryNumberForm = new UntypedFormControl(data.newLuminaryNumber);
    this.luminaryOfTemporalUse = data.luminaryOfTemporalUse;
    this.maintenance = data.maintenance;
    this.availableMaterials = (data.availableMaterials || []).map((material) => (
      {...material}
    ));
    const currentUsedMaterials = data.usedMaterials;
    this.description = data.description;
    this.extractedMaterials = data.extractedMaterials || [];
    this.presetMaterials = !!currentUsedMaterials;
    this.usedMaterials = this.availableMaterials.map((availableMaterial) => {
      const currentUsedMaterial = (currentUsedMaterials || [])
        .find((usedMaterial) => usedMaterial.reference?.id === availableMaterial.reference.id);

      return {
        amount: currentUsedMaterial?.amount || 0,
        reference: availableMaterial.reference,
        max: availableMaterial.amount + (currentUsedMaterial?.amount || 0)
      };
    });

    (currentUsedMaterials || []).forEach((usedMaterial) => {
      const usedInAvailableMaterials = this.usedMaterials
        .find((currentUsedMaterial) => currentUsedMaterial.reference?.id === usedMaterial.reference.id);

      if (!usedInAvailableMaterials) {
        this.usedMaterials.push({
          amount: usedMaterial.amount,
          reference: usedMaterial.reference,
          max: usedMaterial.amount
        });
      }
    });

    this.technology = data.newTechnology;
    this.power = data.newPower;
    this.technologyForm = new UntypedFormControl(data.newTechnology ? { typed: data.newTechnology, selected: { name: data.newTechnology }} : '');
    this.powerForm  = new UntypedFormControl( data.newPower, [Validators.pattern(new RegExp('^[0-9]+$'))]);

    if (this.presetMaterials) {
      this.activities = data.activities;
    } else {
      if (data.activities) {
        let tempDefaultActivityList = [...this.defaultActivityList];

        this.initActivities = data.activities.filter(( data ) => {
          const index = tempDefaultActivityList.findIndex(({id}) => id === data.id);

          if (index >= 0) {
            tempDefaultActivityList.splice(index, 1);

            return false;
          }

          return true;
        });
      } else if (this.maintenance.type?.id === 'PREVENTIVE') {
        this.initActivities = [
          CONNECTIONS_ADJUST, CLEAN_ELECTRONIC, CLEAN_OPTIC
        ];
      }
    }

  }

  ngOnInit(): void {
    this.searchLuminaryTypesAdapter = (data) => {
      return this.apiManager.getFromCatalog('technologies', data)
    };
    this.searchMaterialAdapter = () => {
      return this.apiManager.listMaterials();
    };
  }


  get defaultActivityList() {
    const activitiesFromUsedMaterials = this.usedMaterials.map((data) => {
      return Array.from(Array(data.amount)).map(() => ( {...ADD_MATERIAL_ACTIVITY, extraData: data.reference.name }));
    }).flat();

    const activitiesFromRetiredMaterials = this.extractedMaterials.map((data) => {
      return Array.from(Array(data.amount)).map(() => ( {...EXTRACT_MATERIAL_ACTIVITY, extraData: data.reference.name }));
    }).flat();

    return [...activitiesFromUsedMaterials, ...activitiesFromRetiredMaterials];
  }

  updateActivities(activities) {
    this.activities = activities;
  }

  close(commit = false) {
    this.dialogRef.close({
      newLuminaryNumber: this.newLuminaryNumberForm.value,
      luminaryOfTemporalUse: this.luminaryOfTemporalUse,
      activities: this.activities,
      usedMaterials: this.cleanListUsedMaterial,
      extractedMaterials: this.cleanListRetiredMaterial,
      description: this.formDescription.controls?.description?.value,
      technology: this.technologyForm.value?.typed,
      power: this.powerForm.value,
      commit
    });
  }

  private performRetiredMaterial() {
    return this.cleanListUsedMaterial.filter((material) =>
      material.amount > 0
    ).map((material) => {
      return {
        amount: material.amount,
        reference: material.reference,
        max: material.amount
      };
    });
  }

  get causeOfNotextractedMaterials() {
    if (!this.extractedMaterials?.length) {
      return true;
    } else {
      return this.extractedMaterials.find((retiredMaterial) => retiredMaterial.amount === 0);
    }
  }

  headerAction() {
    this.close(false);
  }

  setUsedMaterials() {
    //this.extractedMaterials = this.performRetiredMaterial();
    this.stepper.selected.completed = true;
    this.stepper.next()
  }

  continue() {
     this.stepper.selected.completed = true;
     this.stepper.next();
  }

  confirm() {
    this.close(true);
  }

  resetMaterial() {
    // if (this.maintenance.localUsedMaterials) {
    //   this.updateMaintenance();
    //   this.presetMaterials = false;
    //   return;
    // }

    if (this.maintenance.pendingSync?.repairmentData) {
      this.updateMaintenance();
      this.presetMaterials = false;

      return;
    }

    this.loading = true;
    this.apiManager.undoMaterials(this.maintenance.id)
      .subscribe(() => {
        this.presetMaterials = false;
        this.loading = false;
        this.updateUser();
        this.updateMaintenance();
      }, () => {
        this.loading = false;
      })
  }

  updateMaintenance() {
    const payload = {
      maintenanceId: this.maintenance.id,
      usedMaterials: undefined,
      extractedMaterials: undefined,
      description: undefined,
      localUsedMaterials: undefined,
      newTechnology: undefined,
      newPower: undefined,
      newLuminaryNumber: this.newLuminaryNumberForm.value,
      luminaryOfTemporalUse: this.luminaryOfTemporalUse,
    };

    this.store.dispatch(
      actions.setRepairmentData(payload)
    );
  }

  updateUser() {
    this.loading = true;
    const callUserDetail = this.apiManager.getUserDetail(SessionStore.getInstance().get().user.id);

    callUserDetail.subscribe((data: any) => {
      SessionStore.updateUserInfo({
        confirmedMaterials: data.confirmedMaterials,
        assignedMaterials: data.assignedMaterials
      });
      this.loading = false;
    });

    return callUserDetail;
  }

  get cleanListRetiredMaterial() {
    return this.extractedMaterials.filter(({ amount }) => amount > 0);
  }

  get cleanListUsedMaterial() {
    return this.usedMaterials.filter(({ amount }) => amount > 0);
  }

  onSelectedValue({option}) {
    if (this.extractedMaterials.find(({ reference }) => reference.name === option.value.name)) {
      return;
    }
    const material = {
      amount: 1,
      reference: option.value
    }

    this.extractedMaterials = [...this.extractedMaterials, material];
    this.materialSeacherForm.reset();
  }

  addUnknownMaterial() {
    const name = this.materialSeacherForm.controls?.materialName?.value?.typed;

    if (this.extractedMaterials.find(({ reference }) => reference.name === name)) {
      return;
    }
    const material = {
      amount: 1,
      reference: {
        name
      }
    };
    this.extractedMaterials = [...this.extractedMaterials, material];
    this.materialSeacherForm.reset();
  }

  get typedRetiredMaterial() {
    return this.materialSeacherForm.controls?.materialName?.value?.typed
  }
}
