import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'pathName',
  standalone: true,
})
export class PathNamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const cleanedValue = value?.trim();
    if(!cleanedValue) {
      return '';
    }
    const parts = value.split('/');

    return parts[parts.length - 1];
  }

}
