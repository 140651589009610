import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-bottom-navigation',
    standalone: true,
    imports: [
      MatIconModule,
      CommonModule,
      RouterModule,
      MatRippleModule,
    ],
    template: `
      <div *ngFor="let button of buttons" class="button">
        <div  mat-ripple [routerLink]="[button.url]" routerLinkActive="active-link">
            <mat-icon>{{button.icon}}</mat-icon>
            <div class="text">{{button.text}}</div>
        </div>
      </div>
    `,
    styleUrls: ['./bottom-navigation.component.css'],
})
export class BottomNavigationComponent {

  @Input() buttons;
}
