import { Component, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { ToastService } from '@ildes/services/toast.service';


declare const google;

import {
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  FormBuilder,
  ControlValueAccessor,
  Validators,
  NG_VALIDATORS,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'input-place',
  templateUrl: './input-place.component.html',
  styleUrls: ['./input-place.component.css'],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPlaceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPlaceComponent),
      multi: true
    }
  ]
})
export class InputPlaceComponent implements AfterViewInit, ControlValueAccessor, OnDestroy  {

  private _detailPlaceService;
  private _autoCompleteService;
  public places;
  private sessionToken;
  public address;
  private to;
  private currentPlace;
  loadingGeolocation;
  @Input() hideGeopositionDetail;
  @Input() required;
  @Input() public appearance;
  @Input() public label;
  @Input() public placeholder;
  @Input() public place;
  @Input() requiredGeoposition;

  subscriptions: Subscription[] = [];
  formGroup;
  typed;
  lat;
  lng;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private toast: ToastService,
  ) {
    this.address = '';
    this.places = [];
    this.appearance = 'outline';
  }

  @ViewChild('inputMap') inputView: ElementRef;
  @Output() selectPlace: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    let inputValidator;
    let latValidator;
    let lngValidator;

    if (this.requiredGeoposition) {
      inputValidator = Validators.required;
      latValidator = Validators.required;
      lngValidator = Validators.required;
    }

    if (this.required) {
      inputValidator = Validators.required;
    }

    this.typed = new FormControl('', inputValidator);
    this.lat = new FormControl('', latValidator);
    this.lng = new FormControl('', lngValidator);

    this.formGroup = new UntypedFormGroup({
      typed: this.typed,
      lat: this.lat,
      lng: this.lng,
      place: new FormControl({})
    });

    this.subscriptions.push(
      this.typed.valueChanges.subscribe(async (value) => {
          if (!value) {
            return;
          }
          if (value.description) {
            this.place = await this.getPlaceDetail(value);
            this.formGroup.controls['typed'].setValue(value.description);
            this.formGroup.controls['lat'].setValue(this.place.lat);
            this.formGroup.controls['lng'].setValue(this.place.lng);
            const valueFormatted = {
              typed: value.description,
              place: this.place,
              lat: this.place.lat,
              lng: this.place.lng,
            };
            this.selectPlace.emit(valueFormatted)
            this.onChange(valueFormatted);

            this.onTouched();
          } else {
            this.onChange({
              typed: value,
              place: null,
            });

            this.onTouched();
          }
      })
    );
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(async (value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngAfterViewInit() {
    const input = this.inputView.nativeElement;
    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    this.sessionToken = sessionToken;
    this._autoCompleteService = new google.maps.places.AutocompleteService();

    this._detailPlaceService = new google.maps.places.PlacesService(input);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  _getAutocompletePlaces(value = '') {
    value = value.trim();

    if (value.length < 3) {
      return;
    }
    if (this.to) {
      clearTimeout(this.to);
    }

    this.to = setTimeout(async () => { //TODO: hacerlo con debounce.
      const promise = new Promise((resolve, reject) => { // hay que hacerlo así. Si se asignan directamente en el callback no se refresca el template inmediatamente
        this._autoCompleteService.getPlacePredictions({
          input: value,
          sessionToken: this.sessionToken
        }, (predictions) => {
          resolve(predictions);
        });

      });

      const predictions = await promise;
      this.places = predictions;
    }, 400);
  }

  private async getPlaceDetail(value) {
    const promise = new Promise((resolve, reject) => { // hay que hacerlo así. Si se asignan directamente en el callback no se refresca el template inmediatamente
      this._detailPlaceService.getDetails({
        placeId: value.place_id
      }, (results, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        }
      });
    });

    const results: any = await promise;

    this.address = value.description;
    this.places = [];
    const place = Object.assign(value, {
      lat: results.geometry.location.lat(),
      lng: results.geometry.location.lng()
    });

    return place;
  }

  public onblur() {
    setTimeout(() => {
      this.places = [];
    }, 200)
  }

  onIconClick() {
    this.address = '';
    this.inputView.nativeElement.value = '';
    this.place = {
      lat: '',
      lng: '',
    }
    this.formGroup.controls['lat'].setValue(this.place.lat);
    this.formGroup.controls['lng'].setValue(this.place.lng);
    this.typed.setValue('');
    this.places = [];
    this.cancel.emit();
  }

  displayWith(value) {
    return value?.description || value;
  }

  get value(): any {
    return this.currentPlace;
  }

  set value(value: any) {
    if (value) {
      this.place = value.place;
      this.address = value.typed;
      this.formGroup.setValue(value);
      // this.formGroup.controls['lat'].setValue(value.place?.lat);
      // this.formGroup.controls['lng'].setValue(value.place?.lng);
      // this.formGroup.controls['typed'].setValue(value.typed);
    }
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.formGroup.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.formGroup.valid ? null : { valid: false } ;
  }

  public async setPosition() {
    this.loadingGeolocation = true;

    const positionData: (void | Position) = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 16000,
      maximumAge: 1
    }).catch((e) => {
      this.loadingGeolocation = false;
      this.toast.show('No se pudo obtener tu ubuación', 'error');

      return;
    });
    this.loadingGeolocation = false;

    this.formGroup.controls['lat'].setValue(positionData && positionData.coords.latitude);
    this.formGroup.controls['lng'].setValue(positionData && positionData.coords.longitude);
  }
}
