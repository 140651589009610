import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-list-stores',
    template: `
        <div class="container">
            <div class="scrollable-content">
            <simple-header title="Almacenes" [back]="true" icon="close"></simple-header>
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <app-image-panel *ngIf="!loading && !stores.length" emoji="true" text="No existen almacenes"></app-image-panel>
            <div *ngFor="let store of stores" class="item">
                <list-store-item
                    (linkClicked)="this.naviate(store)"
                    [storeName]="store.name"
                    [projectName]="store.project?.name"
                >
                </list-store-item>
                <mat-divider></mat-divider>
            </div>
            
            <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/list-stores/create']"><mat-icon>add</mat-icon></button>
        </div>
    `,
    styleUrls: ['./list-stores.component.css']
})
export class ListStoresComponent implements OnInit {
    searchFormGroup = new UntypedFormGroup({});
    stores = [];
    loading;
    constructor(
        private apiManager: ApiManagerService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.loading = true;

        this.apiManager.listStores().subscribe((data: any) => {
            this.stores = data.data;
            this.loading = false;
        }, () => {
            this.loading = false;
        })
    }

    naviate(store) {
        this.router.navigate(['/app/store-items'], { queryParams: { id: store.id } });
    }
}
