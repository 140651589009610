<div class="container">
  <div class="scrollable-content">
    <simple-header title="Editar datos de poste y luminaria" [back]="true" icon="close"></simple-header>
    <form [formGroup]="formGroup" class="padding-top-8 padding-bottom-8">
      <div class="inline-flex">
        <mat-form-field appearance="outline" class="column">
          <mat-label>Número de poste</mat-label>
          <input matInput maxlength="20" formControlName="postNumber">
        </mat-form-field>
      </div>
      <div>
        <div class="inline-flex">
          <dropdown-selector class="column margin-right-8" label="Tipo" [types]="types" [(selected)]="selectedType"></dropdown-selector>
          <dropdown-selector class="column"  label="Especial" [types]="special" [(selected)]="selectedSpecial"></dropdown-selector>
        </div>
        <div class="inline-flex">
          <dropdown-selector class="column margin-right-8" label="Anomalía" [types]="anomaly" [(selected)]="selectedAnomaly"></dropdown-selector>
          <dropdown-selector class="column"  label="Tipo de encendido" [types]="switchedon" [(selected)]="selectedSwitchedon"></dropdown-selector>
        </div>
        <dropdown-selector label="Red eléctrica" [types]="electricNetwork" [(selected)]="selectedElectricNetwork"></dropdown-selector>
        <div class="inline-flex">
          <dropdown-selector class="column margin-right-8" label="Tipo de poste" [types]="post" [(selected)]="selectedPostType"></dropdown-selector>
          <dropdown-selector class="column"  label="Clase de poste" [types]="postClass" [(selected)]="selectedPostClass"></dropdown-selector>
        </div>
        <div class="inline-flex">
          <mat-form-field appearance="outline" class="column">
            <mat-label>Altura de poste</mat-label>
            <input matInput formControlName="postHeight">
          </mat-form-field>
        </div>
        <div class="inline-flex">
          <dropdown-selector class="column" label="Zona de instalación" [types]="installationZones" [(selected)]="selectedInstallationZone"></dropdown-selector>
        </div>
        <div class="inline-flex">
          <dropdown-selector class="column" label="Tipo de espacio iluminado" [types]="spaceTypes" [(selected)]="selectedSpaceType"></dropdown-selector>
        </div>
        <div class="inline-flex">
          <mat-form-field appearance="outline" class="column">
            <mat-label>Observaciones</mat-label>
            <textarea rows="2" matInput formControlName="observations"></textarea>
          </mat-form-field>
        </div>
        <div class="margin-bottom-16">
          <mat-checkbox [(ngModel)]="particular" [ngModelOptions]="{standalone: true}">Uso particular</mat-checkbox>
        </div>
      </div>
      <gallery-list [(photos)]="photos" [maxPhotos]="5"></gallery-list>
      <div class="align-center">
        <div class="padding-bottom-8">
          <button (click)="save()" [disabled]="formGroup.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
        </div>
        <div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </form>
  </div>
</div>
